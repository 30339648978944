<template>
    <div class='backgrounAll'>
        <div class="animate__animated animate__bounce animate__fadeInUp">
            <div class="importKey-box centre-content">
                <div class="key-box marbottom-24">
                    <h3>导入密钥</h3>
                    <div class="flex-box importKey-title">
                        <p class="title" v-if="isShow">导入状态：已导入 <i class="el-icon-check"></i></p>
                        <p class="title" v-else>导入状态：未导入 <i class="el-icon-close"></i></p>
                    </div>
                    <div class="key-ipt-box">
                        <p class="nowrapText key-text">密钥内容：</p>
                        <el-input class="textarea-box" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="请输入密钥内容" v-model="textarea">
                        </el-input>
                    </div>
                    <div class="btn-box flex-box">
                        <div class="sub-btn" @click="submitKey()">提交</div>
                        <div class="reset" @click="resetKey()">重置</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { localStorageSet } from "@/utils/localStorage";
import { localStorageGet } from "@/utils/localStorage";
export default {
    data() {
        return {
            textarea: '',
            configInfo: {},
            isShow: false,
        };
    },
    //接口 一进入页面就执行的 
    mounted() {
        this.configInfo = JSON.parse(localStorageGet('userInfo'));
        let secretKey = JSON.stringify(localStorageGet('secretKey'));
        if (secretKey === "" || secretKey === undefined || secretKey === 'null') {
            this.isShow = false;
        } else {
            this.isShow = true;
        }
    },
    //事件方法
    methods: {
        submitKey() {
            let key = this.textarea;
            var begin = /-----BEGIN (PUBLIC)|(PRIVATE) KEY-----/g;
            var expm = key.match(begin);
            var end = /-----END (PUBLIC)|(PRIVATE) KEY-----/g;
            var expm1 = key.match(end);

            if (!expm || !expm1) {
                alert('密钥格式不正确')
                // message.error('密钥格式不正确');
                return false;
            }

            let str = key.replace(/\r|\n/ig, "")
            localStorage.setItem('xlt-pub-key', str);
            // alert('导入密钥成功')
            // message.success('导入密钥成功');
            // handleCancel();
            // router.go(0)

            this.$message({
                message: '导入成功',
                type: 'success'
            });
            this.isShow = true;
            localStorageSet("secretKey", this.textarea);
        },
        resetKey() {
            this.textarea = '';
        },
    },
    //注册子组件
    components: {},
    //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
    computed: {},
    //过滤器
    filters: {
        filtime(val) { },
    },
};
</script>

<style lang='less' scoped>
h3 {
    height: 45px;
    border-bottom: 1px solid #dcdfe6;
}


.importKey-box {
    padding: 50px 0px;

    .key-box {
        .title {
            font-size: 14px;
            margin-right: 16px;
        }

        .importKey-title {
            padding: 24px 0px 0px 24px;
        }

        .key-ipt-box {
            display: flex;
            padding: 24px;

            .key-text {
                font-size: 14px;
                // margin-right: 12px;
            }

            /deep/.el-textarea__inner {
                min-height: 300px !important;
                width: 600px;
            }

        }

        .btn-box {
            padding-left: 95px;

            .sub-btn {
                margin-right: 12px;
                width: 72px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: linear-gradient(317deg, rgba(70, 118, 248, 0.99) 0%, #54C6F8 100%);
                box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
                border-radius: 4px;
                font-size: 14px;
                color: #FFFFFF;
            }

            .reset {
                width: 72px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #C0C4CC;
                font-size: 14px;
                color: #606266;
            }
        }
    }
}
</style>
