/*
 * @Author: you name
 * @Date: 2022-05-05 19:11:02
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2023-03-16 10:40:23
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/');
            }
        }

        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000;
    } else {
        time = +time;
    }
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
    }
}

//秒转换为 分秒 时分秒
export function secondChange(value, format) {
    var theTime = parseInt(value); // 秒
    var middle = 0; // 分
    var hour = 0; // 小时

    if (theTime >= 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle >= 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    var result = '' + parseInt(theTime) + '秒';

    if ((format = 'MS')) {
        if (middle > 0) {
            result = '' + parseInt(middle) + '分' + result;
        }
    }

    if (format == 'HMS') {
        if (hour > 0) {
            result = '' + parseInt(hour) + '小时' + result;
        }
    }
    return result;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
    if (type === 'start') {
        return new Date().getTime() - 3600 * 1000 * 24 * 90;
    } else {
        return new Date(new Date().toDateString());
    }
}

export function changeDate(dateArr, type = 'month') {
    var arrs = [];
    for (var i = 0; i < dateArr.length; i++) {
        let year = momentFormat(dateArr[i], 'M');
        let month = momentFormat(dateArr[i], 'D');
        let day = momentFormat(dateArr[i], 'HH:mm');

        arrs[i] = type == 'month' ? month : type == 'year' ? year : day;
    }

    return arrs;
}

export function momentFormat(date, dateFormat = 'YYYY') {
    return moment(parseInt(date)).format(dateFormat);
}

export function handleBatchTimeByPattern(dataArr, dateFormat = 'YYYY') {
    return (dataArr = dataArr.map((timeItem, timeIndex) => {
        // 格式化时间
        timeItem = momentFormat(timeItem, dateFormat);
        return timeItem;
    }));
}

export function dateFormat(row, column) {
    var date = row[column.property];
    if (date == undefined) {
        return '';
    }

    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (var i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xdc00 && code <= 0xdfff) i--;
    }
    return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
    const newArray = [];
    for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
            newArray.push(actual[i]);
        }
    }
    return newArray;
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.lastIndexOf('?') + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1);
        let val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
    });
    return obj;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
    if (!json) return '';
    return cleanArray(
        Object.keys(json).map((key) => {
            if (json[key] === undefined) return '';
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        }),
    ).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');
    if (!search) {
        return {};
    }
    const obj = {};
    const searchArr = search.split('&');
    searchArr.forEach((v) => {
        const index = v.indexOf('=');
        if (index !== -1) {
            const name = v.substring(0, index);
            const val = v.substring(index + 1, v.length);
            obj[name] = val;
        }
    });
    return obj;
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
    const div = document.createElement('div');
    div.innerHTML = val;
    return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
    if (typeof target !== 'object') {
        target = {};
    }
    if (Array.isArray(source)) {
        return source.slice();
    }
    Object.keys(source).forEach((property) => {
        const sourceProperty = source[property];
        if (typeof sourceProperty === 'object') {
            target[property] = objectMerge(target[property], sourceProperty);
        } else {
            target[property] = sourceProperty;
        }
    });
    return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
    if (!element || !className) {
        return;
    }
    let classString = element.className;
    const nameIndex = classString.indexOf(className);
    if (nameIndex === -1) {
        classString += '' + className;
    } else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
    }
    element.className = classString;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp;

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function (...args) {
        context = this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }
        return result;
    };
}

//节流
export function throttle(fn, delay = 1000) {
    var lastTime, timer, delay;
    return function () {
        var _this = this;
        var args = arguments;
        var nowTime = Date.now();
        if (lastTime && nowTime - lastTime < delay) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(function () {
                lastTime = nowTime;
                fn.apply(_this, args);
            }, delay);
        } else {
            lastTime = nowTime;
            fn.apply(_this, args);
        }
    };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone');
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys]);
        } else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
    return Array.from(new Set(arr));
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
    return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
    if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ');
    }
}

export function sort_ASCII(obj) {
    //   var arr = new Array();
    //   var num = 0;
    //   for (var i in obj) {
    //     arr[num] = i;
    //     num++;
    //   }
    //   var sortArr = arr.sort();
    //   var sortObj = {};
    //   for (var i in sortArr) {
    //     sortObj[sortArr[i]] = obj[sortArr[i]];
    //   }
    //   return sortObj;

    var arr = new Array();
    var num = 0;
    for (var i in obj) {
        arr[num] = i;
        num++;
    }

    var sortArr = arr.sort();
    var sortObj = {};

    for (var i in sortArr) {
        if (typeof obj[sortArr[i]] != 'object') {
            //普通数据类型
            sortObj[sortArr[i]] = obj[sortArr[i]];
        } else {
            // 如果是数组
            if (Array.isArray(obj[sortArr[i]])) {
                if (!sortObj[sortArr[i]]) {
                    sortObj[sortArr[i]] = [];
                }

                obj[sortArr[i]].forEach((element, index, arr) => {
                    sortObj[sortArr[i]].push(sort_ASCII(element));
                });
            }
        }
    }
    return sortObj;
}

// 动态引入js
export const injectScript = (src) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;
    const t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
};

//监听是否开启全屏
export const fullscreenListener = () => {
    document.addEventListener('fullscreenchange', function (e) {
        if (document.fullscreenElement) {
            console.log('进入全屏');
        } else {
            console.log('退出全屏');
        }
    });
};

//开启全屏
export const launchFullscreen = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
    }
};

//退出全屏
export const exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};

//数据类型判断
//是否字符串
export const isString = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'String';
};

// 是否数字
export const isNumber = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Number';
};

// 是否boolean
export const isBoolean = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean';
};

//去除html标签
export const removeHtmltag = (str) => {
    return str.replace(/<[^>]+>/g, '');
};

// 是否函数
export const isFunction = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Function';
};

// 是否对象
export const isObj = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
};

// 是否时间
export const isArray = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Array';
};

//数字相关
/*指定显示的文字数量多余的使用省略号代替*/
export const strEllp = (str, num = str.length) => {
    var subStr = str.substring(0, num);
    return subStr + (str.length > num ? '...' : '');
};

// 百分比保留一位小数
export function toPercent(point) {
    var str = Number(point * 100).toFixed(1);
    str += '%';
    return str;
}

/**
 * 百分比保留两位小数
 * @param value
 * @returns {string}
 */
export function fmtPercentage(value) {
    if (!value) {
        return '-';
    }
    return (value * 100).toFixed(2) + '%';
}

/**
 * 生成指定范围内的随机数字
 * @param min
 * @param max
 * @returns {*}
 */
export const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

//字符串相关
// `trim`方法不存在的话，用正则
export const trim = (str) => {
    return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
};

//数组相关
//判断是否为空数组
export const isEmpty = (arr) => !Array.isArray(arr) || arr.length === 0;

//判断一个元素是否在数组中
export const contains = (arr, val) => {
    return arr.indexOf(val) != -1 ? true : false;
};

//删除数组其中一个元素
export const remove = (arr, ele) => {
    var index = arr.indexOf(ele);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

//获取数组最大值
export function getArrMax() {
    return (arr) => Math.max(...arr);
}

//获取数组最小值
export function getArrMin() {
    return (arr) => Math.min(...arr);
}

//数组求和
export function arrSum(ar) {
    var arr = ar;
    var sum;
    if (arr.length > 0) {
        sum = arr.reduce(function (prev, curr, idx, arr) {
            return prev + curr;
        });
    } else {
        sum = 0;
    }

    return sum;
}

//数组平均值
export const average = (arr) => {
    return arrSum(arr) / arr.length;
};

export function chunkArr(arr, size) {
    let result = [];
    for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
    }
    return result;
}

//数组排序
export function compare(property, sortBy = 'desc') {
    return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];

        return sortBy == 'desc' ? value2 - value1 : value1 - value2;
    };
}

// export function isNumber (val) {
//   if (parseFloat(val).toString() == "NaN") {
//     return false;
//   } else {
//     return true;
//   }
// }

export function filterArray(arr, pid = 'parentId', id = 'id', children = 'children') {
    let obj = Object.create(null);
    let tree_arr = [];
    arr.forEach((res) => {
        // 数组循环 生成唯一id对象
        obj[res[id]] = res;
    });
    arr.map((res) => {
        let parent = obj[res[pid]]; // 如果pid在obj对象 可以获取值 说明对应的id 是 res 的父级
        if (parent) {
            // 没有没有找到 就 插入数组
            if (!parent[children]) {
                parent[children] = [];
            }
            parent[children].push(res);
        } else {
            tree_arr.push(res);
        }
    });
    return tree_arr;
}

//将数字转为数组
export function numberToArr(n) {
    var str = n.toString();
    var arr = str.split('');

    return arr;
}

//获取最近几年数组
export const yearsList = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
};

export function tableColumnFormatter(arr, rowKey, itemKey) {
    let name = '';
    if (arr && arr.length > 0) {
        arr.forEach((item, index) => {
            if (rowKey == item[itemKey]) {
                name = item.name;
            }
        });
    }

    return name;
}

export function statusFormatter(row, column) {
    //1 未启用 2 待确认 3 启用
    let status = row.status;
    return status == 1 ? '未启用' : status == 2 ? '待确认' : '启用';
}

//如果数据为null,使用真实数据，否则为0
export function dataProcessing(key) {
    return key ? key : 0;
}

//创建指定长度数组
export function createArrByLen(len) {
    return new Array(len).fill(0);
}

export function group(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
    }
    return newArray;
}

export function checkEmpty(data) {
    if (typeof data === 'object') {
        if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
            return true;
        } else if (!data) {
            return true;
        }
        return false;
    } else if (typeof data === 'string') {
        if (!data.trim()) {
            return true;
        }
        return false;
    } else if (typeof data === 'undefined') {
        return true;
    } else {
        return false;
    }
}

export function getTimeHandler() {
    let date = new Date();
    // 获取时间：时分秒
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const secound = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    // 获取日期：年月日
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const newMonth = month < 10 ? '0' + month : month;
    const day = date.getDate();
    const newDay = day < 10 ? '0' + day : day;
    return (year +
        '-' +
        newMonth +
        '-' +
        newDay +
        ' ' +
        '星期' +
        '日一二三四五六'.charAt(new Date().getDay()) +
        ' ' +
        hour +
        ':' +
        minute);
}