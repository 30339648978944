<template>
  <div class="backgrounAllFindPass">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="findPass-box centre-content">
        <div class="findPass-content">
          <div class="flex-box password-title">
            <p class="icon"></p>
            <p class="text">找回密码</p>
          </div>
          <div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
              <!-- <el-form-item label="请输入用户名：" prop="name">
                                <el-input v-model="ruleForm.name"></el-input>
                            </el-form-item> -->
              <!-- <el-form-item label="请输入验证码：" prop="name">
                                <el-input v-model="ruleForm.vercode"></el-input>
                            </el-form-item> -->
              <div class="ipt-box flex-box">
                <div>
                  <div class="flex-box">
                    <p class="nowrapText ipt-text">请输入用户名：</p>
                    <input v-model.lazy="username" class="input-box" type="text" @change="nameInput($event)"
                      placeholder="请输入用户名" />
                  </div>
                  <p class="cellPhone marginLeft" v-show="cellPhone">
                    {{ cellPhone }}
                  </p>
                  <div class="flex-box marginLeft" v-show="username != '' && !cellPhone">
                    <p class="errIcon">
                      <img src="../../../assets/home/err-icon.png" alt="" />
                    </p>
                    <p class="err-text">未找到该用户！请联系管理员</p>
                  </div>
                </div>
              </div>
              <div class="ipt-box flex-box">
                <div class="flex-box">
                  <p class="nowrapText ipt-text">请输入验证码：</p>
                  <input class="input-box-code" type="text" @input="codeInput($event)" placeholder="请输入验证码：" />
                </div>
                <div class="sendVercode hoverFinger" @click="getPhoneCode()" v-show="timeTrue == true">
                  发送验证码
                </div>
                <div class="sendVercode hoverFinger" v-show="timeTrue == false">
                  {{ currentTime }}秒
                </div>
              </div>
              <el-form-item label="密码：" prop="pass" required>
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="checkPass" required>
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off">
                  <i slot="prefix" class="el-input__icon el-icon-pass"></i>
                </el-input>
              </el-form-item>
              <div class="line"></div>
              <div class="btn-box addStudent-btn-box">
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                  <el-button @click="resetForm()">取消</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import { Dialog } from "element-ui";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        vercode: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur",
          },
        ],
        vercode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" },
        ],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },

      timeTrue: true,
      currentTime: 60,
      isSendCode: false,
      username: "", //用户名
      cellPhone: "", //用户电话
      vcode: "", //验证码
    };
  },
  //接口 一进入页面就执行的
  mounted() { },
  //事件方法
  methods: {
    submitForm(formName) {
      console.log(this.vcode);
      // console.log(this.cellPhone);
      console.log(this.username);
      console.log(this.ruleForm.pass);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let data = {
            vcode: this.vcode,
            // cellphone: this.cellPhone,
            username: this.username,
            password: this.ruleForm.pass,
          };
          request({
            url: "/auth/modifypwd",
            method: "post",
            data,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: '修改成功',
                type: 'success'
              });

            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    nameInput(e) {
      //   console.log(e.target.value);
      //   this.username = e.target.value;
      let data = {
        username: this.username,
      };
      request({
        url: "/misc/finduser",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res);
          this.cellPhone = res.data.cellPhone;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    codeInput(e) {
      this.vcode = e.target.value;
    },
    setTimes() {
      // 倒计时
      this.timeTrue = false;
      var setTimeoutS = setInterval(() => {
        this.currentTime--;
        if (this.currentTime <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },
    getPhoneCode() {
      // console.log(www)
      if (!this.cellPhone) return;
      this.setTimes();
      let data = {
        username: this.username,
      };
      request({
        url: "/misc/sendsms",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.backgrounAllFindPass {
  height: 100vh;
  overflow: hidden;
  background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/login-bj.png") no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.findPass-box {
  padding: 40px 0px 60px;
  height: 100vh;
  overflow: hidden;
  background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/login-bj.png") no-repeat;
  background-size: 100% 100%;
  background-size: cover;

  .findPass-content {
    padding: 24px;
    // width: 600px;
    width: 510px;
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 100px;

    .password-title {
      margin-bottom: 32px;

      .icon {
        width: 4px;
        height: 21px;
        background: #3c6dfb;
        border-radius: 8px;
        margin-right: 4px;
      }

      .text {
        font-size: 16px;
        font-weight: bold;
        color: #303133;
      }
    }

    .line {
      height: 1px;
      background: #D5D6D9;
    }

    .btn-box {
      margin-top: 24px;
      margin-left: 42px !important;
    }
  }
}

/deep/.el-button--primary {
  width: 76px;
  height: 36px;
  padding: 0px;
  border-radius: 4px;
  margin-right: 24px;
  box-shadow: 0px 2px 8px 0px rgba(70,117,246,0.45);
}
/deep/.el-button--default{
  width: 76px;
  height: 36px;
  padding: 0px;
  border-radius: 4px;
}

/deep/.el-input__inner {
  width: 300px;
  // border: 1px solid red !important;
}

.addStudent-btn-box {
  margin-left: 0px;
  width: 500px;
  margin: 0 auto;
}

// input框
.ipt-box {
  margin-bottom: 24px;

  .marginLeft {
    margin-left: 104px;
    margin-top: 4px;
  }

  .cellPhone {
    font-size: 14px;
    color: #c0c4cc;
  }

  .errIcon>img {
    width: 19px;
    height: 19px;
    margin-right: 3px;
    display: block;
  }

  .err-text {
    font-size: 14px;
    color: #fc4646;
  }

  .ipt-text {
    width: 98px;
    height: 19px;
    font-size: 14px;
    color: #606266;
    line-height: 19px;
    margin-right: 10px;
  }

  .input-box {
    padding: 12px;
    // width: 200px;
    width: 304px;
    // height: 36px;
    border-radius: 6px;
    border: 1px solid #c0c4cc;
    margin-right: 10px;
    outline-color: #c0c4cc;
  }

  .input-box-code {
    padding: 12px;
    width: 200px;
    // height: 36px;
    border-radius: 6px;
    border: 1px solid #c0c4cc;
    margin-right: 10px;
    outline-color: #c0c4cc;
  }

  .sendVercode {
    width: 88px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #4676f8;
    font-size: 14px;
    color: #4676f8;
  }
}

.demo-ruleForm {
  /deep/.el-form-item__label {
    width: 109px !important;
  }

  /deep/.el-form-item__content {
    margin-left: 110px !important;
  }

  /deep/.el-input__inner {
    padding-left: 12px !important;
    width: 326px;
  }
}
</style>
