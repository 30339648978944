<template>
  <div class="box" style="margin-top: -64px">
    <div class="banner-list">
      <div class="banner">
        <div class="banner-box" v-if="bannerImgs.length>0">
          <!-- type="card" -->
          <el-carousel :interval="4000" height="700px" width="100%">
            <el-carousel-item v-for="(item,index) in bannerImgs" :key="index">
              <img :src="item.imgUrl" alt="" @click="toImg(item.linkUrl)">
            </el-carousel-item>
          </el-carousel>
        </div>
        <video v-else id="media" class="media" height="1080" width="100%" controls :src="configData.bannerImg"
          autoplay="autoplay" loop muted style="pointer-events: none;"></video>
      </div>
    </div>
    <div class="home-bj">
      <!-- 资源库介绍 -->
      <div class="resource-box parentLocation" style="display: none;">
        <h1 class="h1Style">资源库介绍</h1>
        <div class="resource-stock">
          <div class="resource-img">
            <img :src="configData.resDescImg" alt="" />
          </div>
          <!-- 富文本 -->
          <p class="resource-text" v-html="configData.resDescText"></p>
        </div>
      </div>
      <div class="resource-box">
        <img class="resourceImg" :src="configData.resDescImg" alt="">
        <!-- <img class="resourceImg2" src="../assets/home/ziyuankuJieshao.png" alt=""> -->
      </div>
      <!-- 中心内容块 -->
      <div class="centre-content">
        <!-- 精品示范课 -->
        <div class="course-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon.png" alt="" />
              </p>
              <h2 class="h2Style">精品示范课</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/course')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="course-list">
            <div class="course-item" v-for="coursesItem in courses" :key="coursesItem.id" @click="
              toDetail('courseDetail', { courseid: coursesItem.courseId })
              ">
              <div class="course-cover">
                <div class="cover-img">
                  <img :src="coursesItem.coverImg" alt="" />
                </div>
                <div class="cover-info flex-box">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/learn-num.png" alt="" />
                    </p>
                    <span>{{ coursesItem.studyNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ coursesItem.shareNum }}</span>
                  </div>
                </div>
              </div>
              <div class="course-info">
                <p class="course-title article-title-hover">
                  {{ coursesItem.courseTitle }}
                </p>
                <p class="course-introduce">{{ coursesItem.courseLecturer }}</p>
                <p class="courseHover">
                  <img src="../assets/home/course-hover-icon.png" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 课件 -->
        <div class="courseware-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon.png" alt="" />
              </p>
              <h2 class="h2Style">课件</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/courseware')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="courseware-list">
            <div class="courseware-item parentLocation" v-for="keJianItem in keJian" :key="keJianItem.i" @click="
              $router.push({
                name: 'materialDetail',
                params: { resid: keJianItem.resId },
                query: { type: 1 },
              })
              ">
              <div class="courseware-content parentLocation" v-if="keJianItem.customImg != ''">
                <p class="courseware-cover"><img :src="keJianItem.customImg" alt=""></p>
              </div>

              <div class="courseware-content parentLocation" v-else>
                <p class="courseware-cover">
                  <!-- 1:png格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
                  <img v-show="keJianItem.coverImg == 1" src="../assets/home/png-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 2" src="../assets/home/ppt-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 3" src="../assets/home/pdf-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 4" src="../assets/home/xls-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 5" src="../assets/home/doc-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 6" src="../assets/home/zip-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 7" src="../assets/home/else-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 8" src="../assets/home/txt-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 9" src="../assets/home/video-cover.png" alt="" />
                  <img v-show="keJianItem.coverImg == 11" src="../assets/home/audio-cover.png" alt="" />
                </p>
                <div class="sonLocation">
                  <p class="courseware-title">{{ keJianItem.resName }}</p>
                  <p class="courseware-line"></p>
                </div>
              </div>
              <div class="courseware-info sonLocation">
                <div class="flex-box">
                  <p class="shareNum-img">
                    <img src="../assets/home/download2.png" alt="" />
                  </p>
                  <span>{{ keJianItem.viewNum }}</span>
                </div>
                <div class="flex-box marLeft-16">
                  <p class="learnNum-img">
                    <img src="../assets/home/share-num.png" alt="" />
                  </p>
                  <span>{{ keJianItem.shareNum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 心理微课 -->
        <div class="psyvideo-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon.png" alt="" />
              </p>
              <h2 class="h2Style">心理微课</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/video')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <!-- 微课分类 -->
          <div class="sort-nav course-box">
            <div class="sort-nav-list">
              <div v-for="item in smallVideo" :key="item.cateInfo.cateId" @click="videoTabChange(item.cateInfo.cateId)"
                :class="videoTabActive == item.cateInfo.cateId
                  ? 'nav-item-active hoverFinger'
                  : 'sort-nav-item hoverFinger'
                  ">
                {{ item.cateInfo.cateName }}
              </div>
            </div>
            <div class="video-list">
              <div class="video-item" v-for="videoItem in videoList" :key="videoItem.id" @click="
                toDetail('videoDetail', { vid: videoItem.vid })
                ">
                <div class="video-cover">
                  <div class="cover-img">
                    <img :src="videoItem.coverImg" alt="" />
                  </div>
                  <div class="cover-info">
                    <div class="flex-box">
                      <p class="learnNum-img">
                        <img src="../assets/home/learn-num.png" alt="" />
                      </p>
                      <span>{{ videoItem.viewNum }}</span>
                    </div>
                    <div class="flex-box marLeft-16">
                      <p class="shareNum-img">
                        <img src="../assets/home/share-num.png" alt="" />
                      </p>
                      <span>{{ videoItem.favNum }}</span>
                    </div>
                  </div>
                </div>
                <div class="video-info">
                  <p class="video-title article-title-hover">
                    {{ videoItem.title }}
                  </p>
                  <p class="video-introduce">讲师：{{ videoItem.lecturer }}</p>
                  <p class="videoHover">
                    <img src="../assets/home/video-hover-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 心理图书 -->
        <div class="psybook-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon.png" alt="" />
              </p>
              <h2 class="h2Style">心理图书</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/book')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="sort-nav">
            <div class="sort-nav-list">
              <div v-for="item in books" :key="item.catdId" @click="bookTabChange(item.cateInfo.cateId)" :class="bookTabActive == item.cateInfo.cateId
                ? 'nav-item-active hoverFinger'
                : 'sort-nav-item hoverFinger'
                ">
                {{ item.cateInfo.cateName }}
              </div>
            </div>
          </div>
          <div class="book-list">
            <div class="book-item" v-for="bookItem in bookList" :key="bookItem.id"
              @click="toDetail('bookDetail', { cbid: bookItem.cbid })">
              <div class="book-cover">
                <div class="cover-img">
                  <img :src="bookItem.coverImg" alt="" />
                </div>
                <div class="cover-info">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/learnNum.png" alt="" />
                    </p>
                    <span>{{ bookItem.viewNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ bookItem.shareNum }}</span>
                  </div>
                </div>
              </div>
              <div class="book-info">
                <p class="book-title">{{ bookItem.bookName }}</p>
                <p class="book-introduce">作者：{{ bookItem.bookAuthor }}</p>
                <p class="bookHover">
                  <img src="../assets/home/book-hover-icon.png" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 心理文章 -->
        <div class="psyarticle-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon2.png" alt="" />
              </p>
              <h2 class="h2Style">心理文章</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon2.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/article')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="article-list">
            <div class="article-item" v-for="articlesItem in articles" :key="articlesItem.id"
              @click="toDetail('articleDetail', { artid: articlesItem.artId })">
              <div class="flexBox">
                <div class="article-cover parentLocation">
                  <p class="cover-img">
                    <img :src="articlesItem.coverImg" alt="" />
                  </p>
                  <div class="cover-info flex-box sonLocation">
                    <div class="flex-box">
                      <p class="learnNum-img">
                        <img src="../assets/home/learnNum.png" alt="" />
                      </p>
                      <span>{{ articlesItem.viewNum }}</span>
                    </div>
                    <div class="flex-box marLeft-16">
                      <p class="shareNum-img">
                        <img src="../assets/home/share-num.png" alt="" />
                      </p>
                      <span>{{ articlesItem.shareNum }}</span>
                    </div>
                  </div>
                </div>
                <div class="article-text">{{ articlesItem.title }}</div>
              </div>
              <div class="article-item-hover">
                <p class="article-hover-img">
                  <img src="../assets/home/article-hover-icon.png" alt="" />
                </p>
                <div class="article-hover-title">{{ articlesItem.title }}</div>
                <div class="article-hover-text">
                  {{ articlesItem.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 活动锦囊 -->
        <div class="activity-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon.png" alt="" />
              </p>
              <h2 class="h2Style">活动锦囊</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <p class="seemore-text hoverFinger" @click="routerPush('/activity')">
                更多
              </p>
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="activity-list">
            <div class="activity-item parentLocation" v-for="item in jinNang" :key="item.id" @click="
              $router.push({
                name: 'materialDetail',
                params: { resid: item.resId },
                query: { type: 3 },
              })
              ">

              <div v-if="item.customImg != ''" class="activity-content parentLocation">
                <p class="activity-cover"><img :src="item.customImg" alt=""></p>
              </div>

              <div v-else class="activity-content parentLocation">
                <p class="activity-cover">
                  <!-- <img :src="coverItem.pptcover" alt="" /> -->
                  <!-- 1:img格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
                  <img v-show="item.coverImg == 1" src="../assets/home/img-cover.png" alt="" />
                  <img v-show="item.coverImg == 2" src="../assets/home/ppt-cover.png" alt="" />
                  <img v-show="item.coverImg == 3" src="../assets/home/pdf-cover.png" alt="" />
                  <img v-show="item.coverImg == 4" src="../assets/home/xls-cover.png" alt="" />
                  <img v-show="item.coverImg == 5" src="../assets/home/doc-cover.png" alt="" />
                  <img v-show="item.coverImg == 6" src="../assets/home/zip-cover.png" alt="" />
                  <img v-show="item.coverImg == 7" src="../assets/home/else-cover.png" alt="" />
                  <img v-show="item.coverImg == 8" src="../assets/home/txt-cover.png" alt="" />
                  <img v-show="item.coverImg == 9" src="../assets/home/video-cover.png" alt="" />
                  <img v-show="item.coverImg == 11" src="../assets/home/audio-cover.png" alt="" />
                </p>
                <div class="sonLocation">
                  <p class="activity-title">{{ item.resName }}</p>
                  <p class="activity-line"></p>
                </div>
              </div>
              <div class="courseware-info sonLocation">
                <div class="flex-box">
                  <p class="shareNum-img">
                    <img src="../assets/home/download2.png" alt="" />
                  </p>
                  <span>{{ item.viewNum }}</span>
                </div>
                <div class="flex-box marLeft-16">
                  <p class="learnNum-img">
                    <img src="../assets/home/share-num.png" alt="" />
                  </p>
                  <span>{{ item.shareNum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 推荐资源 -->
        <div class="pushResource-box parentLocation">
          <div class="flex-box">
            <div class="h2Title-box">
              <p class="titleLeftIcon">
                <img src="../assets/home/title-left-icon2.png" alt="" />
              </p>
              <h2 class="h2Style">推荐资源</h2>
              <p class="titleRightIcon">
                <img src="../assets/home/title-right-icon2.png" alt="" />
              </p>
            </div>
            <div class="seemore-box flex-box">
              <!-- <p class="seemore-text hoverFinger" @click="routerPush('/material')">
                更多
              </p> -->
              <p class="seemore-img">
                <img src="../assets/home/seemore-icon.png" alt="" />
              </p>
            </div>
          </div>
          <div class="pushResource-list">
            <div class="pushResource-item" v-for="item in recommendRes" :key="item.id" @click="
              $router.push({
                name: 'materialDetail',
                params: { resid: item.resId },
                query: { type: 2 },
              })
              ">
              <div class="parentLocation">
                <div v-if="item.customImg != ''" class="parentLocation">
                  <p class="pushResource-cover"><img :src="item.customImg" alt=""></p>
                </div>
                <div class="parentLocation" v-else>
                  <p class="pushResource-cover">
                    <!-- 1:img格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
                    <img v-show="item.coverImg == 1" src="../assets/home/img-cover.png" alt="" />
                    <img v-show="item.coverImg == 2" src="../assets/home/ppt-cover.png" alt="" />
                    <img v-show="item.coverImg == 3" src="../assets/home/pdf-cover.png" alt="" />
                    <img v-show="item.coverImg == 4" src="../assets/home/xls-cover.png" alt="" />
                    <img v-show="item.coverImg == 5" src="../assets/home/doc-cover.png" alt="" />
                    <img v-show="item.coverImg == 6" src="../assets/home/zip-cover.png" alt="" />
                    <img v-show="item.coverImg == 7" src="../assets/home/else-cover.png" alt="" />
                    <img v-show="item.coverImg == 8" src="../assets/home/txt-cover.png" alt="" />
                    <img v-show="item.coverImg == 9" src="../assets/home/video-cover.png" alt="" />
                    <img v-show="item.coverImg == 11" src="../assets/home/audio-cover.png" alt="" />
                  </p>
                  <div class="sonLocation">
                    <p class="cover-title">{{ item.resName }}</p>
                    <p class="cover-line"></p>
                  </div>
                </div>
                <div class="cover-info flex-box sonLocation">
                  <div class="flex-box">
                    <p class="shareNum-img">
                      <img src="../assets/home/download2.png" alt="" />
                    </p>
                    <span>{{ item.viewNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="learnNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ item.shareNum }}</span>
                  </div>
                </div>

              </div>
              <p class="pushResource-title">{{ item.resName }}</p>
              <p class="pushResource-type">类型：{{ item.format }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import request from "@/utils/request";
export default {
  name: "Index",
  data () {
    return {
      courses: [], //课程
      keJian: [], //课件
      jinNang: [],
      smallVideo: [], //心理微课
      videoList: [],
      videoCateId: 1,
      videoTabActive: 1,
      books: [], //心理图书
      bookCateId: 2,
      bookTabActive: 2,
      articles: [], //心理文章
      bookList: [],
      recommendRes: [], //推荐资源（素材）
      configData: {}, //配置文件数据
      configInfo: {},// 配置文件详情
      configToken: '',
      bannerImgs:[],//轮播图
    };
  },
  components: {},
  mounted () {
    this.configInfo = JSON.parse(localStorageGet('userInfo'));
    this.configToken = localStorageGet('configToken');
    this.getHomePage();
    this.getConfig();
    this.getConfigToken();

    // // 打印显示器分辨率
    console.log(window.screen.width);
    console.log(window.screen.height);

    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    this.getHomeImg()
  },
  methods: {
    getConfigToken () { // 获取 configToken
      let data = { token: this.configToken ? this.configToken : '' };
      request({
        url: "/config/token",
        method: "post",
        data,
      }).then((res) => {
        localStorageSet("configToken", res.data.token);
      });
    },
    getHomeImg () {//轮播图
            request({
                url: "/index/banners",
                method: "post",
                data: { plat: 1 },
            }).then((res) => {
                this.bannerImgs = res.data.bannerImgs || [];
            });
        },
    getHomePage () {
      request({
        url: "/index/index",
        method: "post",
        data: {},
      }).then((res) => {
        this.courses = res.data.courses; //课程
        this.keJian = res.data.keJian; //课件
        this.jinNang = res.data.jinNang; //锦囊
        this.smallVideo = res.data.smallVideo; //心理微课
        this.books = res.data.books; //图书
        this.articles = res.data.articles; //心理文章
        this.bookList = res.data.books[0].bookList;
        this.videoList = res.data.smallVideo[0].videoList;
        this.recommendRes = res.data.recommendRes;
      });
    },
    getConfig () {
      let data = {};
      request({
        url: "/config",
        method: "post",
        data,
      }).then((res) => {
        this.configData = res.data;
      });
    },

    videoTabChange (cateId) {
      this.videoCateId = cateId;
      this.videoTabActive = cateId;
      this.smallVideo.forEach((item) => {
        if (this.videoTabActive == item.cateInfo.cateId) {
          this.videoList = item.videoList;
        }
      });
    },
    bookTabChange (catdId) {
      this.bookCateId = catdId;
      this.bookTabActive = catdId;
      this.books.forEach((item) => {
        if (this.bookTabActive == item.cateInfo.cateId) {
          this.bookList = item.bookList;
        }
      });
    },
    routerPush (path) {
      this.$router.push(path);
    },
    toImg(path){
      if(path){
        window.open(path);
      }
    },
    toDetail (name, params, query) {
      this.$router.push({
        name,
        params,
        query,
      });
    },

  },
};
</script>

<style lang="less" scoped>
// video 标签隐藏最底部功能
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-timeline {
  display: none;
}

video::-webkit-media-controls-current-time-display {
  display: none;
}

video::-webkit-media-controls-time-remaining-display {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  display: none;
}

video::-webkit-media-controls-volume-slider {
  display: none;
}

//video结束


.home-bj {
  // background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/home-bj.png");
  background: url('https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/home-bj2.png');
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: contain;
}

.centre-content {
  width: 1440px;
  margin: 0 auto;
  min-height: 800px;
}

.banner>img {
  // height: 1080px;
  width: 100%;
  display: block;
}

.banner {
  width: 100%;
  height: auto;
}

.media {
  display: block;
  height: 99%;
}

// 资源库
.resource-box {
  padding-bottom: 100px;
  // background-image: url('../assets/home/ziyuankuJieshao-bj.png');


  .resourceImg {
    width: 100%;
    // min-height: 670px;
    display: block;
  }

  .resourceImg2 {
    display: block;
    margin: 0 auto;
    padding-top: 48px;
  }

  .h1Style {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #2870ff;
    text-shadow: 0px 2px 4px rgba(70, 118, 248, 0.2);
    background: linear-gradient(335deg, #2ad9ff 0%, #1a58ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
  }

  .resource-stock {
    display: flex;
    // width: 100%;
  }

  .resource-img {
    margin-right: 80px;
  }

  .resource-img>img {
    width: 500px;
    height: 386px;
    display: block;
    // border: 1px solid;
  }

  .resource-text {
    font-size: 20px;
    line-height: 36px;
    color: #303133;
    width: 860px;
    height: 364px;
    // text-align-last: justify;
    text-align: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出显示省略号
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}

// 精品示范课
.course-box {
  margin-bottom: 100px;

  .course-list {
    display: flex;
  }

  .course-item {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    width: 336px;
    width: 336px;
    height: 310px;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    margin-right: 32px;
    cursor: pointer;

    .course-cover {
      .cover-img>img {
        width: 336px;
        height: 224px;
        border-radius: 8px;
        background-color: #6F7174;
        display: block;
      }

      position: relative;
    }

    .cover-info {
      width: 303px;
      position: absolute;
      bottom: 0px;
      display: flex;
      padding: 17px 16px 7px;
      font-size: 12px;
      color: #ffffff;

      // background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 0.25) 100%);
      background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 1.5) 100%);
      border-radius: 0px 0px 8px 8px;
      // background-color: plum;
    }

    .course-info {
      padding: 16px;
      position: relative;

      .course-title {
        font-size: 16px;
        color: #3b3d41;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .course-title :hover {
        color: #4676f8;
      }

      .course-introduce {
        font-size: 12px;
        color: #909399;
      }

      .courseHover {
        position: absolute;
        right: 0;
        bottom: -6px;
        display: none;
      }

      .courseHover>img {
        width: 49px;
        height: 48px;
        display: block;
      }
    }
  }

  .course-item:nth-child(4) {
    margin-right: 0px;
  }

  .course-item:hover {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  .course-item:hover .article-title-hover {
    color: #4676f8;
  }

  .course-item:hover .courseHover {
    display: inline-block;
    animation: mymove ease 2s;
    animation-iteration-count: 1;
    /*设置动画播放次数*/
    animation-fill-mode: forwards;
  }

  @keyframes mymove {
    from {
      // top: 80px;
      opacity: 0;
    }

    to {
      // top: 40px;
      opacity: 1;
    }
  }
}

// 课件
.courseware-box {
  padding-bottom: 160px;

  .courseware-list {
    display: flex;

    .courseware-item {
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      width: 336px;
      width: 220px;
      height: 164px;
      border-radius: 8px;
      margin-right: 24px;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      cursor: pointer;

      .courseware-content {
        .sonLocation {
          top: 40px;
          left: 16px;
        }

        .courseware-title {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .courseware-line {
          margin-top: 16px;
          width: 20px;
          height: 2px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 1px;
        }
      }

      .courseware-cover>img {
        width: 220px;
        height: 164px;
        display: block;
        background: linear-gradient(301deg, #fea37b 0%, #ff4747 100%);
        border-radius: 8px;
        backdrop-filter: blur(36px);
        display: block;
      }

      .courseware-info {
        display: flex;
        padding: 0 16px 16px;
        font-size: 12px;
        color: #ffffff;
        width: 186px;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .courseware-item:nth-child(6) {
      margin-right: 0px;
    }

    .courseware-item:hover {
      // transform: translateY(-.60rem); //向上浮动了60个像素
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
}

//心理微课
.psyvideo-box {
  padding-bottom: 60px;

  .video-list {
    display: flex;

    .video-item {
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      width: 336px;
      height: 275px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;
      margin-right: 32px;
      cursor: pointer;

      .video-cover {
        .cover-img>img {
          width: 336px;
          height: 189px;
          border-radius: 8px;
          background-color: #6F7174;
          display: block;
        }

        position: relative;
      }

      .cover-info {
        width: 303px;
        position: absolute;
        bottom: 0px;
        display: flex;
        padding: 17px 16px 7px;
        font-size: 12px;
        color: #ffffff;
        // background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 0.25) 100%);
        background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 1.5) 100%);
        border-radius: 0px 0px 8px 8px;
      }

      .video-info {
        padding: 16px;
        position: relative;

        .video-title {
          font-size: 16px;
          color: #3b3d41;
          margin-bottom: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .video-title :hover {
          color: #4676f8;
        }

        .video-introduce {
          font-size: 12px;
          color: #909399;
        }

        .videoHover {
          position: absolute;
          right: 0;
          bottom: -6px;
          display: none;
        }

        .videoHover>img {
          width: 44px;
          height: 42px;
          display: block;
        }
      }
    }

    .video-item:nth-child(4) {
      margin-right: 0px;
    }

    .video-item:hover {
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    .video-item:hover .article-title-hover {
      color: #4676f8;
    }

    .video-item:hover .videoHover {
      display: inline-block;
      animation: mymove 2s infinite;
      animation-iteration-count: 1;
      /*设置动画播放次数*/
    }

    @keyframes mymove {
      from {
        opacity: 0;
      }

      to {
        opacity: 100;
      }
    }
  }
}

// 心理图书
.psybook-box {
  padding-bottom: 160px;

  .book-list {
    display: flex;
    padding-top: 20px;

    .book-item {
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      width: 336px;
      width: 210px;
      // height: 382px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;
      margin-right: 36px;
      cursor: pointer;

      .book-cover {
        .cover-img>img {
          width: 210px;
          height: 280px;
          border-radius: 8px;
          background-color: #6F7174;
          display: block;
        }

        position: relative;
      }

      .cover-info {
        width: 178px;
        // background: linear-gradient(180deg,
        //     rgba(54, 54, 56, 0) 0%,
        //     rgba(54, 54, 56, 0.25) 100%);
        background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 1.5) 100%);
        border-radius: 0px 0px 8px 8px;
        position: absolute;
        bottom: 0px;
        display: flex;
        padding: 17px 16px 7px;
        font-size: 12px;
        color: #ffffff;
      }

      .book-info {
        padding: 16px;
        position: relative;

        .book-title {
          height: 40px;
          font-size: 16px;
          color: #3b3d41;
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .book-title :hover {
          color: #4676f8;
        }

        .book-introduce {
          font-size: 12px;
          color: #909399;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .bookHover {
          position: absolute;
          right: 0;
          bottom: 0px;
          display: none;
        }

        .bookHover>img {
          width: 42px;
          height: 40px;
          display: block;
        }
      }
    }

    .book-item:nth-child(6) {
      margin-right: 0px;
    }

    .book-item:hover {
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    .book-item:hover .bookHover {
      display: inline-block;
      animation: mymove 2s infinite;
      animation-iteration-count: 1;
      /*设置动画播放次数*/
    }

    @keyframes mymove {
      from {
        opacity: 0;
      }

      to {
        opacity: 100;
      }
    }
  }
}

// 心理文章
.psyarticle-box {
  // padding-bottom: 160px;
  padding-bottom: 136px;

  .article-list {
    display: flex;
    flex-wrap: wrap;

    .article-item {
      position: relative;
      cursor: pointer;
      width: 444px;
      background: #ffffff;
      box-shadow: 0px -2px 14px 0px rgba(139, 114, 144, 0.24);
      border-radius: 16px;
      padding: 10px;
      margin-right: 24px;
      margin-bottom: 24px;

      .article-cover {
        .cover-img>img {
          width: 260px;
          height: 146px;
          display: block;
          background-color: #6F7174;
          border-radius: 14px;
        }

        .cover-info {
          padding: 10px;
          font-size: 12px;
          color: white;
          width: 238px;
          background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 1.5) 100%);
          border-radius: 0px 0px 8px 8px;
        }
      }

      .article-text {
        font-size: 16px;
        font-weight: bold;
        color: #6f7174;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .article-item:nth-child(3n) {
      margin-right: 0;
    }

    .article-item:hover .article-item-hover {
      display: block;
      background: linear-gradient(to right, #fffefc, #fffbed);
      animation: 5000s;
      border-radius: 16px;
    }

    .article-item-hover {
      width: 444px;
      height: 146px;
      background: white;
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      animation: myMove steps(11) 0.6s forwards;
      animation: 5000s;
      padding: 10px;

      .article-hover-img>img {
        width: 93px;
        height: 32px;
        display: block;
      }

      .article-hover-title {
        font-size: 16px;
        font-weight: bold;
        color: #3b3d41;
        margin: 17px 0px 10px;
      }

      .article-hover-text {
        font-size: 14px;
        color: #6f7174;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

// 推荐资源
.pushResource-box {
  padding-bottom: 160px;
  overflow: hidden;

  .pushResource-list::-webkit-scrollbar {
    display: none;
  }

  .pushResource-list {
    display: flex;

    .pushResource-item {
      width: 220px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      margin-right: 24px;
      cursor: pointer;
      display: inline-block;

      .pushResource-cover>img {
        // width: 260px;
        // height: 146px;
        width: 220px;
        height: 164px;
        background: #d8d8d8;
        border-radius: 8px;
        display: block;
      }

      .sonLocation {
        top: 40px;
        // left: 16px;
        left: 10px;
        bottom: -87px;
      }

      .cover-title {
        width: 200px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .cover-line {
        margin-top: 16px;
        width: 20px;
        height: 2px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 1px;
      }

      .pushResource-title {
        width: 100%;
        font-size: 16px;
        color: #3b3d41;
        margin-top: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pushResource-type {
        font-size: 12px;
        color: #909399;
        margin-top: 12px;
      }

      .cover-info {
        color: white;
        // padding: 10px;
        // border: 1px solid red;
      }
    }

    .pushResource-item:hover {
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
}

// 活动锦囊
.activity-box {
  padding-bottom: 160px;

  .activity-list {
    display: flex;

    .activity-item {
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      width: 336px;
      cursor: pointer;
      margin-right: 24px;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;

      .activity-content {
        .sonLocation {
          top: 40px;
          left: 16px;
        }

        .activity-title {
          // width: 140px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .activity-line {
          margin-top: 16px;
          width: 20px;
          height: 2px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 1px;
        }
      }

      .activity-cover {
        width: 220px;
        height: 164px;
        background-image: url("../assets/home/png-cover.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-size: contain;
      }

      .activity-cover>img {
        width: 220px;
        height: 164px;
        display: block;
      }

      .courseware-info {
        display: flex;
        color: white;
        padding: 10px;
      }
    }

    .activity-item:nth-child(6) {
      margin-right: 0px;
    }

    .activity-item:hover {
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
}

// 二级标题
.h2Title-box {
  display: flex;
  align-items: center;
  margin: 0 auto 40px;

  .titleLeftIcon>img {
    width: 18px;
    height: 12px;
    margin-right: 14px;
    display: block;
  }

  .titleRightIcon>img {
    width: 18px;
    height: 12px;
    margin-left: 14px;
    display: block;
  }
}


// 复用
.seemore-box {
  position: absolute;
  right: 0;
}

.seemore-text {
  font-size: 16px;
  color: #909399;
  margin-right: 4px;
}

.seemore-img>img {
  width: 8px;
  height: 10px;
  display: block;
}

// 分类
.sort-nav {
  .sort-nav-list {
    display: flex;
    margin-bottom: 36px;

    .sort-nav-item {
      font-size: 14px;
      color: #606266;
      margin-right: 80px;
    }

    .nav-item-active {
      font-size: 14px;
      color: #606266;
      margin-right: 80px;
      color: #4676f8;
      height: 26px;
      border-bottom: 4px solid #4676f8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
</style>
<style  lang="less">
/* 公用样式类 */
.backgrounAll {
  background: #f7f9fc;
}

.centre-content {
  width: 1440px;
  margin: 0 auto;
  min-height: 700px;
  /* min-height: 1100px; */
}

.parentLocation {
  /* 父级定位 */
  position: relative;
}

.sonLocation {
  /* 子定位 */
  position: absolute;
  bottom: 0;
}

.learnNum-img>img,
.shareNum-img>img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  // display: block;
}

.flexBox {
  display: flex;
  align-items: center;
}

.flex-box {
  display: flex;
  align-items: center;
}

.flexBox-space-between {
  display: flex;
  justify-content: space-between;
}

.marLeft-16 {
  margin-left: 16px;
}

.marRight-24 {
  margin-right: 24px;
}

.marRight-10 {
  margin-right: 10px;
}


.marbottom-16 {
  margin-bottom: 16px;
}

.marbottom-24 {
  margin-bottom: 24px;
}

.hoverFinger {
  cursor: pointer;
}

/* 分页 */
.page-box {
  padding-bottom: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  /deep/ .el-select-dropdown {
    position: absolute !important;
    left: 0 !important;
  }
}

.homePage,
.endPage {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  color: #3b3d41;
  line-height: 20px;
  cursor: position;
}

/* 暂无内容 */
.notBox {
  padding: 150px 0;
}

.no-content {
  width: 400px;
  height: 355px;
  margin: 0px auto;
}

.no-content>img {
  width: 400px;
  height: 355px;
  display: block;
}

.no-conent-text {
  font-size: 14px;
  color: #3B3D41;
  margin-top: 15px;
  text-align: center;
  margin: 10px auto;
}

/* 暂无内容样式结束 */
.nowrapText {
  white-space: nowrap;
}

.banner-box {
  img{
    width: 100%;
    height: 100%;
  }
//   margin-top: 100px;
// }

// .recommend-text {
//   font-size: 16px;
//   font-weight: bold;
//   color: #3b3d41;
//   margin-bottom: 16px;
// }

// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 500px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//   background-color: #d3dce6;
}
</style>