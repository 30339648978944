<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="centre-content">
        <!-- 导航 -->
        <div class="article-nav">
          <div v-if="isSearch != true">
            <!-- <div :class="tabActive == 0 ? 'nav-item-active' : 'nav-item'" @click="tabChange(0)">
              全部
            </div>
            <div v-for="item in navList" :class="tabActive == item.cateId ? 'nav-item-active' : 'nav-item'"
              @click="tabChange(item.cateId)">
              {{ item.cateName }}
            </div> -->
            <el-tabs v-model="cateId" @tab-click="tabChange">
              <el-tab-pane :label="item.cateName" :name="item.cateId.toString()" v-for="(item,i) in navList" :key="i"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 列表 -->
        <div v-if="articleList.length != 0">
          <div class="article-list">
            <div class="article-item hoverFinger" v-for="(articleItem,i) in articleList" @click="toDetail(articleItem.artId)" :key="i">
              <div class="article-img">
                <p class="article-cover">
                  <img :src="articleItem.coverImg" alt="" />
                </p>
              </div>
              <div class="article-info parentLocation">
                <p class="article-title">{{ articleItem.title }}</p>
                <p class="article-text">
                  {{ articleItem.summary }}
                </p>
                <div class="icon-box sonLocation">
                  <p class="learn-num-icon">
                    <img src="../assets/article/learn-num.png" alt="" /><span>{{
                      articleItem.viewNum
                    }}</span>
                  </p>
                  <p class="share-icon">
                    <img src="../assets/article/share-icon.png" alt="" /><span>{{ articleItem.shareNum }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-box">
            <p class="homePage hoverFinger" @click="currentChange(1)">首页</p>
            <el-pagination :page-size="perPage" background layout="prev, pager, next" :total="total" pager-count:7
              @current-change="currentChange" :current-page="currentPage">
            </el-pagination>
            <p class="endPage hoverFinger" @click="currentChange(Math.ceil(total / perPage))">
              尾页
            </p>
          </div>
        </div>

        <!-- 暂无内容 -->
        <div class="notBox" v-else>
          <p class="no-content">
            <img src="../assets/home/no-content.png" alt="" />
          </p>
          <p class="no-conent-text">暂无资源，平台正在努力添加资源~</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      navList: [],
      tabActive: 0,
      cateId: 0,
      articleList: [],
      pageNum: 1,
      currentPage: 1, // 当前页码
      total: 0, // 数据总条数
      perPage: 10, // 一页请求数据条数
      isSearch: false,
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    this.getArticleCate();
    this.getArticleList();
    // this.isSearch = this.$route.query.search;
  },
  watch: {
    "$store.state.searchInfo"() {
      this.currentPage = 1;
      this.getArticleList();
    },
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getArticleList();
    },
    tabChange(cateId) {
      this.currentPage = 1;
      this.cateId = cateId.name;
      this.tabActive = cateId.name;
      this.getArticleList();
    },
    toDetail(artid) {
      // this.$router.push(`/articleDetail/${artid}`)
      let pathInfo = this.$router.resolve({
        path: `/articleDetail/${artid}`,
      });
      window.open(pathInfo.href, "_blank");
    },
    getArticleCate() {
      //文章分类
      let data = {};
      request({
        url: "/article/cate",
        method: "post",
        data,
      }).then((res) => {
        this.navList = res.data;
        this.navList.unshift({cateId:0,cateName:'全部'})
      });
    },
    getArticleList() {
      if (
        this.searchType != undefined &&
        this.keyWord != undefined &&
        this.searchType == "article"
      ) {
        this.searchList();
        return;
      }
      //文章列表
      let data = {
        cateId: this.cateId,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/article/lists",
        method: "post",
        data,
      }).then((res) => {
        this.articleList = res.data.list;
        this.total = res.data.count;
      });
    },
    searchList() {
      let data = {
        searchType: this.searchType,
        keyWord: this.keyWord,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/search",
        method: "post",
        data,
      }).then((res) => {
        this.isSearch = true;
        this.articleList = res.data.list;
        this.total = res.data.count;
      });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {
    searchType: function () {
      return this.$store.state.searchInfo.searchType;
    },
    keyWord: function () {
      return this.$store.state.searchInfo.keyWord;
    },
  },
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.article-nav {
  padding: 30px 0px;

  .nav-list {
    display: flex;
    // justify-content: center;
    // height: 40px;
    flex-wrap: wrap;

    .nav-item {
      margin-right: 60px;
      font-size: 16px;
      color: #606266;
      cursor: pointer;
      margin-bottom: 20px;
    }

    .nav-item-active {
      margin-right: 60px;
      font-size: 16px;
      color: #4676f8;
      height: 26px;
      border-bottom: 4px solid #4676f8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 20px;
    }
  }
}

.article-list {
  .article-item {
    display: flex;
    width: 1440px;
    height: 245px;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    margin-bottom: 40px;

    .article-img {
      .article-cover>img {
        width: 436px;
        height: 245px;
        background: #d8d8d8;
        border-radius: 8px;
      }
    }

    .article-info {
      padding: 16px;
      width: 956px;

      .article-title {
        font-size: 22px;
        font-weight: bold;
        color: #3b3d41;
        margin-bottom: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .article-text {
        font-size: 16px;
        color: #606266;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .icon-box {
        font-size: 14px;
        color: #909399;
        bottom: 16px;
        display: flex;
        align-items: center;
        .learn-num-icon{
          display: flex;
          align-items: center;
        }
        .learn-num-icon>img {
          width: 19px;
          height: 13px;
          margin-right: 3px;
        }
        .share-icon{
          display: flex;
          align-items: center;
        }
        .share-icon>img {
          width: 17px;
          height: 17px;
          margin-left: 16px;
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
