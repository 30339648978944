<template>
  <div class="box" @click="toHome()">
    <img src="../assets/home/err404.png" alt="" />
    <p class="errText">很抱歉，您要访问的页面貌似走丢了，我们正在努力寻找</p>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  data() {
    return {}
  },
  methods: {
    toHome() {
      this.$router.go(-1) //返回上一页面
    },
  },
}
</script>
<style scoped>
.box {
  margin: 0 auto;
  text-align: center;
}

.box>img {
  width: 400px;
  height: 389px;
  margin: 0 auto;
  padding-top: 116px;
}

.errText {
  font-size: 14px;
  color: #3B3D41;
  margin-top: 16px;
}
</style>