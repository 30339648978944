<template>
    <div class="backgrounAll">
        <div class='articleDetail-box  animate__animated animate__bounce animate__fadeInUp'>
            <div class="centre-content">
                <div class="articleDetail-content">
                    <div class="articleDetail-header">
                        <p class="articleDetail-title">|{{ detail.title }}</p>
                        <div class="cover-info">
                            <div class="flex-box">
                                <p class="learnNum-img"><img src="../assets/article/learn-num.png" alt=""></p>
                                <span>{{ detail.viewNum }}</span>
                            </div>
                            <div class="flex-box marLeft-16">
                                <p class="shareNum-img"><img src="../assets/home/share-num2.png" alt=""></p>
                                <span>{{ detail.shareNum }}</span>
                            </div>
                            <div class="share-box">
                                <span class="share hoverFinger" @click="share()">分享</span> <span style="color:red;">*</span>
                                <span class="share-text hoverFinger">点击分享生成二维码</span>
                            </div>
                        </div>
                    </div>
                    <div class="articleDetail-info">
                        <p class="box_img" v-html="detail.body"></p>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="shareDialogVisible" width="22%" center>
            <p class="qrCode"><img :src="qrcode" alt="" /></p>
            <span slot="footer" class="dialog-footer">
                <p class="download-btn hoverFinger" @click="downLoad()">保存二维码</p>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import request from '@/utils/request';
export default {
    data () {
        return {
            artId: '',
            detail: {},
            qrcode: '', //分享二维码
            qrcodeUrl: '',  //下载分享二维码
            shareDialogVisible: false
        };
    },
    //接口 一进入页面就执行的 
    mounted () {
        // window, scrollTo(0, 0)
        this.artId = this.$route.params.artid
        this.getArticleDetail();
    },
    updated () { //富文本图片设置宽度百分百
        let DomList = document.getElementsByClassName('box_img')[0].querySelectorAll('img')
        for (let i in DomList) {
            if (DomList[i].style) {
                DomList[i].style.maxWidth = '100%'
                DomList[i].style.height = 'auto'
            }
        }
    },
    //事件方法
    methods: {
        getArticleDetail () {
            let data = { artId: this.artId }
            request({
                url: '/article/detail',
                method: 'post',
                data,
            }).then((res) => {
                this.detail = res.data;
            }).catch((err) => {
                console.log(err)
            })
        },
        share () { //获取分享二维码
            let data = { shareType: 'article', shareResId: this.artId }
            request({
                url: '/share',
                method: 'post',
                data
            }).then((res) => {
                this.shareDialogVisible = true;
                this.qrcode = res.data.qrcode;
                this.qrcodeUrl = decodeURIComponent(res.data.qrcode);
            }).catch((err) => {
                console.log(err)
            })
        },
        downLoad () { //下载
            var a = document.createElement("a");
            var body = document.querySelector("body");

            var base64 = this.qrcodeUrl; // imgSrc 就是base64哈
            var byteCharacters = atob(
                base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
            );
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
                type: undefined,
            });

            a.href = URL.createObjectURL(blob);
            a.download = "分享二维码.png";
            a.style.display = "none";
            body.appendChild(a);
            a.click();
            body.removeChild(a);
            window.URL.revokeObjectURL(a.href);
        },
    },
    //注册子组件
    components: {},
    //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
    computed: {},
    //过滤器
    filters: {
        filtime (val) { },
    },
};
</script>

<style lang='less' scoped>
.articleDetail-box {
    padding: 40px 0 80px;
}

.articleDetail-content {
    width: 1440px;
    // height: 1000px;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    padding: 24px;

    .articleDetail-header {
        position: relative;
        height: 120px;
        width: 1440px;
        border-bottom: 1px solid #DCDFE1;

        .articleDetail-title {
            font-size: 22px;
            font-weight: bold;
            color: #3B3D41;
        }

        .cover-info {
            display: flex;
            position: absolute;
            bottom: 24px;
            font-size: 14px;
            color: #6F7174;

            .learnNum-img>img {
                width: 19px;
                height: 13px;
            }

            .share-box {
                margin-left: 24px;

                .share {
                    font-size: 14px;
                    color: #4676F8;
                }

                .share-text {
                    font-size: 14px;
                    color: #6F7174;
                }
            }
        }
    }

    .articleDetail-info {
        // width: 1392px;
        padding-top: 20px;
        font-size: 20px;
        color: #606266;

        p {
            font-size: 16px;
            color: #6F7174;
            line-height: 32px;
            text-indent: 2em;

        }

        ::v-deep img {
            margin: 20px auto;
        }

        .box_img>p>img {
            margin: 24px auto;
            border: 1px solid red;
            width: 100% !important;
            height: auto !important;
        }
    }
}

// 弹框
.el-dialog,
.el-dialog--center {
    border-radius: 20px;

    .qrCode {
        text-align: center;
    }

    .qrCode>img {
        width: 200px;
        height: 200px;
        background-color: #D8D8D8;
    }

    .download-btn {
        width: 120px;
        height: 37px;
        line-height: 37px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #4676F8;
        font-size: 16px;
        color: #4676F8;
        margin: 0 auto;
    }
}
</style>
