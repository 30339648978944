<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import DevicePixelRatio from './utils/devicePixelRatio';
import { localStorageSet } from "@/utils/localStorage";
export default {
  data() {
    return {};
  },
  mounted() {

    if (this._isMobile()) {
      // alert('手机端');
      // <!--移动端首页路由-->
      // this.$router.replace('/homeAndroid');
      window.addEventListener(
        'orientationchange' in window ? 'orientationchange' : 'resize',
        (function () {
          //判断是屏幕旋转还是resize
          function c() {
            var d = document.documentElement //获取html元素
            var cw = d.clientWidth || 750
            // console.log(cw)
            d.style.fontSize = 10 * (cw / 375) > 40 ? 40 + 'px' : 8 * (cw / 375) + 'px'
            //   d.style.fontSize = 20 * (cw / 375) > 40 ? 40 + 'px' : 20 * (cw / 375) + 'px'
          }
          c()
          return c
        })(),
        false
      )

    } else {
      // alert('pc端');
      // <!--pc端首页路由-->
      // this.$route.name
      // this.$router.replace('/index');
    }

  },

  methods: {
    // 判断移动端还是pc端
    // <!--_isMobile方法-->
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可视区域宽度
      var scale = devicewidth / 1920; // 除以设计稿的尺寸
      document.body.style.zoom = scale; // 放大缩小相应倍数
    },
    getConfigPage() {
      let data = {};
      this.$request({
        url: "/config",
        method: "post",
        data,
      })
        .then((res) => {
          localStorageSet("configData", res.data);
          this.$store.commit('setConfigData', res.data)

          document.title = this.$store.state.configData.siteName
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
  created() {
    // new DevicePixelRatio().init();
    // this.bodyScale();
    this.getConfigPage();
  },
};
</script>

<style lang="less">
// Message Box 适配移动端，宽度小于720px时宽度使用下面这个
@media (max-width: 720px) {
  .el-message-box {
    width: 320px !important;
  }
}
/*css主要部分的样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 8px; /*对垂直流动条有效*/
    height: 8px; /*对水平流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.151);
    // background-color: rosybrown; 
}
 
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: #DDDEE0;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); 
}
 
/*定义滑块悬停变化颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb:hover{
    background-color: #C7C9CC;
}
 
/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
  //  background-color: cyan; 
}
 
/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
     background: khaki; 
}
 
/* 隐藏滚动条 */
/* ::-webkit-scrollbar {
    display: none;
} */
</style>
