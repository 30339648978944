<template>
  <!-- 批量导入弹框 -->
  <el-dialog title="批量导入" width="25%" :visible="visible" center @close="close" v-loading="loading">
    <div class="">
      <div class="dialog-text">
        模板：<span style="
            font-size: 14px;
            font-weight: bold;
            color: #4676f8;
            margin-left: 10px;
          " @click="downLoad">点击下载模板</span>
      </div>
      <div class="displayFlex" style="margin-bottom: 24px; margin-top: 10px">
        <div class="dialog-text">注意：</div>
        <div class="dialog-text">
          <p>1.表格模板不能随意变动</p>
          <p>2.模板中所有标注“*”项为必填项</p>
          <p>3.请按每列批准说明要去填写用户信息</p>
          <p>4.建议每次导入数据不超过3000条</p>
        </div>
      </div>
      <div class="flex-box">
        <div class="dialog-text" style="align-self: start; line-height: 33px">
          <span style="color: red">*</span>上传文件：
        </div>
        <div class="import-btn hoverFinger">
          <el-upload ref="upload" class="upload-demo" action="javascript:void(0);" accept=".xls,.xlsx"
            :show-file-list="true" :limit="1" :auto-upload="false" :on-change="onChange" :on-remove="onRemove">
            <el-button size="small" type="primary" style="font-size: 14px">选择文件</el-button>
            <span style="font-size: 14px; color: #c0c4cc; margin-left: 10px">
              文件格式xls、xlsx文件
            </span>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 认</el-button>
      <el-button @click="close">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import request2 from "@/utils/request2.js";
export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    // 显隐
    visible: Boolean,
    downloadTemplateUrl: {
      require: true,
      type: String,
    },
    requestUrl: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
      this.file = null;
      this.$refs.upload.clearFiles();
    },
    async submit() {
      if (!this.file) {
        this.$message({
          type: "info",
          message: "请选择要上传的文件",
        });
        return;
      }

      var formData = new FormData();
      formData.append("xltuploadfile", this.file);
      this.loading = true;
      try {
        // 上传文件
        let filePath = await this.getStudentUpload(formData);
        // 解析并提交文件
        await studentImport.call(this, filePath);
      } catch (e) {
      } finally {
        this.loading = false;
      }

      function studentImport(filePath) {
        //学生导入
        let data = {
          filePath: filePath,
        };
        this.$request({
          url: this.requestUrl,
          method: "post",
          data,
        })
          .then((res) => {
            this.visible = false;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$emit("submitSuccess");
            this.close();
          });
      }
    },
    getStudentUpload(formData) {
      return new Promise((resolve, reject) => {
        //上传
        request2({
          url: "/upload",
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res.data.filePath);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downLoad() {
      //导入下载模板
      window.location.href = this.downloadTemplateUrl;
    },
    onChange(file) {
      this.file = file.raw;
    },
    onRemove() {
      this.file = null;
    },
  },
};
</script>
<style lang='less' scoped></style>