<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="messageManage-box centre-content">
        <h3>通知管理</h3>

        <div class="message-title" style="display: none;">
          <div class="ipt-box flex-box">
            <div class="flex-box">
              <p class="nowrapText ipt-text">通知标题：</p>
              <input class="input-box" type="text" @input="nameInput($event)" placeholder="通知标题" />
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">状态：</p>
              <div class="marRight-24">
                <el-select v-model="stateValue" filterable placeholder="请选择" @change="selectBank">
                  <el-option v-for="item in state" :key="item.title" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="btn-box flex-box">
              <p class="search-btn">搜索</p>
              <p class="reset-btn">重置</p>
            </div>
          </div>
        </div>

        <div class="message-tab">
          <div class="btn-box" style="margin-bottom: 24px">
            <div class="flex-box">
              <p class="add-btn hoverFinger" @click="addStudent()">添加通知</p>
              <p class="batchDel-btn hoverFinger" @click="getMessagetBathDel()">
                批量删除
              </p>
            </div>
            <!-- <div class="listText">自定义列表</div> -->
          </div>
          <!-- table部分 -->
          <div>
            <el-table ref="multipleTable" :data="messageList" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"> </el-table-column>

              <el-table-column prop="title" label="通知标题" width="120">
              </el-table-column>
              <el-table-column prop="content" label="通知内容" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="nid" label="状态" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="createdBy" label="添加者" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="createdAt" label="添加时间" show-overflow-tooltip>
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                  <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                  <el-button @click="handleDelete(scope.$index, scope.row)" type="text" size="small"
                    style="color: #fc4646">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加通知弹框 -->
    <!-- <div class="addDialog">
      <el-dialog
        title="添加通知"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <div class="dialog-box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="dialog-ipt-box">
              <el-form-item label="通知标题" prop="name" class="ipt-text">
                <el-input
                  class="dialog-input-box"
                  v-model="ruleForm.name"
                  placeholder="请输入姓名"
                  @input="nameInput($event)"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="通知内容" prop="studentNo" class="ipt-text">
                <el-input
                  class="textarea-box"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="textarea"
                >
                </el-input>
              </el-form-item>
            </div>

            <div class="line"></div>
            <el-form-item class="addStudent-btn-box">
              <el-button
                class="yesBtn"
                type="primary"
                @click="submitForm('ruleForm')"
                >确认</el-button
              >
              <el-button class="noBtn" @click="resetForm('ruleForm')"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div> -->

    <handleDialog :visible.sync="centerDialogVisible" :formData="ruleForm" :handleState="handleState"
      @submitSuccess="getMessageList" />
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import handleDialog from "./components/handleDialog.vue";
export default {
  data() {
    return {
      messageList: [], //消息通知列表
      perPageL: 10, // 每页多少个
      pageNum: 1, //页码
      currentPage: 1, //当前页码
      total: 0,
      secretKey: "",

      stateValue: "",
      state: [
        {
          title: "未发布",
          id: 1,
        },
        {
          title: "已发布",
          id: 2,
        },
      ], //状态
      messageNid: "", //删除nid
      centerDialogVisible: false, // 通知弹框
      messageTitle: "", //标题
      textarea: "", //内容
      ruleForm: {
        name: "", //标题
        content: "", //内容
      },
      //   rules: {
      //     name: [
      //       { required: true, message: "请输入姓名", trigger: "blur" },
      //       { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
      //     ],
      //     content: [
      //       { required: true, message: "请输入学号", trigger: "blur" },
      //       { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
      //     ],
      //   },

      handleState: "新增",
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    let Base64 = require("js-base64").Base64;
    let secretKey = localStorageGet("secretKey");
    this.secretKey = Base64.encode(secretKey);
    this.getMessageList();
  },
  //事件方法
  methods: {
    handleCurrentChange(val) {
      //切换页码
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getMessageList();
    },
    handleSizeChange(val) {
      //每页多少条数据
      this.perPageL = val;
      window.scrollTo(0, 0);
      this.getMessageList();
    },
    getMessageList() {
      //列表
      let data = {
        perPageL: this.perPageL,
        pageNum: this.pageNum,
        enkey: this.secretKey,
      };
      request({
        url: "/notice/notices",
        method: "post",
        data,
      })
        .then((res) => {
          this.total = res.data.count;
          this.messageList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNoticeAdd() {
      //添加
      let data = {
        title: this.messageTitle,
        content: this.textarea,
      };
      request({
        url: "/notice/add",
        method: "post",
        data,
      })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    getMessagetDel() { //删除
      let data = {
        nid: this.messageNid,
      };
      request({
        url: "/notice/del",
        method: "post",
        data,
      })
        .then((res) => {
          this.getMessageList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMessagetBathDel() {
      //批量删除
      let data = {
        nids: this.multipleSelection.map((item) => item.nid).join("|"),
      };
      request({
        url: "/notice/delall",
        method: "post",
        data,
      })
        .then((res) => {
          this.getMessageList();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    nameInput(e) {
      //通知标题
      this.messageTitle = e;
    },
    addStudent() {
      this.centerDialogVisible = true;
      this.handleState = "新增";
      this.ruleForm = {};
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getNoticeAdd();
          this.textarea = "";
          this.messageTitle = "";
          this.getMessageList();
          this.centerDialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    selectBank(stateValue) {
      //状态选择
      this.stateValue = stateValue;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete(index, row) {
      // console.log(row)
      //获取删除nid
      this.messageNid = row.nid;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getMessagetDel();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClick(row) {
      //查看
      this.centerDialogVisible = true;
      this.handleState = "查看";
      // this.ruleForm = row;
      this.ruleForm = JSON.parse(JSON.stringify(row));
    },
    handleEdit(row) {
      this.centerDialogVisible = true;
      this.handleState = "编辑";
      this.ruleForm = JSON.parse(JSON.stringify(row));
    },
  },
  //注册子组件
  components: {
    handleDialog,
  },
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scope>
h3 {
  height: 45px;
  border-bottom: 1px solid #dcdfe6;
}

.messageManage-box {
  padding: 40px 0px 60px;
}

.message-title {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
  border-radius: 8px;
  margin-bottom: 16px;

  .ipt-box {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;

    .ipt-text {
      font-size: 14px;
      color: #303133;
    }

    .input-box {
      padding: 8px;
      width: 200px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #c0c4cc;
      // caret-color: #40B8FA;
      outline-color: #c0c4cc;
      margin-right: 24px;
    }

    /deep/.el-input__inner {
      width: 300px;
      border: none;
    }
  }

  .btn-box {
    .search-btn {
      margin-right: 16px;
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: linear-gradient(317deg,
          rgba(70, 118, 248, 0.99) 0%,
          #54c6f8 100%);
      box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
      border-radius: 4px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }

    .reset-btn {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #c0c4cc;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606266;
    }
  }
}

.message-tab {
  margin-top: 16px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
  border-radius: 8px;

  .btn-box {
    display: flex;
    justify-content: space-between;

    .add-btn {
      width: 96px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #4676f8;
      font-size: 14px;
      color: #4676f8;
      margin-right: 24px;
    }

    .batchDel-btn {
      width: 96px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #fc4646;
      font-size: 14px;
      color: #fc4646;
    }

    .el-button--primary {
      color: #4676f8;
      border: none;
    }

    .listText {
      font-size: 14px;
      color: #4676f8;
    }
  }
}

.page-box {
  padding-top: 30px;
}
</style>