// export default {
//     baseUrl: 'https://ow-xltapi.x-lingtong.com/',
//     requestTimeOut: 30000,
//     signKey: '3a91ca99a9497bd1b5edd352e78bd7a5',
//     // schoolId:1000
// };
// 本地
// export const baseUrl = '/baseURL';
// 部署
// export const baseUrl = 'https://reslibapi-test.x-lingtong.com';
// 测试：https://res-test.x-lingtong.cn/api    正式：https://res.x-lingtong.cn/api
export const baseUrl = 'https://res.x-lingtong.cn/api';
// export const baseUrl = 'https://res-test.x-lingtong.cn/api';
export const requestTimeOut = 30000
export const signKey = 'sChGwktY11eId678BN4F3HfVJg7Tyl86';