<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="video-detail-box">
        <div class="centre-content">
          <div class="video-detail-top">
            <h2 style="padding: 40px 0px 20px">{{ courseInfo.courseTitle }}</h2>
            <div class="share-box flex-box">
              <div class="flex-box">
                <p class="learn-num-icon">
                  <img src="../assets/home/learn-num2.png" alt="" />
                </p>
                <p>{{ courseInfo.studyNum }}</p>
              </div>
              <div class="flex-box">
                <p class="share-icon-icon">
                  <img src="../assets/article/share-icon.png" alt="" />
                </p>
                <p>{{ courseInfo.shareNum }}</p>
              </div>
              <span class="share hoverFinger" @click="share()">分享</span>
              <span style="color: red">*</span><span class="hoverFinger">点击分享生成二维码</span>
            </div>
            <div class="play-video-box">
              <div class="videoPlay">
                <div class="player-container">
                  <div id="artplayer" class="video-player-box"></div>
                </div>
              </div>
              <div class="videoPlayList">
                <h2 style="text-align: center; padding-bottom: 16px">
                  章节目录
                </h2>
                <div class="catalogue-box">
                  <!-- 无章节 -->
                  <div v-if="courseLessonType == 1" class="No-chapter">
                    <div v-for="item in courseLessons" :key="item.id">
                      <div class="flex-box No-chapter-classtitle">
                        <p class="playIcon" v-if="item.contentId != contentid">
                          <img src="../assets/home/play-icon.png" alt="" />
                        </p>
                        <p class="playIcon" v-else>
                          <img src="../assets/home/playing-icon.png" alt="" />
                        </p>
                        <p @click="toDetail(item)"
                          :class="item.contentId == contentid ? 'active hoverFinger' : 'keshi hoverFinger'">
                          {{ item.courseTitle }}
                        </p>
                      </div>
                      <div class="flexBox-space-between tex-color marbottom-16 ">
                        <div class="flex-box">
                          <p class="share-icon">
                            <img src="../assets/home/share-icon.png" alt="" />
                          </p>
                          <span>{{ item.shareNum }}</span>
                          <!-- <span style="color: #4676f8; margin-left: 10px">分享</span> -->
                        </div>
                        <div class="flex-box">
                          <p class="time-icon">
                            <img src="../assets/home/time-icon.png" alt="" />
                          </p>
                          <span>{{ item.duration }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 有章无节  -->
                  <div v-if="courseLessonType == 2">
                    <div class="zhang" v-for="item in courseLessons" :key="item.id">
                      {{ item.chapterName }}
                      <div class="jie" v-for="item2 in item.lessons" :key="item2.id">
                        <div class="flex-box">
                          <p class="playIcon" v-if="item2.contentId != contentid">
                            <img src="../assets/home/play-icon.png" alt="" />
                          </p>
                          <p class="playIcon" v-else>
                            <img src="../assets/home/playing-icon.png" alt="" />
                          </p>
                          <p @click="toDetail(item2)"
                            :class="item2.contentId == contentid ? 'active hoverFinger' : 'keshi hoverFinger'">
                            {{ item2.courseTitle }}
                          </p>
                        </div>
                        <div class="keshi-box">
                          <div class="flexBox-space-between tex-color">
                            <div class="flex-box">
                              <p class="share-icon">
                                <img src="../assets/home/share-icon.png" alt="" />
                              </p>
                              <span>{{ item2.shareNum }}</span>
                              <!-- <span style="color: #4676f8; margin-left: 10px">分享</span> -->
                            </div>
                            <div class="flex-box">
                              <p class="time-icon">
                                <img src="../assets/home/time-icon.png" alt="" />
                              </p>
                              <span>{{ item2.duration }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 有章有节有课时 -->
                  <div v-if="courseLessonType == 3">
                    <div class="zhang" v-for="item in courseLessons" :key="item.id">
                      <p class="zhangTitle">{{ item.chapterName }}</p>
                      <div class="keshi" v-for="item4 in item.lessons" :key="item4.id">
                        <div class="keshi-box">
                          <div class="flex-box" style="padding-left: 10px;">
                            <p class="playIcon" v-if="item4.contentId != contentid">
                              <img src="../assets/home/play-icon.png" alt="" />
                            </p>
                            <p class="playIcon" v-else>
                              <img src="../assets/home/playing-icon.png" alt="" />
                            </p>
                            <p @click="toDetail(item4)" id="boxCenter"
                              :class="item4.contentId == contentid ? 'active hoverFinger' : 'keshi hoverFinger'">
                              {{ item4.courseTitle }}
                            </p>
                          </div>
                          <div class="flexBox-space-between tex-color">
                            <div class="flex-box">
                              <p class="share-icon">
                                <img src="../assets/home/share-icon.png" alt="" />
                              </p>
                              <span>{{ item4.shareNum }}</span>
                              <!-- <span style="color: #4676f8; margin-left: 10px">分享</span> -->
                            </div>
                            <div class="flex-box">
                              <p class="time-icon">
                                <img src="../assets/home/time-icon.png" alt="" />
                              </p>
                              <span>{{ item4.duration }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="jie" v-for="item2 in item.units" :key="item2.id">
                        <p class="jieTitle">{{ item2.unitName }}</p>
                        <div class="keshi-box" v-for="item3 in item2.lessons" :key="item3.id">
                          <div class="flex-box">
                            <p class="playIcon" v-if="item3.contentId != contentid">
                              <img src="../assets/home/play-icon.png" alt="" />
                            </p>
                            <p class="playIcon" v-else>
                              <img src="../assets/home/playing-icon.png" alt="" />
                            </p>
                            <!-- class="keshi" -->
                            <p @click="toDetail(item3)"
                              :class="item3.contentId == contentid ? 'active hoverFinger' : 'keshi hoverFinger'">
                              {{ item3.courseTitle }}
                            </p>
                          </div>
                          <div class="flexBox-space-between tex-color">
                            <div class="flex-box">
                              <p class="share-icon">
                                <img src="../assets/home/share-icon.png" alt="" />
                              </p>
                              <span>{{ item3.shareNum }}</span>
                              <!-- <span style="color: #4676f8; margin-left: 10px">分享</span> -->
                            </div>
                            <div class="flex-box">
                              <p class="time-icon">
                                <img src="../assets/home/time-icon.png" alt="" />
                              </p>
                              <span>{{ item3.duration }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- 左边详情介绍部分 -->
          <div class="course-detail-introduce">
            <div class="course-detail-left">
              <tabDetails ref="tabDetails" :courseid="courseid" :courseDetail="courseDetail" :menuActionId="contentid"
                @toCoursePlay="toDetail" />
              <!-- @toCoursePlay="toCoursePlay" -->
            </div>

            <!-- 教师团队 -->
            <div class="course-detail-right">
              <div class="teacher-list">
                <h3>教师团队</h3>
                <div class="teacher-item" v-for="courseLectorsItem in courseLectors" :key="courseLectorsItem.id">
                  <div class="flex-box marbottom-16">
                    <p class="teacher-cover">
                      <img :src="courseLectorsItem.lectorAvatar" alt="" />
                    </p>
                    <p class="teacher-name">
                      {{ courseLectorsItem.lectorName }}
                    </p>
                  </div>
                  <p>
                    {{ courseLectorsItem.lectorDesc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 相关推荐 -->
          <div class="course-recommend">
            <div class="flexBox-space-between">
              <h3>相关推荐</h3>
              <div class="block">
                <!-- <el-pagination layout="prev, pager, next" :total="30">
                                </el-pagination> -->
                <el-pagination :page-size="4" background layout="prev, pager, next" :total="total" pager-count:7
                  @current-change="currentChange" :current-page="currentPage">
                </el-pagination>
              </div>
            </div>
            <div class="course-list">
              <div class="course-item" v-for="item in courseRecommendList" :key="item.id" @click="
                $router.push({
                  name: 'courseDetail',
                  params: { courseid: item.courseId },
                })
                ">
                <div class="course-cover">
                  <div class="cover-img">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <div class="cover-info">
                    <div class="flex-box">
                      <p class="learnNum-img">
                        <img src="../assets/home/learn-num.png" alt="" />
                      </p>
                      <span>{{ item.studyNum }}</span>
                    </div>
                    <div class="flex-box marLeft-16">
                      <p class="shareNum-img">
                        <img src="../assets/home/share-num.png" alt="" />
                      </p>
                      <span>{{ item.shareNum }}</span>
                    </div>
                  </div>
                </div>
                <div class="course-info">
                  <p class="course-title">{{ item.courseTitle }}</p>
                  <p class="course-introduce">{{ item.courseLecturer }}</p>
                  <p class="courseHover">
                    <img src="../assets/home/course-hover-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="shareDialogVisible" width="22%" center>
      <p class="qrCode"><img :src="qrcode" alt="" /></p>
      <span slot="footer" class="dialog-footer">
        <p class="download-btn hoverFinger" @click="downLoad()">保存二维码</p>
        <!-- <el-button @click="shareDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="shareDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Artplayer from "artplayer";
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import tabDetails from "./courseDetail/components/tabDetails.vue";
export default {
  data() {
    return {
      type: 1, // 1 课程介绍 2 章节目录 3 课程素材
      courseid: "", //课程id
      contentid: "", //课时id
      courseInfo: {}, //课程信息
      courseRecommendList: [], //课程推荐列表
      total: 0,
      currentPage: 1, // 当前页码

      courseLectors: [], //讲师
      courseLessons: [], //章节目录
      courseLessonsOpenMenus: [], //当前展开的章节目录
      sucaiTotal: "",
      sucaiList: [], //素材列表
      centerDialogVisible: false,

      isCollapse: false,
      courseLessonType: "", //  1 无章节 2 有章无节 3 有章有节有课时

      courseDetail: {},
      artplayer: null,
      shareDialogVisible: false,
      qrcode: "", //课程分享二维码
      qrcodeUrl: "", //下载图片
      // studentManage
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    this.courseid = this.$route.params.courseid;
    this.contentid = this.$route.query.contentId;
    this.courseLessonType = this.$route.query.courseLessonType;
    this.getCourseDetail();
    this.getCourseRecommend();
    this.getCoursePlayurl();
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getCourseRecommend();
    },
    typeChange(type) {
      this.type = type;
    },
    getCourseDetail() {
      let data = { courseId: this.courseid };
      request({
        url: "/course/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.courseDetail = res.data;
          this.courseInfo = res.data.courseInfo; //课程信息
          this.courseLectors = res.data.courseLectors; //课程老师
          this.courseLessons = res.data.courseLessons; //课程目录
          this.courseLessonsOpenMenus = this.getMenuIndexList(
            res.data.courseLessons
          ); // 计算所有的课程目录
          this.courseLessonType = res.data.courseLessonType; //  1 无章节 2 有章无节 3 有章有节有课时
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCourseRecommend() {//课程推荐
      let data = { recommendNum: 10, cateId: 0 };
      request({
        url: "/course/recommend",
        method: "post",
        data,
      })
        .then((res) => {
          this.courseRecommendList = res.data;
          this.total = res.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCoursePlayurl() {//课程播放
      let data = { courseId: this.courseid, contentId: this.contentid };
      request({
        url: "/course/playurl",
        method: "post",
        data,
      })
        .then((res) => {
          if (!this.artplayer) {
            this.artplayer = new Artplayer({
              container: "#artplayer",
              url: res.data.playurl,
              playbackRate: true,
              setting: true, // 设置 （倍速）
              fullscreen: true, //全屏
              // fullscreenWeb: true, //网页全屏
              pip: true, //画中画
              autoplay: true, //自动播放
              // poster: res.data.coverImg, //封面图
              backdrop: true, //背景
              theme: "#fff", //进度条颜色
              autoSize: true,
            });
          } else {
            this.artplayer.switchUrl(res.data.playurl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    share() {      // 课程分享弹框
      let data = { shareType: "course", shareResId: this.courseid };
      request({
        url: "/share",
        method: "post",
        data,
      })
        .then((res) => {
          this.shareDialogVisible = true;
          this.qrcode = res.data.qrcode;
          this.qrcodeUrl = decodeURIComponent(res.data.qrcode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downLoad() {
      //下载
      var a = document.createElement("a");
      var body = document.querySelector("body");

      var base64 = this.qrcodeUrl; // imgSrc 就是base64哈
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });

      a.href = URL.createObjectURL(blob);
      a.download = "分享二维码.png";
      a.style.display = "none";
      body.appendChild(a);
      a.click();
      body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    },

    toDetail(item) {
      this.contentid = item.contentId;
      this.courseid = item.courseId;
      window, scrollTo(0, 0);
      this.getCoursePlayurl();
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    getMenuIndexList(list) {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        let item1 = list[i];
        let children = item1.units || item1.lessons;

        if (children && children.length > 0) {
          arr.push(i + "");
          for (let j = 0; j < children.length; j++) {
            let item2 = children[j];

            if (item2.lessons && item2.lessons.length > 0) {
              arr.push(i + "-" + j);
            }
          }
        }
      }
      return arr;
    },
  },
  //注册子组件
  components: { tabDetails },
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
  color: #3b3d41;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  color: #3b3d41;
}

ul>li {
  border: 1px solid #dcdfe1;
}

.video-detail-box {
  padding-bottom: 50px;
}

.video-detail-top {
  .No-chapter {
    .No-chapter-classtitle {
      margin-bottom: 10px;
    }
  }

  .share-box {
    margin-bottom: 16px;
    font-size: 14px;
    color: #6f7174;
    bottom: 0;

    .learn-num-icon>img {
      // width: 19px;
      height: 13px;
      margin-right: 4px;
      display: block;
    }

    .share-icon-icon>img {
      width: 19px;
      height: 19px;
      margin-left: 16px;
      margin-right: 4px;
      display: block;
    }

    .share {
      color: #4676f8;
      margin-left: 16px;
    }
  }

  .play-video-box {
    display: flex;

    .videoPlay {
      width: 1000px;
      height: 563px;
      background: #d8d8d8;
      border-radius: 8px;
      // object-fit: cover;

      .video-player-box {
        height: 563px;
        // object-fit: cover;
      }
    }


    .videoPlayList {
      padding: 16px;
      width: 440px;
      height: 530px;
      background: #d8d8d8;
      border-radius: 8px;
      background: #ffffff;
      // overflow: hidden;
      // overflow-y: scroll;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

      .catalogue-box {
        overflow: hidden;
        overflow-y: scroll;
        height: 490px;
      }

      .zhang {
        font-size: 16px;
        color: #303133;
        margin-bottom: 16px;

        .zhangTitle {
          margin-bottom: 12px;
        }
      }

      .jie {
        font-size: 14px;
        color: #303133;
        margin: 10px;

        .jieTitle {
          margin-bottom: 10px;
        }
      }

      .playIcon>img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        display: block;
      }

      .tex-color {
        padding-left: 16px;
        font-size: 14px;
        color: #606266;
        margin-top: 5px;
        // margin-bottom: 5px;
        // border: 1px solid red;
      }

      .share-icon>img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: block;
      }

      .time-icon>img {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        display: block;
      }

      .keshi-box {
        margin-bottom: 12px;
        // border: 1px solid red;

        .keshi {
          font-size: 14px;
          color: #606266;
          margin-left: 4px;
          width: 379px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .catalogue-box::-webkit-scrollbar {
      overflow-y: scroll;
    }

    .catalogue-box::-webkit-scrollbar-thumb {
      height: 109px;
      background: #dcdfe6;
      background: #dcdfe6;
      border-radius: 12px;
    }
  }
}

.course-detail-introduce {
  padding: 50px 0px;
  display: flex;
  justify-content: space-between;

  .course-detail-left {
    width: 900px;
    // height: 800px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .type-nav {
      display: flex;
      justify-content: center;
      height: 57px;
      line-height: 57px;
      border-bottom: 1px solid #e8e8e8;

      .nav-item {
        font-size: 16px;
        font-weight: bold;
        color: #303133;
        margin-right: 100px;
      }

      .nav-item-active {
        font-size: 16px;
        font-weight: bold;
        color: #4676f8;
        margin-right: 100px;
        border-bottom: 4px solid #4676f8;
      }

      .nav-item:nth-child(3) {
        margin-right: 0px;
      }

      .nav-item-active:nth-child(3) {
        margin-right: 0px;
      }
    }

    .type-info {
      // padding: 24px;
      margin: 24px;
    }
  }

  .course-detail-right {
    width: 490px;
    max-height: 800px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    // overflow-y: scroll;
    overflow-y: auto;

    .teacher-list {
      padding: 16px 24px;
      overflow-y: scroll;
      overflow: hidden;

      .teacher-item {
        margin-top: 16px;

        .teacher-cover>img {
          display: block;
          width: 60px;
          height: 60px;
          background: #d8d8d8;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          margin-right: 16px;
        }

        .teacher-name {
          font-size: 16px;
          font-weight: bold;
          color: #303133;
        }
      }
    }
  }

  // 滚动条
  .course-detail-right::-webkit-scrollbar {
    overflow-y: scroll;
  }

  ::-webkit-scrollbar-thumb {
    height: 109px;
    background: #dcdfe6;
    border-radius: 12px;
  }
}

// 相关推荐
.course-recommend {
  padding: 24px;
  width: 1440px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .course-list {
    display: flex;
    padding-top: 24px;
    overflow: hidden;

    .course-item {
      width: 336px;
      height: 310px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;
      margin-right: 32px;
      margin-bottom: 40px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      .course-cover {
        .cover-img>img {
          width: 336px;
          height: 224px;
          border-radius: 8px;
          background-color: #6F7174;
          display: block;
        }

        position: relative;
      }

      .cover-info {
        width: 303px;
        background: linear-gradient(180deg,
            rgba(54, 54, 56, 0) 0%,
            rgba(54, 54, 56, 0.25) 100%);
        border-radius: 0px 0px 8px 8px;
        position: absolute;
        bottom: 0px;
        display: flex;
        padding: 17px 16px 7px;
        font-size: 12px;
        color: #ffffff;
      }

      .course-info {
        padding: 16px;
        position: relative;

        .course-title {
          font-size: 16px;
          color: #3b3d41;
          margin-bottom: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .course-title :hover {
          color: #4676f8;
        }

        .course-introduce {
          font-size: 12px;
          color: #909399;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .courseHover {
          position: absolute;
          right: 0;
          bottom: -8px;
          display: none;
        }

        .courseHover>img {
          width: 49px;
          height: 48px;
          display: block;
        }
      }
    }

    .course-item:hover {
      transform: scale(1.03);
    }

    .course-item:hover .courseHover {
      display: inline-block;
      animation: mymove ease 2s;
      animation-iteration-count: 1;
      /*设置动画播放次数*/
      animation-fill-mode: forwards;
    }

    @keyframes mymove {
      from {
        // top: 80px;
        opacity: 0;
      }

      to {
        // top: 40px;
        opacity: 1;
      }
    }
  }
}

// table 样式
.jieBox {
  border: 10px solid red;
}

/deep/.el-menu--inline,
/deep/.el-submenu,
/deep/.el-menu-item {
  border: 1px solid #dcdfe1 !important;
  border-bottom: 1px solid #dcdfe1 !important;
  margin: -1px -1px -1px -1px !important;
  // border-radius: 6px !important;
}


/deep/.el-submenu__title:hover,
/deep/.el-menu-item:hover {
  background-color: transparent !important;
}

/deep/.el-submenu__title {
  padding-left: 10px !important;
  height: 50px !important;
}

/deep/.el-submenu .el-menu-item {
  padding: 0px 20px !important;
}

/deep/.el-menu-item,
/deep/.is-active {
  color: black !important;
}

.active {
  color: #4676f8;
  font-size: 14px;
  margin-left: 4px;
  width: 379px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// 弹框
.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode>img {
    width: 200px;
    height: 200px;
    background-color: #6f7174;
  }

  .download-btn {
    width: 120px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #4676F8;
    font-size: 16px;
    color: #4676F8;
    margin: 0 auto;
  }
}
</style>
