import Vue from 'vue';
import VueRouter from 'vue-router';
import homeLayout from '@/layout/homeLayout';
import androidLayout from '@/layout/androidLayout';
import index from '../views/home';
import err404 from '../views/err404.vue';
import course from '../views/course'; //精品示范课
import courseDetail from '@/views/courseDetail' //精品示范课详情
import video from '@/views/video' //心理微课
import courseware from '@/views/courseware' //课件
import article from '@/views/article'; //心理文章
import articleDetail from '@/views/articleDetail'; //心理文章详情
import material from '@/views/material' //素材中心 
import onlinePreview from '@/views/onlinePreview'; //在线预览页面
import materialDetail from '@/views/materialDetail'; //素材中心详情
import book from '@/views/book'; //心理图书
import bookDetail from '@/views/bookDetail'; //心理图书详情
import activity from '@/views/activity'; // 活动锦囊

import videoDetail from '@/views/videoDetail'; //微视频播放详情
import coursePlay from '@/views/coursePlay'; //课程播放页
import login from '@/views/login'; // 登录
import studentManage from "@/views/studentManage"; //学生管理\
import importKey from '@/views/user/importKey'; //导入密钥
import userManage from '@/views/user/userManage'; //用户管理
import messageManage from '@/views/user/messageManage'; // 通知管理
import myInfo from '@/views/user/myInfo'; //修改资料
import creditSet from '@/views/user/creditSet'; //学分设置
import findPassword from '@/views/user/findPassword'; // 找回密码
// import { baseUrl } from '@/settings.js';





Vue.use(VueRouter);

// PC 端
const routesPC = [
    // pc端
    {
        path: '/',
        name: 'home',
        component: homeLayout,
        redirect: '/index', //路由重定向
        children: [{
            path: 'index',
            name: 'index',
            meta: {
                title: '心灵通 - 资源库',
            },
            component: index,
        },
        {
            path: 'course',
            name: 'course',
            meta: {
                title: '课程',
            },
            component: course,
        },
        {
            path: 'courseDetail/:courseid',
            name: 'courseDetail',
            meta: {
                title: '课程详情',
            },
            component: courseDetail,
        },
        {
            path: 'video',
            name: 'video',
            meta: {
                title: '心理微课'
            },
            component: video
        },
        {
            path: 'courseware',
            name: 'courseware',
            meta: {
                title: '课件'
            },
            component: courseware
        },
        {
            path: 'article',
            name: 'article',
            meta: {
                title: '心理文章'
            },
            component: article
        },
        {
            path: 'articleDetail/:artid',
            name: 'articleDetail',
            meta: {
                title: '心理文章 - 详情'
            },
            component: articleDetail
        },
        {
            path: 'material',
            name: 'material',
            meta: {
                title: '素材中心'
            },
            component: material
        },
        {
            path: 'onlinePreview',
            name: 'onlinePreview',
            meta: {
                title: '在线预览'
            },
            component: onlinePreview
        },
        {
            path: 'materialDetail/:resid',
            name: 'materialDetail',
            meta: {
                title: '素材中心详情',
                // keepAlive: true
            },
            component: materialDetail
        },
        {
            path: 'book',
            name: 'book',
            meta: {
                title: '心理图书'
            },
            component: book
        },
        {
            path: 'bookDetail/:cbid',
            name: 'bookDetail',
            meta: {
                title: '心理图书-详情'
            },
            component: bookDetail
        },
        {
            path: 'activity',
            name: 'activity',
            meta: {
                title: '活动锦囊'
            },
            component: activity
        },
        {
            path: 'videoDetail/:vid',
            name: 'videoDetail',
            meta: {
                title: '心理微视频-详情'
            },
            component: videoDetail
        },
        {
            path: 'coursePlay/:courseid',
            name: 'coursePlay',
            meta: {
                title: '课程播放-详情'
            },
            component: coursePlay
        },
        {
            path: 'studentManage',
            name: 'studentManage',
            meta: {
                title: '学生管理'
            },
            component: studentManage
        },
        {
            path: 'importKey',
            name: 'importKey',
            meta: {
                title: '导入密钥'
            },
            component: importKey
        },
        {
            path: 'userManage',
            name: 'userManage',
            meta: {
                title: '用户管理'
            },
            component: userManage
        },
        {
            path: 'messageManage',
            name: 'messageManage',
            meta: {
                title: '通知管理'
            },
            component: messageManage
        },
        {
            path: 'myInfo',
            name: 'myInfo',
            meta: {
                title: '修改资料'
            },
            component: myInfo
        },
        {
            path: 'creditSet',
            name: 'creditSet',
            meta: {
                title: '学分设置'
            },
            component: creditSet
        },
        {
            path: '/findPassword',
            name: 'findPassword',
            meta: {
                title: '找回密码'
            },
            component: findPassword
        },
        ],
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: login
    },

    {
        path: '/404',
        name: err404,
        component: err404,
    },
    {
        path: '*',
        redirect: '/404',
    }
];

const routesM = [ //移动端
    // 移动端
    {
        path: '/',
        component: androidLayout,
        redirect: '/index',
        children: [{
            path: 'index',
            name: 'homeAndroid_Home',
            component: () =>
                import('@/mviews/mhome/index.vue')
        },
        {
            path: 'course',
            name: 'course',
            meta: {
                keepAlive: true,
            },
            component: () =>
                import('@/mviews/course/index.vue'),
        },
        {
            path: 'courseware',
            name: 'courseware',
            meta: {
                keepAlive: true,
            },
            component: () =>
                import('@/mviews/courseware/index.vue'),
        },
        {
            path: 'activity',
            name: 'activity',
            meta: {
                keepAlive: true,
            },
            component: () =>
                import('@/mviews/activity/index.vue')
        },
        {
            path: 'video',
            name: 'video',
            meta: {
                title: '心理微课'
            },
            component: () =>
                import('@/mviews/video/index.vue')
        },
        {
            path: 'videoDetail/:vid',
            name: 'videoDetail',
            meta: {
                title: '心理微视频-详情'
            },
            component: () => import('@/mviews/video/detail/index.vue')
        },
        {
            path: 'book',
            name: 'book',
            meta: {
                title: '心理图书'
            },
            component: () =>
                import('@/mviews/psybook/index.vue')
        },
        {
            path: 'bookDetail/:cbid',
            name: 'bookDetail',
            meta: {
                title: '心理图书-详情'
            },
            component: () => import('@/mviews/psybook/detail/index.vue')
        },

        {
            path: 'article',
            name: 'article',
            meta: {
                title: '心理文章'
            },
            component: () =>
                import('@/mviews/psyarticle/index.vue')
        },
        {
            path: 'articleDetail/:artid',
            name: 'articleDetail',
            meta: {
                title: '心理文章 - 详情'
            },
            component: () =>
                import('@/mviews/psyarticle/detail/index.vue')
        },

        {
            path: 'courseDetail/:courseid',
            name: 'courseDetail',
            meta: {
                title: '精品师范课 - 详情'
            },
            component: () =>
                import('@/mviews/course/detail/index.vue')
        },
        {
            path: 'coursePlay/:courseid',
            name: 'coursePlay',
            meta: {
                title: '课程播放-详情'
            },
            component: () => import('@/mviews/coursePlay/index.vue')
        },
        {
            path: 'materialDetail/:resid',
            name: 'materialDetail',
            meta: {
                title: '课件详情',
                // keepAlive: true
            },
            component: () =>
                import('@/mviews/kejianDetail/index.vue')
        },
        {
            path: 'onlinePreview',
            name: 'onlinePreview',
            meta: {
                title: '在线预览'
            },
            component: () =>
                import('@/mviews/onlinePreview/index.vue')
        },
        {
            path: 'findPassword',
            name: 'findPassword',
            meta: {
                title: '找回密码'
            },
            component: () => import('@/mviews/user/findPassword/index.vue')
        },
        {
            path: 'creditSet',
            name: 'creditSet',
            redirect: '/index',
            meta: {
                title: '学分设置'
            },
            component: () => import('@/mviews/user/creditSet/index.vue')
        },
        {
            path: 'studentManage',
            name: 'studentManage',
            redirect: '/index',
            meta: {
                title: '学生管理'
            },
            component: () => import('@/mviews/user/studentManage/index.vue')
        },
        {
            path: 'importKey',
            name: 'importKey',
            redirect: '/index',
            meta: {
                title: '导入密钥'
            },
            component: () => import('@/mviews/user/importKey/index.vue')
        },
        {
            path: 'userManage',
            name: 'userManage',
            redirect: '/index',
            meta: {
                title: '用户管理'
            },
            component: () => import('@/mviews/user/userManage/index.vue')
        },
        {
            path: 'messageManage',
            name: 'messageManage',
            redirect: '/index',
            meta: {
                title: '通知管理'
            },
            component: () => import('@/mviews/user/messageManage/index.vue')
        },
        {
            path: 'myInfo',
            name: 'myInfo',
            redirect: '/index',
            meta: {
                title: '修改资料'
            },
            component: () => import('@/mviews/user/myInfo/index.vue')
        },

        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import('@/mviews/login/index.vue')
    },

]

var routes = []

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    console.log('移动端')
    routes = routesM
} else {
    console.log('PC端')
    routes = routesPC
}

const router = new VueRouter({
    mode: 'history',
    // base: baseUrl,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        return { x: 0, y: 0 }
    }
});



export default router;