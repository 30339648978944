<template>
    <div class='backgrounAll'>
        <div class=" animate__animated animate__bounce animate__fadeInUp">
            <div class="centre-content">
                <!-- 导航部分 -->
                <div class="courseware-nav">
                    <div class="nav-list">
                        <div :class="tabActive == 0 ? 'nav-item-active' : 'nav-item'" @click="tabChange(0)"> 全部 </div>
                        <div v-for="item in navList" :class="tabActive == item.cateId ? 'nav-item-active' : 'nav-item'"
                            @click="tabChange(item.cateId)"> {{ item.cateName }} </div>
                    </div>
                </div>
                <div v-if="kejianList.length != 0">
                    <!-- 列表 -->
                    <div class="courseware-list">
                        <div class="courseware-item" v-for="kejianItem in kejianList" @click="toDetail(kejianItem.resId)">
                            <div class="courseware-cover parentLocation">
                                <div class="cover-img">
                                    <!-- 1:png格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
                                    <img v-show="kejianItem.coverImg == 1" src="../assets/home/img-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 2" src="../assets/home/ppt-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 3" src="../assets/home/pdf-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 4" src="../assets/home/xls-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 5" src="../assets/home/doc-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 6" src="../assets/home/zip-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 7" src="../assets/home/else-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 8" src="../assets/home/txt-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 9" src="../assets/home/video-cover.png" alt="">
                                    <img v-show="kejianItem.coverImg == 11" src="../assets/home/audio-cover.png" alt="">
                                </div>
                                <div class="sonLocation">
                                    <p class="cover-title ">{{ kejianItem.resName }}</p>
                                    <p class="cover-line"></p>
                                </div>
                                <div class="cover-info flex-box">
                                    <div class="flex-box">
                                        <p class="learnNum-img"><img src="../assets/home/learn-num.png" alt=""></p>
                                        <span>{{ kejianItem.viewNum }}</span>
                                    </div>
                                    <div class="flex-box marLeft-16">
                                        <p class="shareNum-img"><img src="../assets/home/download2.png" alt=""></p>
                                        <span>{{ kejianItem.shareNum }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="courseware-info">
                                <p class="courseware-title">{{ kejianItem.resName }}</p>
                                <p class="courseware-layout">格式：{{ kejianItem.format }}</p>
                                <p class="courseware-time">上传时间：2022-06-13 15:00</p>
                            </div>
                        </div>
                    </div>
                    <!-- 分页 -->
                    <div class="page-box">
                        <p class="homePage">首页</p>
                        <el-pagination :page-size="12" background layout="prev, pager, next" :total="total" pager-count:7
                            @current-change="currentChange" :current-page="currentPage"> </el-pagination>
                        <p class="endPage">尾页</p>
                    </div>
                </div>
                <!-- 暂无内容 -->
                <div class="notBox" v-else>
                    <p class="no-content"><img src="../assets/home/no-content.png" alt=""></p>
                    <p class="no-conent-text">暂无资源，平台正在努力添加资源~</p>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import request from '@/utils/request';
import { localStorageGet } from '@/utils/localStorage';
import { localStorageSet } from '@/utils/localStorage';
export default {
    data() {
        return {
            navList: [],
            tabActive: 0,
            cateId: 0,
            kejianList: [],
            currentPage: 1, // 当前页码
            total: 0,
            type: 2, // 1 课件 2 素材
            searchType: '',
            keyWord: '',
        };
    },
    mounted() {
        this.searchType = this.$route.query.searchType;
        this.keyWord = this.$route.query.keyWord;
        this.getKejianCate();
        this.getKejianList();
    },
    methods: {
        currentChange(val) {
            this.currentPage = val
            window.scrollTo(0, 0)
            this.getKejianList()
        },
        tabChange(cateId) {
            this.currentPage = 1;
            this.cateId = cateId;
            this.tabActive = cateId;
            this.getKejianList();
        },
        getKejianCate() { //素材分类
            let data = {};
            request({
                url: '/kejian/cate',
                method: 'post',
                data,
            }).then((res) => {
                this.navList = res.data
            })
        },
        getKejianList() { //素材列表
            let data = { type: 2, cateId: this.cateId, pageNum: this.currentPage, perPage: 12 }
            request({
                url: '/kejian/lists',
                method: 'post',
                data,
            }).then((res) => {
                this.kejianList = res.data.list;
            })
            if (this.searchType != undefined && this.keyWord != undefined) {
                this.searchList()
            } else {
                return false;
            }
        },
        searchList() {
            let data = { searchType: this.searchType, keyWord: this.keyWord, perPage: 10, pageNum: this.currentPage }
            request({
                url: '/search',
                method: 'post',
                data,
            }).then((res) => {
                this.kejianList = res.data.list;
            })
        },
        toDetail(resId) {
            // this.$router.push(`/materialDetail?resId=${resId}&type=${this.type}`)
            this.$router.push({ path: `/materialDetail/${resId}?type=${this.type}` })

        },
    },
    //注册子组件
    components: {},
    //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
    computed: {},
    //过滤器
    filters: {
        filtime(val) { },
    },
};
</script>

<style lang='less' scoped>
.courseware-nav {
    padding: 40px 0px;

    .nav-list::-webkit-scrollbar {
        display: none;
    }

    .nav-list {
        display: flex;
        justify-content: center;
        height: 40px;
        overflow-x: scroll;

        .nav-item {
            margin-right: 100px;
            font-size: 16px;
            color: #606266;
            cursor: pointer;
            white-space: nowrap;
        }

        .nav-item-active {
            white-space: nowrap;
            margin-right: 100px;
            font-size: 16px;
            color: #4676F8;
            height: 26px;
            border-bottom: 4px solid #4676F8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.courseware-list {
    display: flex;
    flex-wrap: wrap;

    .courseware-item:hover {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
    }

    .courseware-item {
        width: 220px;
        margin-right: 85px;
        padding-bottom: 40px;
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;

        .courseware-cover {
            margin-bottom: 16px;

            .cover-img>img {
                width: 220px;
                height: 164px;
                // background: linear-gradient(301deg, #AC7BFE 0%, #6447FF 100%);
                border-radius: 8px;
                backdrop-filter: blur(36px);
                display: block;
            }

            position: relative;

            .sonLocation {
                top: 40px;
                left: 16px;
            }

            .cover-title {
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                width: 180px;
                overflow: hidden;
                text-overflow: ellipsis; //文本溢出显示省略号
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .cover-line {
                margin-top: 16px;
                width: 20px;
                height: 2px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                border-radius: 1px;
            }
        }

        .cover-info {
            width: 188px;
            background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 0.25) 100%);
            border-radius: 0px 0px 8px 8px;
            position: absolute;
            bottom: 0px;
            display: flex;
            padding: 17px 16px 7px;
            font-size: 12px;
            color: #FFFFFF;
        }

        .courseware-info {
            .courseware-title {
                font-size: 16px;
                font-weight: bold;
                color: #3B3D41;
                padding-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .courseware-layout {
                font-size: 14px;
                color: #606266;
                padding-bottom: 10px;
            }

            .courseware-time {
                font-size: 14px;
                color: #606266;
            }
        }
    }

    .courseware-item:nth-child(5n) {
        margin-right: 0px;
    }
}
</style>
