<template>
  <div class="loginBj">
    <div class="loginPadding animate__animated animate__bounce animate__fadeInUp">
      <div class="login-box">
        <div class="login-left">
          <img src="../assets/login/login-left-img.png" alt="" />
        </div>
        <div class="login-right">
          <div class="logoImg">
            <img src="../assets/login/logo.png" alt="" />
          </div>
          <p class="logo-title">心理健康教学资源库系统</p>
          <div class="login-from">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="" prop="username">
                <el-input placeholder="请输入用户名" v-model="ruleForm.username">
                  <img class="passIcon" slot="prefix" src="../assets/login/password1.png" alt="" />
                  <!-- <i slot="prefix" class="el-input__icon el-icon-user"></i> -->
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input placeholder="请输入密码" v-model="ruleForm.password" show-password>
                  <!-- <p class="passIcon"> -->
                  <img class="passIcon" slot="prefix" src="../assets/login/yonghu1.png" alt="" />
                  <!-- </p> -->
                  <!-- <i slot="prefix" class="el-input__icon el-icon-s-home"></i> -->
                </el-input>
              </el-form-item>
              <div class="vercode-box">
                <input class="vercodeIpt" type="text" v-model="vercodeipt" placeholder="请输入验证码" />
                <p class="vercode" @click="changeVerifyUrl()">
                  <img :src="verifyCodeUrl" alt="" />
                </p>
              </div>
              <el-form-item>
                <el-button type="primary" @click="logIn()"
                  :disabled="!ruleForm.username || !ruleForm.password">登录</el-button>
              </el-form-item>
              <p class="findPass" @click="toFindPass()">找回密码</p>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      vercodeipt: "", //验证码
      accountId: "", //用户id
      token: "",

      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      isDisabled: true, // 登录btn
      verifyCodeUrl: "https://reslibapi-test.x-lingtong.com/captcha",
      configToken: "",
    };
  },

  //接口 一进入页面就执行的
  mounted() {
    let configToken = localStorageGet("configToken");
    this.configToken = configToken ? configToken : "";
    this.changeVerifyUrl();
  },
  //事件方法
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    logIn() {
      this.isDisabled = true;
      let params = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        vercode: this.vercodeipt,
        token: this.configToken,
      };
      request({
        url: "/auth",
        method: "post",
        data: params,
      })
        .then((res) => {
          localStorageSet("userInfo", res.data);
          this.accountId = res.data.accountId;
          this.token = res.data.token;
          if (this.$route.query.redirect) {
            let redirect = this.$route.query.redirect;
            redirect = decodeURIComponent(redirect);
            this.$router.push(redirect);
            return;
          }
          this.$router.push("/index");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeVerifyUrl() { //点击图片刷新验证码
      // this.verifyCodeUrl = 'https://reslibapi-test.x-lingtong.com/captcha?token=' + this.configToken;
      this.verifyCodeUrl = `https://reslibapi.x-lingtong.com/captcha?token=${this.configToken
        }&s=${new Date().getTime()}`;
      // this.verifyCodeUrl = 'https://reslibapi-test.x-lingtong.com/captcha';
    },
    toFindPass() {
      this.$router.push("/findPassword");
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.loginBj {
  // width: 1920px;
  // height: 1080px;
  // height: 100vh;
  // overflow: hidden;
  // height: 100%;
  background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/login-bj.png");
  background-size: 100% auto;
  background-size: cover;
  background-position: center;
  position: relative;

  // height: 100vh;
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;

  // &::before {
  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 400%;
  //   background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/login-bj.png");
  //   background-size: 100% auto;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}

// .loginPadding {
//   // padding: 115px 0px;
//   // padding: 80px 0px;
// }

.login-box {
  display: flex;
  margin: 0 auto;
  width: 1631px;
  height: 790px;
  // width: 90%;
  background: #fdfdfd;
  box-shadow: 8px 14px 32px 0px rgba(47, 59, 87, 0.2);
  border-radius: 16px;

  .login-left>img {
    width: 650px;
    height: 790px;
  }

  .login-right {
    padding: 150px 220px;

    .logoImg {
      width: 198px;
      height: 80px;
      margin: 0 auto;
    }

    .logoImg>img {
      width: 198px;
      height: 80px;
    }

    .logo-title {
      margin-top: 24px;
      margin-bottom: 60px;
      font-size: 48px;
      font-weight: bold;
      color: #4676f8;
      text-shadow: 8px 14px 32px rgba(47, 59, 87, 0.2);
    }

    .login-from {
      width: 524px;

      .ipt-box {
        width: 524px;
        height: 66px;
      }

      /deep/.el-input--prefix>input {
        height: 50px;
        line-height: 50px;
      }

      .vercodeIpt {
        border: none;
      }

      // input:focus {
      //   border: 1px solid red;
      // }

      input {
        outline: none;
      }

      .vercode-box {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        height: 35px;
        // box-shadow: 8px 14px 32px 0px rgba(47, 59, 87, 0.2);
        border: 1px solid #b7bcc1;
        border-radius: 8px;
        margin-bottom: 40px;

        .vercode>img {
          width: 114px;
          height: 38px;
          box-shadow: 8px 14px 32px 0px rgba(47, 59, 87, 0.2);
        }
      }

      .login {
        width: 524px;
        height: 66px;
        line-height: 66px;
        text-align: center;
        background: linear-gradient(135deg,
            rgba(0, 140, 255, 0.55) 0%,
            #4676f8 100%);
        box-shadow: 8px 14px 32px 0px rgba(47, 59, 87, 0.2),
          1px 2px 4px 0px rgba(0, 56, 187, 0.13);
        border-radius: 8px;
        font-size: 20px;
        color: #ffffff;
        margin-top: 40px;
      }
    }
  }
}

.findPass {
  font-size: 18px;
  color: #3c6dfb;
  line-height: 24px;
  text-shadow: 8px 14px 32px rgba(47, 59, 87, 0.2);
  float: right;
}

/deep/.el-input__inner {
  // width: 524px !important;
  width: 100% !important;
}

/deep/.el-button {
  // width: 100% !important;
  width: 524px;
  height: 66px;
  background: linear-gradient(135deg, rgba(0, 140, 255, 0.55) 0%, #4676f8 100%);
  box-shadow: 8px 14px 32px 0px rgba(47, 59, 87, 0.2),
    1px 2px 4px 0px rgba(0, 56, 187, 0.13);
  border-radius: 8px;
  font-size: 20px;
  color: #ffffff;
  line-height: 26px;
  text-shadow: 8px 14px 32px rgba(47, 59, 87, 0.2);
}

/deep/.el-form-item__content {
  margin-left: 0px !important;
}

/deep/.el-form-item__label {
  width: 67px !important;
}

.passIcon {
  width: 16px;
  height: 16px;
  margin-top: 16px;
  margin-left: 6px;
}
</style>
