<template>
    <el-dialog title="提示" :visible="visible" width="30%" center>
        <p class="qrCode"><img src="" alt="" /></p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="visible = false">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import request2 from "@/utils/request2.js";
export default ({
    model: {
        prop: "visible",
        event: "change",
    },
    props: {
        // 显隐
        visible: Boolean,
    },
    data() {

        return {

        };
    },
    methods() {

    },

})
</script>
<style lang='less' scoped></style>