<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="creditSet-box centre-content">
        <!-- <div class="creditSet-title flex-box"> -->
        <!-- <p :class="tabActive == item.id
            ? 'active hoverFinger'
            : 'score-title hoverFinger'
            " v-for="item in tabNav" @click="changeTab(item.id)" :key="item.id">
            {{ item.title }}
          </p> -->
        <!-- <p class="credit-title active">学分设置</p> -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.title" :name="item.id" v-for="(item, index) in tabNav" :key="index"></el-tab-pane>
        </el-tabs>
        <!-- </div> -->

        <div v-show="activeName == 2">
        <creditStatistics></creditStatistics>
        </div>

        <!-- 学分设置 -->
        <div v-show="activeName == 1" class="credit-box">
          <!-- 课程学分 -->
          <div class="class-credit">
            <h4>课程学分</h4>
            <div class="flex-box class-box" v-for="(item, index) in courseList" :key="item.id">
              <p class="class-title">课程{{ index + 1 }}:</p>
              <p class="class-title">
                "{{ item.courseTitle }}" 完成所有课时，获得
                <input :disabled="configInfo.accountInfo.level == 2 ? true : false" class="creditset-ipt" type="text"
                  oninput="value=value.replace(/[^0-9.]/g,'')" v-model="item.score"
                  @change="courseChangeIpt(item, $event)" />
                学分。
              </p>
            </div>
          </div>
          <!-- 微视频学分 -->
          <div class="video-credit">
            <h4>微视频学分</h4>
            <div class="flex-box class-box">
              <p class="class-title">
                微视频：查看<input :disabled="configInfo.accountInfo.level == 2 ? true : false" class="creditset-ipt"
                  type="number" v-model.lazy="video.complateNum" oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="videoChangeIpt()" />
                个微视频，获得
                <input :disabled="configInfo.accountInfo.level == 2 ? true : false" class="creditset-ipt" type="text"
                  v-model.lazy="video.score" @change="videoChangeIpt()" oninput="value=value.replace(/[^0-9.]/g,'')" />学分；
                如果未完成设定的微视频学习任务，按平均分数（分值*已修数量/设定数量）给予学生学分。
              </p>
            </div>
          </div>

          <!-- 心理文章学分 -->
          <div class="article-credit">
            <h4>心理文章学分</h4>
            <div class="flex-box class-box">
              <p class="class-title">
                心理文章：查看<input :disabled="configInfo.accountInfo.level == 2 ? true : false" class="creditset-ipt"
                  type="number" v-model.lazy="art.complateNum" @change="artChangeIpt($event)"
                  oninput="value=value.replace(/[^0-9.]/g,'')" />
                个心理文章， 按系统默认规则查看心理文章，获得
                <input :disabled="configInfo.accountInfo.level == 2 ? true : false" class="creditset-ipt" type="text"
                  v-model.lazy="art.score" @change="artChangeIpt($event)"
                  oninput="value=value.replace(/[^0-9.]/g,'')" />学分。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { localStorageGet,localStorageSet } from "@/utils/localStorage";
import creditStatistics from './components/creditStatistics.vue'
export default {
  data () {
    return {
      activeName: '1',
      tabNav: [
        // {
        //   id: 1,
        //   title: "成绩统计",
        // },
        {
          id: '1',
          title: "学分设置",
        },
        {
          id: '2',
          title: "学分统计",
        },

      ],
      tabActive: 1,
      type: 1,
      configInfo: { accountInfo: {} },
      accountInfo: {},

      courseList: [], // 课程列表
      cateId: 0,
      currentPage: 1, //当前页码
      perPage: 12, // 请求条数

      courseId: "",
      courseScore: "", // 课程学分
      // videoScore: '', // 微视频学分
      // complateVideoNum: 0,//微视频数量

      art: {
        //心理文章
        complateNum: "",
        score: "",
      },
      video: {
        //微视频
        complateNum: "",
        score: "",
      },
      course: {},
      // videoNum: '', //微视频数量
      // videoScore: '', //微视频学分
    };
  },
  //接口 一进入页面就执行的
  mounted () {
    this.type = this.$route.query.type;
    this.tabActive = this.$route.query.type;
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.configInfo = configInfo;
    this.accountInfo = configInfo.accountInfo;
    this.getCourseList();
    this.getScoresetSets();
  },
  //事件方法
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    },
    changeTab (type) {
      this.type = type;
      this.tabActive = type;
    },
    // 请求课程列表
    getCourseList () {
      let data = {
        cateId: this.cateId,
        pageNum: this.currentPage,
        perPage: this.perPage,
      };
      request({
        url: "/course/lists",
        method: "post",
        data,
      })
        .then((res) => {
          this.courseList = res.data.lists;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setCourseListScore();
        });
    },
    courseChangeIpt (item, e) {
      this.courseId = item.courseId;
      this.courseScore = e.target.value;

      this.$confirm("确定修改学分吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.getCourseScoresetSet();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.getScoresetSets();
        });
    },
    getCourseScoresetSet () {
      //课程学分
      let data = {
        setType: "course",
        courseId: this.courseId,
        score: this.courseScore,
      };
      request({
        url: "/scoreset/set",
        method: "post",
        data,
      }).then((res) => {
        this.getScoresetSets();
        this.$message({
          type: "success",
          message: "修改成功!",
        });
      });
    },

    videoChangeIpt () {
      // 提交修改视频分数及数量
      if (this.video.complateNum != "" && this.video.score != "") {
        this.$confirm("确定修改学分吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.getVideoScoresetSet();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            this.getScoresetSets();
          });
      }
    },

    getVideoScoresetSet () {
      //微视频学分
      let data = {
        setType: "video",
        complateNum: this.video.complateNum,
        score: this.video.score,
      };
      request({
        url: "/scoreset/set",
        method: "post",
        data,
      })
        .then((res) => {
          this.getScoresetSets();

          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    artChangeIpt () {
      if (this.art.complateNum != "" && this.art.score != "") {
        this.$confirm("确定修改学分吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // type: 'warning'
        })
          .then(() => {
            this.getArtScoresetSet();
          })
          .catch(() => {
            this.getScoresetSets();
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    getArtScoresetSet () {
      //心理文章学分
      let data = {
        setType: "art",
        complateNum: this.art.complateNum,
        score: this.art.score,
      };
      request({
        url: "/scoreset/set",
        method: "post",
        data,
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getScoresetSets();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 学分回填
    getScoresetSets () {
      request({
        url: "/scoreset/sets",
        method: "post",
        data: {},
      })
        .then((res) => {
          if (res.data.art) {
            this.art = res.data.art;
          }
          if (res.data.video) {
            this.video = res.data.video;
          }
          this.course = res.data.course;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setCourseListScore();
        });
    },
    setCourseListScore () {
      if (JSON.stringify(this.course) == "{}" || this.courseList.length == 0) {
        return;
      }
      this.courseList.forEach((item) => {
        this.$set(item, "score", this.course[item.courseId]);
      });
    },
  },
  //注册子组件
  components: {creditStatistics},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime (val) { },
  },
};
</script>

<style lang='less' scoped>
.creditSet-box {
  padding: 40px 0px 60px;

  .creditSet-title {
    height: 45px;
    border-bottom: 1px solid #dcdfe6;

    .active {
      margin-right: 128px;
      height: 45px;
      line-height: 45px;
      width: 64px;
      font-size: 16px;
      font-weight: bold;
      color: #4676f8;
      border-bottom: 2px solid #4676f8;
    }

    .score-title {
      width: 64px;
      width: 64px;
      font-size: 16px;
      font-weight: bold;
      color: #606266;
      margin-right: 128px;
    }

    .credit-title {
      width: 64px;
      width: 64px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  // 学分设置
  .credit-box {
    h4 {
      font-size: 18px;
      font-weight: bold;
      color: #303133;
      margin-bottom: 24px;
    }

    .class-credit {
      margin-top: 20px;
      padding: 24px;
      background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/xufen-bj1.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 20px;
    }

    .class-box {
      margin-bottom: 24px;

      .class-title {
        font-size: 16px;
        color: #303133;
        line-height: 22px;
      }

      .colorBlock {
        width: 60px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        color: white;
        background: linear-gradient(90deg, #5494fc 0%, #3074f9 100%);
        box-shadow: 0px 1px 4px 0px rgba(58, 95, 205, 0.16);
        border-radius: 4px;
      }

      .creditset-ipt {
        width: 50px;
        border: 1px solid #c0c4cc;
        padding: 8px;
        outline-color: #c0c4cc;
        margin: 0 6px;
      }
    }

    .video-credit {
      padding: 24px;
      background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/xufen-bj2.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 20px;
    }

    .article-credit {
      padding: 24px;
      background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/xufen-bj3.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
</style>
