<template>
  <div class="box">
    <!-- 导航部分 -->
    <div class="header" :class="{ headerAnimation: headerAnimation }">
      <div class="nav-box">
        <div class="logo">
          <!-- searchType：{{ searchType }}---value:{{value}} -->
          <img :src="homeData.logo ? homeData.logo : logo" alt="" />
          <!-- <img :src="homeData.logo" alt="" /> -->
        </div>
        <!-- <div class="nav-item">
          <router-link to="index">首页</router-link>
          <router-link to="/course">精品示范课</router-link>
          <router-link to="/courseware">课件</router-link>
          <router-link to="/activity">活动精囊</router-link>
          <router-link to="/video">心理微课</router-link>
          <router-link to="/book">心理图书</router-link>
          <router-link to="/article">心理文章</router-link>
          <router-link to="/material">素材中心</router-link>
        </div> -->
        <div class="nav">
          <ul>
            <router-link to="/index" style="margin-left: 130px">
              <a>首页</a>
            </router-link>
            <router-link :to="'/' + item.url" v-for="item in cateList" :key="item.cateId" :active="true">
              <a>
                {{ item.cateName }}
              </a>
            </router-link>
          </ul>
        </div>

        <div class="search">
          <!-- :popper-append-to-body="false" -->
          <el-select :popper-append-to-body="false" v-model="value" placeholder="请选择" @change="selectTrigger(value)">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <input type="text" placeholder="请输入搜索的内容" @input="iptValue" v-model="searchName" />
          <span class="search-btn hoverFinger" @click="getSearch">搜索</span>
          <a class="search-btn-last" v-if="searchName.length > 0" @click="resetting">x</a>

          <!-- <el-input placeholder="请输入内容" @change="iptValue" v-model.lazy="searchName" class="input-with-select">
            <el-select style="min-width: 120px;" :popper-append-to-body="false" v-model="value" slot="prepend" placeholder="请选择" @change="selectTrigger(value)">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
            <el-button slot="append" icon="el-icon-search" @click="getSearch"></el-button>
          </el-input> -->
        </div>
        <!-- <div> configInfo {{ configInfo == null }}</div> -->
        <!-- <div>accountName:{{ accountName == '' }}</div> -->

        <div class="login hoverFinger" @click="loginIn()" v-if="accountName == ''">
          登录
        </div>

        <div class="user flex-box" v-else>
          <p class="user-icon" v-show="!avatar">
            <img src="../../assets/home/user.png" alt="" />
          </p>
          <p class="user-icon" v-show="avatar"><img :src="avatar" alt="" /></p>
          <div class="userName hoverFinger">
            <el-menu :default-active="activeIndex" class="el-menu-demo sshy" mode="horizontal" @select="handleSelect"
              menu-trigger="hover">
              <el-submenu index="2">
                <template slot="title">
                  <div class="flex-box">
                    <p>{{ trueName }}</p>
                    <p class="user-right-icon hoverFinger">
                      <img src="../../assets/home/user-icon.png" alt="" />
                    </p>
                  </div>
                </template>
                <el-menu-item index="2-1" @click="torouterPush('/studentManage')">学生管理</el-menu-item>
                <el-menu-item index="2-2" @click="torouterPush('/creditSet?type=1')"
                  style="display: none">成绩统计</el-menu-item>
                <el-menu-item index="2-3" @click="torouterPush('/creditSet?type=2')">学分设置</el-menu-item>
                <el-menu-item index="2-3" @click="torouterPush('/myInfo')">修改资料</el-menu-item>
                <el-menu-item index="2-3" v-if="configInfo.accountInfo.level == 1"
                  @click="torouterPush('/userManage')">用户管理</el-menu-item>
                <el-menu-item index="2-3" v-if="configInfo.accountInfo.level == 1"
                  @click="torouterPush('/messageManage')">通知管理</el-menu-item>
                <el-menu-item index="2-3" @click="torouterPush('/importKey')">导入密钥</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
          <p class="logOut hoverFinger" @click="outLogin()" v-show="accountName">
            退出
          </p>
        </div>
      </div>
    </div>
    <!-- 中心内容出口 -->
    <div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
    <!-- foot部分 -->
    <div class="foot">
      <div class="foot-content">
        <div class="foot-left">
          <div class="school">{{ homeData.companyName }}</div>
          <div class="address">
            <!-- <div>公司地址：<span class="address-text">{{centerConfig.centerAddr}}</span></div> -->
            <div>
              公司地址：<span class="address-text"><span>{{ homeData.addr }}</span>
              </span>
            </div>
          </div>
          <div class="phone">
            电话：<span>{{ homeData.tel }}</span>
          </div>
          <div class="email">
            邮箱：<span>{{ homeData.email }}</span>
          </div>
        </div>
        <div class="foot-center">
          <div class="foot-center-title" style="display: none">友情链接</div>
          <div style="display: flex">
            <div class="foot-centerInfo">
              <div class="foot-center-list" style="padding-right: 10px" v-for="firendLinksList in firendLinks"
                :key="firendLinksList.linkId" @click="friendLink(firendLinksList.linkUrl)">
                {{ firendLinksList.linkDesc }}
              </div>
            </div>
          </div>
        </div>
        <div class="foot-right">
          <div class="circle-box">
            <div class="circle">
              <img v-bind:src="homeData.wxMpQrcode" />
            </div>
            <div class="circle-text">心灵通小程序</div>
          </div>
          <div class="square-box">
            <div class="square">
              <img v-bind:src="homeData.xltGzhQrcode" />
            </div>
            <div class="square-text">心灵通公众号</div>
          </div>
        </div>
      </div>
      <div>
        <div class="foot-publicist">
          Copyright 2021- {{ year }} {{ homeData.companyName }} . All rights
          reserved
        </div>
        <div class="foot-publicist">
          <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2021006657号</a>
        </div>
        <div class="foot-publicist" style="display: none">
          <a href="http://www.beian.gov.cn" target="_blank">京公网安备 11010802035955</a>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" center>
      <span>确定退出当前用户吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="outLogin = false">确 定</el-button>
      </span>
    </el-dialog>

    <div v-if="loginDialog" class="login-box animate__animated animate__bounce animate__fadeInUp">
      <div class="box-item">
        <p class="img">
          <img src="@/assets/login/logo.png" alt="">
        </p>
        <p class="title">心理健康教学资源库系统</p>
        <div class="form-item">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="" prop="username">
              <el-input placeholder="请输入用户名" prefix-icon="el-icon-user" v-model="ruleForm.username">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="ruleForm.password" show-password>
              </el-input>
            </el-form-item>
            <div class="vercode-box">
              <el-input type="text" v-model="vercodeipt" placeholder="请输入验证码" />
              <p class="vercode" @click="changeVerifyUrl()">
                <img :src="verifyCodeUrl" alt="" />
              </p>
            </div>
            <a class="findPass" @click="toFindPass()">找回密码</a>
            <el-form-item>
              <el-button type="primary" @click="logIn()"
                :disabled="!ruleForm.username || !ruleForm.password">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import * as _ from "lodash";
import { localStorageGet, localStorageSet } from "@/utils/localStorage";
import { baseUrl } from '@/settings.js'
export default {
  data () {
    return {
      loginDialog: false,//登录弹窗
      ruleForm: {
        username: "",
        password: "",
      },
      vercodeipt: "", //验证码
      accountId: "", //用户id
      token: "",

      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      isDisabled: true, // 登录btn
      verifyCodeUrl: "https://reslibapi-test.x-lingtong.com/captcha",
      configToken: "",
      options: [
        {
          value: "course",
          label: "精品示范课",
          url: "course",
        },
        {
          value: "kejian",
          label: "课件",
          url: "courseware",
        },
        {
          value: "jinnang",
          label: "活动锦囊",
          url: "activity",
        },
        {
          value: "weike",
          label: "心理微课",
          url: "video",
        },
        {
          value: "book",
          label: "心理图书",
          url: "book",
        },
        {
          value: "article",
          label: "心理文章",
          url: "article",
        },
        // {
        //   value: "sucai",
        //   label: "素材中心",
        //   url: "material",
        // },
      ],
      value: "course",
      cateList: [
        {
          cateId: 1,
          cateName: "精品示范课",
          url: "course",
        },
        {
          cateId: 2,
          cateName: "课件",
          url: "courseware",
        },
        {
          cateId: 3,
          cateName: "活动锦囊",
          url: "activity",
        },
        {
          cateId: 4,
          cateName: "心理微课",
          url: "video",
        },
        {
          cateId: 5,
          cateName: "心理图书",
          url: "book",
        },
        {
          cateId: 6,
          cateName: "心理文章",
          url: "article",
        },
        // {
        //   cateId: 7,
        //   cateName: "素材中心",
        //   url: "material",
        // },
      ],

      year: 0,
      activeIndex: this.$route.name,
      centerConfig: {},
      firendLinks: [],
      productHardActive: "0",

      pageNum: 1, // 当前页码
      currentPage: 1, // 当前页码
      total: 0, //列表总数量
      searchName: "", // 搜索内容
      searchType: "course", //搜索类型--默认
      keyWord: "", // 搜索内容

      headerAnimation: false,
      configInfo: {},
      accountName: "", //用户姓名
      trueName: "", //用户姓名
      avatar: "", // 头像
      dialogVisible: false,

      activeIndex: "1",
      activeIndex2: "1",
      logo: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/logo.png', //本地logo
    };
  },
  watch: {
    $route (val) {
      // this.activeIndex = this.$route.name
      this.$set(this, "activeIndex", this.$route.name);
      if (this.$route.name == 'index') {
        this.value = '';
        this.searchName = '';
        // this.clearable = true;
        console.log('监听');
        this.year = new Date().getFullYear();
    // this.$refs.selectRef.toggleMenu()
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    if (configInfo == null || configInfo == "" || configInfo == undefined) {
      this.accountName = ''
      return false;
    } else {
      this.configInfo = configInfo;
      this.accountName = configInfo.accountInfo.accountName;
      this.trueName = configInfo.accountInfo.trueName;
      this.avatar = configInfo.accountInfo.avatar;
    }

    // this.activeIndex = this.$route.name

    this.onHeaderAnimation();
      }
    },

  },
  mounted () {
    console.log('首页mounted');
    this.year = new Date().getFullYear();
    // this.$refs.selectRef.toggleMenu()
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    if (configInfo == null || configInfo == "" || configInfo == undefined) {
      return false;
    } else {
      this.configInfo = configInfo;
      this.accountName = configInfo.accountInfo.accountName;
      this.trueName = configInfo.accountInfo.trueName;
      this.avatar = configInfo.accountInfo.avatar;
    }

    // this.activeIndex = this.$route.name

    this.onHeaderAnimation();

    // this.$router.beforeEach((to, from, next) => {
    //   if (to.query.search != true) {
    //     this.$store.commit("setSearchInfo", {
    //       searchType: undefined,
    //       keyWord: undefined,
    //     });
    //   }
    //   next();
    // });
  },
  methods: {
    resetting () {
      this.$router.go(0)
    },
    handleSelect (key, keyPath) {
      // console.log(key, keyPath);
    },
    torouterPush (path) {
      this.$router.push(path);
    },
    loginIn () {//登录
      // let pathInfo = this.$router.resolve({
      //   path: "/login",
      // });
      // window.open(pathInfo.href, "_blank");
      let configToken = localStorageGet("configToken");
      this.configToken = configToken ? configToken : "";
      this.loginDialog = true
      this.changeVerifyUrl();
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    logIn () {
      this.isDisabled = true;
      let params = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        vercode: this.vercodeipt,
        token: this.configToken,
      };
      request({
        url: "/auth",
        method: "post",
        data: params,
      }).then((res) => {
        console.log('登陆成功', res);
        localStorageSet("userInfo", res.data);
        this.accountId = res.data.accountId;
        this.token = res.data.token;
        if (this.$route.query.redirect) {
          let redirect = this.$route.query.redirect;
          redirect = decodeURIComponent(redirect);
          this.$router.push(redirect);
          return;
        }
        this.loginDialog = false

        this.year = new Date().getFullYear();
        let configInfo = JSON.parse(localStorageGet("userInfo"));
        if (configInfo == null || configInfo == "" || configInfo == undefined) {
          return false;
        } else {
          this.configInfo = configInfo;
          this.accountName = configInfo.accountInfo.accountName;
          this.trueName = configInfo.accountInfo.trueName;
          this.avatar = configInfo.accountInfo.avatar;
        }
        this.$message({
          message: '登陆成功！',
          type: 'success'
        });
        this.vercodeipt = ''
        this.onHeaderAnimation();
        this.$router.go(0)
        // this.$router.go(0);

      })
        .catch((err) => {
          console.log('登陆失败', err);
          this.changeVerifyUrl()
        });
    },
    changeVerifyUrl () { //点击图片刷新验证码
      this.verifyCodeUrl = `${baseUrl}/captcha?token=${this.configToken
        }&s=${new Date().getTime()}`;
      // this.verifyCodeUrl = 'https://reslibapi-test.x-lingtong.com/captcha?token=' + this.configToken;
      // this.verifyCodeUrl = `https://reslibapi.x-lingtong.com/captcha?token=${this.configToken
      //   }&s=${new Date().getTime()}`;
    },
    toFindPass () {
      this.loginDialog = false
      this.$router.push("/findPassword");

    },

    openDialog () {
      this.dialogVisible = true;
    },
    outLogin () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message('已退出!');
        localStorage.removeItem("userInfo");
        this.accountName = ''
        let queryList = ['course', 'course', 'courseware', 'courseware', 'video', 'book', 'article']
        let str = queryList.indexOf(this.$route.name)
        console.log(str);
        if (str == -1) {
          this.$router.push("/index");
        }
        // location.reload();
      }).catch(() => { });
    },
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    toTarget () {
      let map = {
        kejian: "courseware",
        course: "course",
        jinnang: "activity",
        weike: "video",
        book: "book",
        article: "article",
      };

      if (this.$route.name != map[this.searchType]) {
        this.$router.push({
          name: map[this.searchType],
          // query: {
          //   search: true,
          // },
        });
      }
      this.$store.commit("setSearchInfo", {
        searchType: this.searchType,
        keyWord: this.keyWord,
      });
      return;
      console.log(this.$route.path);

      // if (this.searchType == "kejian") {
      //   this.$router.push(
      //     `/courseware?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "course") {
      //   this.$router.push(
      //     `course?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "book") {
      //   this.$router.push(
      //     `book?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "weike") {
      //   this.$router.push(
      //     `video?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "article") {
      //   this.$router.push(
      //     `article?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "sucai") {
      //   this.$router.push(
      //     `material?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
      // if (this.searchType == "jinnang") {
      //   this.$router.push(
      //     `activity?searchType=${this.searchType}&keyWord=${this.keyWord}`
      //   );
      // }
    },
    iptValue: _.throttle(function (e) {
      const val = e.target?.value;
      console.log('val', val);
      this.keyWord = val;
      this.bookName = val;
      if (this.searchName == "") {
        this.currentPage = 1;
      } else {
        return;
      }
    }, 1000),
    // iptValue(e){
    //   console.log(e.target.value)
    //   this.searchName = e.target.value;
    // },
    selectTrigger (val) {
      this.searchType = val;
    },

    getSearch () {
      this.toTarget();
    },

    friendLink (e) {
      window.open(e, "_blank");
    },

    toProductHard (index) {
      if (this.$route.name != "productHard") {
        this.$router.push({ name: "productHard" });
      } else {
        this.productHardActive = index;
      }
    },
    myMenuisHardActive (index) {
      if (this.$route.name == "productHard") {
        if (this.productHardActive == index) {
          return true;
        } else {
          return false;
        }
      }
    },
    toProductHardSelfHelp () {
      if (this.$route.name != "productHardSelfHelp") {
        this.$router.push("/productHardSelfHelp");
      } else {
        return false;
      }
    },
    toProductHardRobot () {
      if (this.$route.name != "productHardRobot") {
        this.$router.push("/productHardRobot");
      } else {
        return false;
      }
    },
    onHeaderAnimation () {
      let fn = () => {
        let top = document.documentElement.scrollTop;
        if (top == 0) {
          this.headerAnimation = true;
        } else {
          this.headerAnimation = false;
        }
      };
      fn();
      window.addEventListener("scroll", fn);
    },
  },
  computed: {
    //首页数据
    homeData () {
      return this.$store.state.configData;
    },
  },
};
</script>

<style lang="less" scoped>
// @media screen and (min-width: 2560px) {
//   html {
//     font-size: 26px;
//   }

//   a {
//     font-size: 24px;
//   }
// }

// @media screen and (min-width: 1920px) {
//   html {
//     font-size: 26px;
//     color: red;
//   }

//   .home-bj {
//     background-color: plum;
//     border: 1px solid red;
//   }
// }

.box {
  // width: 1920px;
  margin: 0 auto;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}


// 头部样式
.header {
  height: 64px;
  background: rgba(48, 49, 51, 0.35);
  background: white;
  padding: 0 40px;
  z-index: 99;
  position: sticky;
  top: 0;
  transition: all 0.2s;
}

// .headerAnimation {
//   background: transparent;
// }

.logo>img {
  // height: 40px;
  // max-width: 140px;
  max-width: 368px;
}

.nav-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-item {
  white-space: nowrap;
  margin-left: 24px;
}

.nav>ul {
  display: flex;
  height: 61px;
  border-bottom: 0px solid #e5eaf1;
  margin: 0 auto;
  list-style: none;
  line-height: 61px;
}

ul>a {
  margin-right: 24px;
  display: flex;
  // font-size: 16px;
  // color: red;
  font-weight: 400;
  color: #3b3d41;
  white-space: nowrap;
}

ul>a:hover {
  color: #4676f8;
  border-radius: 2px;
  white-space: nowrap;
}

.router-link-active a {
  color: #4676f8;
  border-bottom: 2px solid #4676f8;
  border-radius: 2px;
  font-size: 1rem;
}

// }

a {
  text-decoration: none;
  display: block;
  margin-right: 24px;
  // font-size: 16px;
  font-size: 1rem;
  // color: red;
  color: #757575;
}

a:last-child {
  margin-right: 0px;
}

input {
  width: 115px;
  outline: none;
  border-style: none;
  background: none;
  padding: 7px 40px 7px 10px;
}

.search {
  // width: 400px;
  display: flex;
  position: relative;
  margin-left: 50px;
  font-size: 14px;

  input {
    border: solid 1px #dcdfe6;
    // position: relative;
    border-left: none;
  }
}

.search-btn {
  // width: 60px;
  width: 54px;
  line-height: 40px;
  text-align: center;
  background: #f5f7fa;
  // border-radius: 4px;
  border: solid 1px #dcdfe6;
  border-left: none;
  color: #757575;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.search-btn-last {
  position: absolute;
  right: 60px;
  line-height: 42px;

  color: #757575;
  cursor: pointer;
  padding: 0 10px;
}

.search {
  /deep/.el-input__inner {
    width: 110px;
    font-size: 14px;
    color: #757575;
    padding: 6px 8px;
    // border: none;
    // border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 42px;

  }
}


// /deep/.el-input-group__prepend{
//   width: 100px !important;
// }
.login {
  margin-left: 168px;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #757575;
}

.user {
  // margin-left: 60px;
  align-items: center;

  .user-icon>img {
    width: 30px;
    height: 30px;
  }

  .userName {
    font-size: 16px;
    white-space: nowrap;
    color: #3b3d41;
    margin-left: 7px;
  }

  .user-right-icon {
    width: 17px;
    height: 17px;
    margin-left: 3px;
  }

  .user-right-icon>img {
    width: 17px;
    height: 17px;
    display: block;
  }

  .logOut {
    margin-left: 20px;
    font-size: 16px;
    color: #909399;
    white-space: nowrap;
  }

  /deep/.el-submenu__icon-arrow {
    display: none;
  }

  /deep/.el-submenu:hover,
  /deep/.el-submenu__title:hover {
    background: transparent !important;
    color: #3b3d41 !important;
  }

  /deep/.el-menu--horizontal {
    border-bottom: none;
    // position: fixed;
  }

  /deep/.el-menu-item:hover {
    background: rgba(70, 118, 248, 0.1) !important;
    color: #4676f8 !important;
  }
}

// foot样式
.foot {
  background: #62656d;
  overflow: hidden;
}

.foot-left {
  padding-top: 32px;
  padding-bottom: 32px;
  // width: 340px;
  width: 640px;
  height: 100%;
}

.foot-center {
  margin-left: 88px;
  margin-top: 32px;
  width: 370px;
  align-items: center;
}

.foot-publicist {
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
  color: #eee;
  cursor: pointer;
}

.foot-publicist:nth-child(2n) {
  margin-bottom: 8px;
}

.foot-publicist:last-child {
  padding-bottom: 10px;
}

.foot-publicist>a {
  color: #eee;
}

.school {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 12px;
  margin-left: 27px;
  // cursor: pointer;
  cursor: default;
}

.address {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  margin-left: 27px;
  cursor: default;
  // width: 242px;
}

.address-text {
  line-height: 22px;
}

.phone {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  margin-left: 27px;
  cursor: default;
  width: 238px;
}

.email {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 27px;
  margin-bottom: 12px;
  cursor: default;
}

.foot-center-title {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
  cursor: default;
}

.foot-centerInfo {
  width: 200px;
  // width: 450px;
  max-height: 100px;
  // 多行文本溢出剪裁
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.foot-center-list {
  width: 200px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
}

.foot-center-list-info {
  padding-right: 32px;
}

.foot-right {
  width: 291px;
  height: 109px;
  margin-left: 175px;
  display: flex;
  margin-top: 32px;
}

.circle>img {
  width: 82px;
  height: 82px;
  border-radius: 10%;
  background-color: white;
  margin-bottom: 14px;
}

.circle-text {
  margin-right: 61px;
  margin-bottom: 12px;
  width: 90px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.square-text {
  width: 154px;
  height: 14px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.square-box {
  width: 154px;
  margin-left: 25px;
}

.square>img {
  text-align: center;
  width: 82px;
  height: 82px;
  background-color: white;
  margin-bottom: 14px;
  border-radius: 10%;
}

.foot-content {
  margin: 0 auto;
  width: 1264px;
  display: flex;
}

/deep/.el-submenu {
  margin: 0px;
}


/deep/.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

/deep/.el-submenu.is-active .el-submenu__title {
  border: none;
}

/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border: none;
}

/deep/.el-menu--horizontal>.el-submenu .el-submenu__title {
  border: none;
}

/deep/.el-submenu__title {
  padding: 0px;
}

/deep/.el-menu-demo {
  background-color: transparent;
}

/deep/.el-menu-demo:hover {
  background-color: transparent;
}

.my-menu {
  padding: 30px;
  width: 300px;
  display: flex;
}

.my-menu-item-title {
  color: #0156ff;
  padding-bottom: 15px;
}

.my-menu .my-menu-item {
  width: 150px;
}

.my-menu .my-menu-item ul {
  list-style: none;
}

.my-menu-item ul li {
  padding: 10px 0px;
  cursor: pointer;
}

.my-menu-item-active {
  color: #0156ff;
}

.sshy {
  position: relative; // 给el-select一个相对定位

  /deep/ .el-select-dropdown {
    // 下拉框相对于el-select进行定位
    position: absolute !important;
    left: 0 !important;
  }
}

.login-box {
  width: 400px;
  // height: 500px;
  position: fixed;
  left: calc(50% - 250px);
  top: calc(50% - 300px);
  z-index: 9999;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .box-item {
    height: 100%;

    .img {
      width: 150px;
      height: 60px;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 22px;
      text-align: center;
      color: #0156ff;
      font-weight: bold;
      line-height: 100px;
    }
  }

  .form-item {
    width: 350px;
    margin: auto;

    .vercode-box {
      display: flex;

      .vercode {
        width: 150px;
        margin-left: 20px;

        img {
          display: block;
          border-radius: 5px;
        }
      }
    }

    .el-button--primary {
      width: 100%;
      background: #0156ff;
      margin-top: 30px;
    }

    .findPass {
      text-align: right;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
<style>
/* .el-menu  .el-submenu*/
.el-menu--collapse,
.el-menu--popup {
  min-width: 100px !important;
  position: absolute;
  left: -27px;
}
</style>

