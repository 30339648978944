<template>
  <el-dialog :title="handleState + '通知'" :visible="visible" width="40%" center @close="close">
    <div class="dialog-box">
      <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
        <div class="dialog-ipt-box">
          <el-form-item label="通知标题" prop="title" class="ipt-text">
            <el-input v-show="handleState !== '查看'" class="dialog-input-box" v-model="formData.title"
              placeholder="请输入通知标题"></el-input>
            <p v-show="handleState === '查看'">{{ formData.title }}</p>
          </el-form-item>
        </div>
        <div class="dialog-ipt-box">
          <el-form-item label="通知内容" prop="content" class="ipt-text">
            <el-input v-show="handleState !== '查看'" class="textarea-box" type="textarea" maxlength="100"
              :autosize="{ minRows: 12, maxRows: 20 }" placeholder="请输入通知内容" v-model="formData.content">
            </el-input>
            <p v-show="handleState === '查看'">{{ formData.content }}</p>
          </el-form-item>
        </div>
        <div class="line"></div>
        <el-form-item class="addStudent-btn-box">
          <el-button v-show="handleState !== '查看'" class="yesBtn" type="primary" @click="submitForm">确认</el-button>
          <el-button class="noBtn" @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    // 显隐
    visible: Boolean,
    // 表单
    formData: Object,
    /**
     * 当前弹出状态
     * "新增"，"查看"，"编辑"
     */
    handleState: String,
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: "请输入通知标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入通知内容", trigger: "blur" },
          {
            min: 3,
            max: 100,
            message: "长度在 3 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      // this.$refs.formData.resetFields();
      this.$emit("update:visible", false);
      this.$refs.formData.clearValidate();
    },
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.handleApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleApi() {
      let apiName = "/notice/add";
      if ("nid" in this.formData) {
        apiName = "/notice/edit";
      }

      //添加
      let data = this.formData;
      this.$request({
        url: apiName,
        method: "post",
        data,
      })
        .then((res) => {
          this.$emit("submitSuccess");
          this.close();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { });
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-input__inner {
  width: 200px;
  // border: 1px solid red !important;
}

.addStudent-btn-box {
  margin-left: 0px !important;
  width: 500px;
  margin: 0 auto;
}
</style>