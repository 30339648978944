<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="studentManage-box centre-content">
        <h3>学生管理</h3>
        <div class="student-title">
          <div class="ipt-box">
            <div class="flex-box">
              <p class="nowrapText ipt-text">姓名：</p>
              <input class="input-box" type="text" v-model="studentName" placeholder="学生姓名" />
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">学号：</p>
              <input class="input-box" type="text" placeholder="学生学号" v-model="studentNo" />
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">院系：</p>
              <div class="marRight-10">
                <el-select v-model="value" filterable placeholder="请选择" @change="selectBank">
                  <el-option v-for="item in yuanxi" :key="item.deptId" :label="item.deptName" :value="item.deptId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">专业：</p>
              <div class="marRight-10">
                <el-select v-model="zhuanyeValue" filterable placeholder="请选择" @change="zhuanYeSelect">
                  <el-option v-for="item in zhuanye" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">班级：</p>
              <div class="marRight-10">
                <el-select v-model="classValue" filterable placeholder="请选择" @change="classRoomInput">
                  <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">层次：</p>
              <div class="marRight-10">
                <el-select v-model="cengciValue" filterable placeholder="全部" @change="cengCiSelect">
                  <el-option v-for="item in cengci" :key="item.id" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">入学年份：</p>
              <div class="marRight-10">
                <!-- filterable 属性 是否支持搜素 -->
                <el-select v-model="yearsValue" filterable placeholder="请选择" @change="yearsSelect">
                  <el-option v-for="item in years" :key="item.id" :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- <div class="flex-box">
              <p class="nowrapText ipt-text">总分：</p>
              <input @input="handleInput($event)" class="input-box" type="text" placeholder="总分" />
            </div> -->
          </div>
          <div class="btn-box">
            <p class="search-btn hoverFinger" @click="searchMethod()">搜索</p>
            <p class="reset-btn hoverFinger" @click="resetBtn()">重置</p>
          </div>
        </div>
        <div class="student-tab">
          <!-- v-if="configInfo.accountInfo.level == 1" -->
          <div class="btn-box" v-if="configInfo.accountInfo.level == 1">
            <div class="flex-box">
              <!-- <p class="add-btn hoverFinger" @click="addStudent()">添加学生</p>
              <p class="import-btn hoverFinger" @click="importBtn()">
                批量导入
              </p> -->
              <el-button type="primary" @click="addStudent()" plain>添加学生</el-button>
              <el-button type="primary" @click="importBtn()" plain>批量导入</el-button>
              <el-button type="danger" plain @click="delAll()" :disabled="multipleSelection.length == 0">批量删除</el-button>
            </div>
            <!-- <div class="listText">自定义列表</div> -->
          </div>
          <!-- table部分 -->
          <div>
            <el-table ref="multipleTable" :data="studentList" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column prop="stuName" label="姓名" width="150">
                <template slot-scope="scope">{{ scope.row.stuName == '' ? '****' : scope.row.stuName }}</template>
              </el-table-column>
              <el-table-column prop="stuNo" label="学号" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.stuNo == '' ? '****' : scope.row.stuNo }}</template>
              </el-table-column>
              <el-table-column prop="departName" label="院系" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="majorName" label="专业" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="className" label="班级" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="studyYear" sortable label="入学年份" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="levelName" label="层次" show-overflow-tooltip>
              </el-table-column>
              <!-- <el-table-column prop="xuefen" label="已修学分" show-overflow-tooltip>
                            </el-table-column> v-if="configInfo.accountInfo.level == 1" -->

              <el-table-column fixed="right" label="操作" width="100" v-if="configInfo.accountInfo.level == 1">
                <template slot-scope="scope">
                  <el-button @click="handleDelete(scope.$index, scope.row)" type="text" size="small"
                    style="color: #fc4646">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加学生弹框 -->
    <div class="addDialog">
      <el-dialog title="添加学生" :visible.sync="centerDialogVisible" width="30%" center @closed="resetForm('ruleForm')">
        <div class="dialog-box">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <div class="dialog-ipt-box">
              <el-form-item label="姓名" prop="name" class="ipt-text">
                <el-input class="dialog-input-box" v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="学号" prop="studentNo" class="ipt-text">
                <el-input class="dialog-input-box" v-model="ruleForm.studentNo" placeholder="请输入学号"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="院系" prop="region">
              <el-select v-model="ruleForm.region" filterable placeholder="请选择" @change="selectBank">
                <el-option v-for="item in yuanxi" :key="item.deptId" :label="item.deptName" :value="item.deptId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业" prop="zhuanye">
              <el-select v-model="ruleForm.zhuanye" filterable placeholder="请选择" @change="zhuanYeSelect">
                <el-option v-for="item in zhuanye" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="班级" prop="classRoom">
              <el-select v-model="ruleForm.classRoom" filterable placeholder="请选择">
                <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="层次" prop="cengci">
              <el-select v-model="ruleForm.cengci" filterable placeholder="全部">
                <el-option v-for="item in cengci" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="入学年份" prop="years">
              <div class="marRight-24">
                <el-select v-model="ruleForm.years" filterable placeholder="请选择">
                  <el-option v-for="item in yearsDialog" :key="item.id" :value="item">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="ruleForm.sex">
                <el-radio label="1" @change="sexChange($event)">男</el-radio>
                <el-radio label="2" @change="sexChange($event)">女</el-radio>
              </el-radio-group>
            </el-form-item>

            <div class="line"></div>
            <el-form-item class="addStudent-btn-box">
              <el-button class="yesBtn" type="primary" @click="submitForm('ruleForm')">确认</el-button>
              <el-button class="noBtn" @click="resetForm('ruleForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>

    <!-- 批量导入弹框 -->
    <div class="channelDialog">
      <el-dialog title="批量导入" :visible.sync="channelDialogVisible" width="25%" v-loading="studentImportLoading">
        <div class="">
          <div class="dialog-text">
            模板：<span style="
                font-size: 14px;
                font-weight: bold;
                color: #4676f8;
                margin-left: 10px;
              " @click="downLoad()">点击下载模板</span>
          </div>
          <div class="displayFlex" style="margin-bottom: 24px; margin-top: 10px">
            <div class="dialog-text">注意：</div>
            <div class="dialog-text">
              <p>1.表格模板不能随意变动</p>
              <p>2.模板中所有标注“*”项为必填项</p>
              <p>3.请按每列批准说明要去填写用户信息</p>
              <p>4.建议每次导入数据不超过3000条</p>
            </div>
          </div>
          <div style="display: flex;">
            <div class="dialog-text">
              <span style="color: red">*</span>上传文件：
            </div>
            <div class="import-btn hoverFinger">
              <!-- :on-exceed="handleExceed" -->
              <el-upload ref="upload" class="upload-demo" action="javascript:void(0);" accept=".xls,.xlsx"
                :on-remove="handleRemove" :on-change="onChange" :before-remove="beforeRemove" multiple
                :show-file-list="true" :limit="1" :file-list="fileList" :auto-upload="false">
                <el-button size="small" type="primary" style="font-size: 14px">选择文件</el-button>
                <span style="font-size: 14px; color: #c0c4cc; margin-left: 10px">
                  文件格式xls、xlsx文件
                </span>
              </el-upload>
            </div>

          </div>
        </div>
        <div class="line"></div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitAddFile">确 认</el-button>
          <el-button @click="importCancel()">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import request2 from "@/utils/request2.js";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import { post } from "@/utils/xajax";
export default {
  data () {
    return {
      yuanxi: [],
      value: "",
      zhuanye: [],
      zhuanyeValue: "",
      classList: [],
      classValue: "",
      cengci: [],
      cengciValue: "",
      years: [],
      yearsValue: "",
      studentName: "",

      configInfo: {
        accountInfo: {}
      }, // 用户数据
      accountName: "", //姓名
      studentNo: "", //学号
      classRoomId: 0, //班级
      majorId: 0, // 专业
      levelId: 0, //层次
      studyYear: 0, //年份
      yearsDialog: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],

      multipleSelection: [],

      total: 0, //列表数量
      currentPage: 1, //当前页码
      perPage: 10, //每页多少条数据
      studentList: [], //学生数据列表
      stuId: "", //学生id
      filePath: "", //文件地址
      secretKey: "", //密钥
      centerDialogVisible: false, //添加学生弹框
      channelDialogVisible: false, //导入弹框
      fileList: [],
      // files: [],
      files: {},
      ruleForm: {
        name: "",
        studentNo: "",
        region: "",
        sex: "",
        zhuanye: "",
        classRoom: "",
        cengci: "",
        years: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur",
          },
        ],
        studentNo: [
          { required: true, message: "请输入学号", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        region: [{ required: true, message: "请选择院系", trigger: "change" }],
        zhuanye: [{ required: true, message: "请选择专业", trigger: "change" }],
        classRoom: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        cengci: [{ required: true, message: "请选择层次", trigger: "change" }],
        years: [{ required: true, message: "请选择年份", trigger: "change" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
      },

      sex: "", //1 男 2 女  3 保密
      // sex: [], //1 男 2 女  3 保密
      radio: 0,

      studentImportLoading: false
    };
  },
  //接口 一进入页面就执行的
  mounted () {
    window, scrollTo(0, 0);
    let Base64 = require("js-base64").Base64;
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.configInfo = configInfo;
    this.accountName = configInfo.accountInfo.accountName;
    let secretKey = localStorageGet("secretKey");
    this.secretKey = secretKey?Base64.encode(secretKey):'';

    this.getStudentList(); // 学生列表
    this.getStudentDeparts(); //院系列表
    this.getStudentMisc(); //请求层次
  },
  //事件方法
  methods: {
    handleCurrentChange (val) {
      //切换页码
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getStudentList();
    },
    handleSizeChange (val) {
      //每页多少条数据
      this.perPage = val;
      window.scrollTo(0, 0);
      this.getStudentList();
    },
    getStudentList () { //学生列表
      let data = {
        pageNum: this.currentPage,
        perPage: this.perPage, //每页多少条数据
        studentName: this.studentName, //当前用户
        studentNo: this.studentNo, //学号
        deptId: this.value, //院系搜索
        majorId: this.majorId, //专业搜索
        classRoomId: this.classRoomId, //班级搜索
        levelId: this.levelId, //层次搜索
        studyYear: this.studyYear, //入学年份
        enkey: this.secretKey, //密钥
      };
      request({
        url: "/student/students",
        method: "post",
        data,
      })
        .then((res) => {
          this.total = res.data.count;
          this.studentList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStudentDeparts () {
      let data = {};
      request({
        url: "/student/departs",
        method: "post",
        data,
      })
        .then((res) => {
          this.yuanxi = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectBank (deptId) {      //院系
      this.deptId = deptId;
      this.getStudentMajor();
    },
    zhuanYeSelect (majorId) {    //专业
      this.majorId = majorId;
      this.getStudentClass();
    },
    classRoomInput (classRoomId) {
      //班级
      this.classRoomId = classRoomId;
    },
    cengCiSelect (levelId) {
      //层次
      this.levelId = levelId;
    },
    yearsSelect (studyYear) {
      //年份
      this.studyYear = studyYear;
    },
    delAll () {//批量删除
      this.$confirm('此操作将永久删除该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        this.multipleSelection.forEach((r) => {
          arr.push(r.stuId)
        })
        let data = { stuIds: arr.join(',') };
        request({
          url: "/student/delall",
          method: "post",
          data,
        })
          .then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getStudentList()
          })
          .catch((err) => {
            console.log(err);
          });

      }).catch(() => { });

    },
    getStudentMajor () {      //请求专业   stype 1 请求专业 2 请求班级
      let data = { deptId: this.deptId, stype: 1, majorId: 0 };
      request({
        url: "/student/majorclass",
        method: "post",
        data,
      })
        .then((res) => {
          // 置空title
          this.classList = [];//置空班级列表
          this.classValue = '';//置空班级输入框
          this.classRoomId = ''; //置空班级id
          this.zhuanyeValue = '';  //置空专业列表
          this.majorId = '';  //置空专业
          // this.studyYear = ''; //清空年份
          // this.levelId = '';  // 置空层次
          // 置空弹窗内班级 层次
          this.ruleForm.zhuanye = '';
          this.ruleForm.classRoom = '';

          this.zhuanye = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStudentClass () {      //请求班级
      this.classList = [];//置空班级列表
      this.ruleForm.classRoom = ''; // 置空班级value
      this.classValue = '';//置空班级输入框
      this.classRoomId = ''; //置空班级id
      let data = { deptId: this.deptId, stype: 2, majorId: this.majorId };
      request({
        url: "/student/majorclass",
        method: "post",
        data,
      })
        .then((res) => {
          this.classList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStudentMisc () {
      //请求层次
      let data = {};
      request({
        url: "/student/misc",
        method: "post",
        data,
      })
        .then((res) => {
          this.cengci = res.data.stuLevel;
          this.years = res.data.studyYear;
          // this.sex = res.data.sexList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addStudent () { // 添加学生弹框
      this.centerDialogVisible = true;
    },
    importBtn () {  //批量导入弹框
      this.channelDialogVisible = true;
    },
    importCancel () { //导入取消按钮
      this.channelDialogVisible = false;
      this.fileList = [];
    },
    cancel () {//取消
      this.centerDialogVisible = false;
    },
    confirm () {
      this.getStudentAdd();
    },

    submitForm (formName) { //添加学生弹框提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getStudentAdd();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm (formName) {
      console.log('取消了')
      this.$refs[formName].resetFields();
      this.centerDialogVisible = false;
      this.studyYear = "";
      this.levelId = "";
      this.majorId = "";
      this.classRoomId = "";
      this.zhuanye = []; //清空专业
      this.classList = []; //清空班级
      this.emptyHeadInpt();
    },

    getStudentAdd () {//添加学生
      let data = {
        studentName: this.ruleForm.name, //姓名
        studentNo: this.ruleForm.studentNo, //学号
        deptId: this.ruleForm.region, //院系
        majorId: this.ruleForm.zhuanye, //专业
        classId: this.ruleForm.classRoom, //班级
        levelId: this.ruleForm.cengci, //层次
        studyYear: this.ruleForm.years, //入学年份
        sex: this.ruleForm.sex, //性别
      };
      request({
        url: "/student/add",
        method: "post",
        data,
      })
        .then((res) => {
          this.majorId = ''; //置空专业
          this.getStudentList();
          this.centerDialogVisible = false;
          this.$refs[formName].resetFields();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getFile (event) {
      var file = event.target.files;
      for (var i = 0; i < file.length; i++) {
        //    上传类型判断
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx != -1) {
          var ext = imgName.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (ext != "pdf" && ext != "doc" && ext != "docx") {
          } else {
            this.addArr.push(file[i]);
          }
        } else {
        }
      }
    },
    onChange (file) {
      this.files = file.raw;
    },
    async submitAddFile () {
      if (!this.files) {
        this.$message({
          type: "info",
          message: "请选择要上传的文件",
        });
        return;
      }

      var formData = new FormData();
      formData.append("xltuploadfile", this.files);
      this.studentImportLoading = true;
      try {
        // 上传文件
        let filePath = await this.getStudentUpload(formData);
        // 解析并提交文件
        await studentImport.call(this, filePath);
      } catch (e) {
      } finally {
        this.studentImportLoading = false;
      }

      function studentImport (filePath) {
        //学生导入
        let data = {
          filePath: filePath,
        };
        request({
          url: "/student/import",
          method: "post",
          data,
        })
          .then((res) => {
            this.$message({
              message: '数据导入成功',
              type: 'success'
            });
            this.channelDialogVisible = false;
            this.fileList = [];
          })
          .catch((err) => {
            console.log('fileList', this.fileList)
            console.log(err);
          });
      }
    },

    handleRemove (file, fileList) {
      this.files = null;
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    getStudentUpload (formData) {
      return new Promise((resolve, reject) => {
        //上传
        request2({
          url: "/upload",
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res.data.filePath);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    handleDelete (index, row) {
      //获取删除学生stuId
      this.stuId = row.stuId;
      this.$confirm("是否确认删除该学生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getStudentDel();
          this.$message({
            type: "success",
            message: "删除成功!",
          });

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getStudentDel () {
      //删除学生
      let data = { stuId: this.stuId };
      request({
        url: "/student/del",
        method: "post",
        data,
      })
        .then((res) => {
          this.getStudentList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nameInput (e) {      //姓名
      this.studentName = e;
    },
    stuIdInput (e) {     //学号;
      this.studentNo = e;
    },
    sexChange (e) {      //性别
      this.sex = e;
      // console.log(e)
    },
    // handleInput(e) { //总分
    //     this.studentNo = e.target.value;
    //     console.log(this.studentNo)
    // },
    searchMethod () {      //搜索
      this.currentPage = 1;
      this.getStudentList();
    },
    resetBtn () {      //重置
      this.studyYear = "";
      this.levelId = "";
      this.majorId = "";
      this.classRoomId = "";
      this.zhuanye = []; //清空专业
      this.classList = []; //清空班级
      this.emptyHeadInpt();
      this.getStudentList();
      // this.$set(this.ruleForm, 'left', null)
    },
    emptyHeadInpt () {      //清空头部输入框
      this.studentName = ""; //姓名
      this.studentNo = ""; //学号
      this.value = ""; //院系
      this.zhuanyeValue = ""; //专业
      this.classValue = ""; //班级
      this.cengciValue = ""; //层次
      this.yearsValue = ""; //年份
    },

    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    downLoad () {
      //导入下载模板
      window.location.href =
        "https://xlingtong.oss-cn-beijing.aliyuncs.com/reslib/files/%E8%B5%84%E6%BA%90%E5%BA%93%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx";
    },
  },
  components: {},
  computed: {},
  filters: {
    filtime (val) { },
  },
};
</script>

<style lang='less' scoped>
h3 {
  height: 45px;
  border-bottom: 1px solid #dcdfe6;
}

.displayFlex {
  display: flex;
}

.studentManage-box {
  padding: 40px 0px 60px;

  .student-title {
    display: flex;
    justify-content: space-between;
    // padding: 24px;
    padding: 24px 24px 24px 0px;
    margin-top: 16px;
    height: 136px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
    border-radius: 8px;

    .ipt-box {
      display: flex;
      text-align: right;
      width: 1200px;
      flex-wrap: wrap;

      // justify-content: space-around;

      .ipt-text {
        font-size: 14px;
        color: #303133;
        // border: 1px solid red;
        // width: 70px;
        width: 70px;
      }

      .input-box {
        padding: 8px;
        width: 200px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid #c0c4cc;
        outline-color: #c0c4cc;
        // margin-right: 24px;
        margin-right: 10px;
      }

      /deep/.el-input__inner {
        width: 220px;
      }
    }

    .btn-box {
      display: flex;
      padding-top: 12px;

      .search-btn {
        margin-right: 24px;
        width: 72px;
        height: 32px;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(317deg,
            rgba(70, 118, 248, 0.99) 0%,
            #54c6f8 100%);
        box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
        border-radius: 4px;
      }

      .reset-btn {
        width: 72px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #c0c4cc;
        font-size: 14px;
        color: #606266;
      }
    }


    input::-webkit-input-placeholder {
      color: #C0C0C0;
      // color: red;
      // color: #E4E5E9;
      font-size: 12px;
    }
  }

  .student-tab {
    .btn-box {
      margin-bottom: 16px;
    }

    padding: 24px;
    margin-top: 16px;
    // width: 1440px;
    // height: 624px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
    border-radius: 8px;

    .btn-box {
      display: flex;
      justify-content: space-between;

      .add-btn,
      .import-btn {
        width: 96px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #4676f8;
        font-size: 14px;
        color: #4676f8;
      }

      .add-btn {
        margin-right: 24px;
      }

      .listText {
        font-size: 14px;
        color: #4676f8;
      }
    }

    .page-box {
      padding: 40px 0px;
    }
  }
}

// 添加弹框
.addDialog {
  .el-button--primary {
    color: #4676f8;
    border: none;
  }

  /deep/.el-dialog--center {
    // width: 468px !important;
    // height: 559px !important;
    // background: #FFFFFF !important;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px !important;
  }

  /deep/.el-form-item__error {
    left: 20%;
  }

  .dialog-box {

    /deep/.el-input__inner,
    /deep/.el-select {
      width: 300px !important;
      // border: 1px solid red !important;
    }

    .dialog-ipt-box {
      margin-bottom: 24px;

      .ipt-text {
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
      }

      .dialog-input-box {
        width: 300px;
        border-radius: 6px;
        border: 0.2px solid #c0c4cc;
        // outline-color: #409EFF;
        outline-color: #c0c4cc;
        margin-right: 24px;

        /deep/.el-input__inner {
          width: 300px;
          border: none;
        }
      }
    }

    .line {
      // width: 510px;
      height: 1px;
      background: #d5d6d9;
      margin: 20px auto;
    }

    .addStudent-btn-box {
      // text-align: center;
      padding-left: 75px;

      .yesBtn {
        width: 76px;
        height: 36px;
        padding: 0px;
        line-height: 36px;
        background: linear-gradient(317deg,
            rgba(70, 118, 248, 0.99) 0%,
            #54c6f8 100%);
        box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        margin-right: 32px;
      }

      .noBtn {
        width: 76px;
        height: 36px;
        line-height: 36px;
        padding: 0px;
        border-radius: 4px;
        border: 1px solid #4676f8;
        font-size: 14px;
        color: #4676f8;
      }
    }

    // /deep/.el-form-item__content {
    //   margin-left: 0px !important;
    // }
  }
}

//批量导入
.channelDialog {
  .dialog-text {
    font-size: 14px;
    color: #606266;
    white-space: nowrap;
  }

  .line {
    width: 432px;
    height: 1px;
    background: #d5d6d9;
    margin-top: 70px;
    margin-bottom: 16px;
  }

  /deep/.el-dialog__body {
    padding: 30px 20px 0px;
  }

  /deep/.el-dialog__footer {
    text-align: center;
  }

  /deep/.el-button,
  /deep/.vel-button--primary {
    width: 76px;
    height: 36px;
    padding: 0px;
  }
}
</style>
