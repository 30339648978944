<template>
  <!-- 添加用户 -->
  <div class="addDialog">
    <el-dialog :title="handleState + '用户'" :visible="visible" width="30%" center @close="close">
      <div class="dialog-box">
        <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
          <!-- readonly="true" -->
          <div class="dialog-ipt-box">
            <el-form-item label="用户名" prop="accountName" class="ipt-text">
              <el-input v-show="!['查看', '编辑'].includes(handleState)" class="dialog-input-box"
                v-model="formData.accountName" placeholder="请输入用户名"></el-input>
              <p v-show="['查看', '编辑'].includes(handleState)">
                {{ formData.accountName }}
              </p>
            </el-form-item>
          </div>
          <div class="dialog-ipt-box">
            <el-form-item label="显示名称" prop="trueName" class="ipt-text">
              <el-input v-show="handleState !== '查看'" class="dialog-input-box" v-model="formData.trueName"
                placeholder="请输入姓名"></el-input>
              <p v-show="handleState === '查看'">{{ formData.trueName }}</p>
            </el-form-item>
          </div>
          <div class="dialog-ipt-box">
            <el-form-item label="手机号" prop="cellPhone" class="ipt-text">
              <el-input v-show="handleState !== '查看'" maxlength="11" class="dialog-input-box" v-model="formData.cellPhone"
                placeholder="请输入手机号"></el-input>
              <p v-show="handleState === '查看'">{{ formData.cellPhone }}</p>
            </el-form-item>
          </div>
          <div class="dialog-ipt-box" v-show="handleState !== '查看'">
            <el-form-item prop="password" class="ipt-text">
              <span slot="label"><span style="color: red">*</span> 密码</span>
              <el-input type="password" class="dialog-input-box" v-model="formData.password"
                placeholder="请输入密码"></el-input>
            </el-form-item>
          </div>
          <div class="dialog-ipt-box" v-show="handleState !== '查看'">
            <el-form-item label="确认密码" prop="checkPass" class="ipt-text">
              <span slot="label"><span style="color: red">*</span> 确认密码</span>
              <el-input type="password" class="dialog-input-box" v-model="formData.checkPass"
                placeholder="确认密码"></el-input>
            </el-form-item>
          </div>

          <div class="line"></div>
          <el-form-item class="addStudent-btn-box" style="margin: 0 auto">
            <el-button v-show="handleState !== '查看'" class="yesBtn" type="primary" @click="submitForm">确认</el-button>
            <el-button class="noBtn" @click="close">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request.js";

export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    // 显隐
    visible: Boolean,
    // 表单
    formData: Object,
    /**
     * 当前弹出状态
     * "新增"，"查看"，"编辑"
     */
    handleState: String,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "" || value === undefined || value === null) {
        return callback(new Error("请输入密码"));
      }
      // if(value) {
      //     return callback(new Error("密码规则是：***"));
      // }

      if (this.formData.checkPass !== "") {
        this.$refs.formData.validateField("checkPass");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "" || value === undefined || value === null) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        trueName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        accountName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        cellPhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "电话格式不正确", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  // mounted(){
  //     console.log("formData", this.$refs.formData);
  // },
  methods: {
    close() {
      // console.log(this.$refs.formData)
      // this.$refs[formData].resetFields();

      this.$emit("update:visible", false);
      this.$refs.formData.clearValidate();
      // console.log("formData", this.$refs.formData);
    },
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.handleApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleApi() {
      let apiName = "/accounts/add";
      if ("accountId" in this.formData) {
        apiName = "/accounts/edit";
      }

      //添加
      let data = this.formData;
      this.$request({
        url: apiName,
        method: "post",
        data,
      })
        .then((res) => {
          this.$emit("submitSuccess");
          this.close();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { });
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-input__inner {
  width: 200px;
  // border: 1px solid red !important;
}

.addStudent-btn-box {
  margin-left: 0px;
  width: 350px;
  margin: 0 auto;
}
</style>
