<template>
    <div class="backgrounAll">
        <div class="animate__animated animate__bounce animate__fadeInUp">
            <div class="studentManage-box centre-content">
                <div class="student-title">
                    <el-form :inline="false" :model="formInline" label-width="85px" label-suffix=""
                        class="demo-form-inline">
                        <el-form-item label="姓名">
                            <el-input v-model="formInline.studentName" placeholder="请输入姓名" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="学号">
                            <el-input v-model="formInline.studentNo" placeholder="请输入学号" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item label="院系">
                            <el-select v-model="formInline.deptId" placeholder="请选择院系" @change="deptChange" size="medium">
                                <el-option v-for="item in department" :key="item.deptId" :label="item.deptName"
                                    :value="item.deptId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="专业">
                            <el-select v-model="formInline.majorId" placeholder="请选择专业" @change="speciaChange"
                                size="medium">
                                <el-option v-for="item in speciality" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级">
                            <el-select v-model="formInline.classRoomId" placeholder="请选择班级" size="medium">
                                <el-option v-for="item in classList" :key="item.value" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="层次">
                            <el-select v-model="formInline.levelId" placeholder="请选择层次" size="medium">
                                <el-option v-for="item in levels" :key="item.id" :label="item.title" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="入学年份">
                            <el-select v-model="formInline.studyYear" placeholder="请选择入学年份" size="medium">
                                <el-option v-for="item in enrolYear" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="总分" style="width:287px;">
                            <el-col :span="11">
                                <el-input v-model="formInline.minScore" placeholder="最小分" oninput="if(isNaN(value)) { value = 
               null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" size="medium"></el-input>
                            </el-col>
                            <el-col class="line" :span="2" style="text-align:center">-</el-col>
                            <el-col :span="11">
                                <el-input v-model="formInline.maxScore" oninput="if(isNaN(value)) { value = 
               null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" placeholder="最高分"
                                    size="medium"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>

                    <div class="btn-box">
                        <p class="search-btn hoverFinger" @click="searchMethod()">搜索</p>
                        <p class="reset-btn hoverFinger" @click="resetBtn()">重置</p>
                    </div>
                </div>
                <div class="student-tab">
                    <!-- v-if="configInfo.accountInfo.level == 1" -->
                    <div class="btn-box" v-if="configInfo.accountInfo.level == 1">
                        <div class="flex-box">
                            <!-- v-loading.fullscreen.lock="fullscreenLoading" -->
                            <el-button type="primary" @click="statistical" plain
                                v-loading.fullscreen.lock="fullscreenLoading">重新统计</el-button>
                            <!-- :disabled="multipleSelection.length == 0" -->
                            <el-button :loading="fileType" type="primary" @click="importBtn" plain>导出成绩</el-button>
                        </div>
                        <!-- <div class="listText">自定义列表</div> -->
                    </div>
                    <!-- table部分 -->
                    <div>
                        <el-table ref="multipleTable" stripe :data="studentList" tooltip-effect="dark" style="width: 100%"
                            @selection-change="handleSelectionChange" :header-cell-style="{
                                background: '#fafafa', color: '#000'
                            }">
                            <!-- <el-table-column type="selection" width="55" fixed="left"> </el-table-column> -->
                            <el-table-column type="index" label="序号" width="80" fixed="left">
                            </el-table-column>
                            <el-table-column prop="stuName" label="姓名" width="100" fixed="left">
                                <template slot-scope="scope">{{ scope.row.stuName == '' ? '****' : scope.row.stuName
                                }}</template>
                            </el-table-column>
                            <el-table-column prop="stuNo" label="学号" show-overflow-tooltip width="180">
                                <template slot-scope="scope">{{ scope.row.stuNo == '' ? '****' : scope.row.stuNo
                                }}</template>
                            </el-table-column>
                            <el-table-column prop="departName" label="院系" show-overflow-tooltip width="200">
                            </el-table-column>
                            <el-table-column prop="majorName" label="专业" show-overflow-tooltip width="200">
                            </el-table-column>
                            <el-table-column prop="className" label="班级" show-overflow-tooltip width="200">
                            </el-table-column>
                            <el-table-column prop="studyYear" sortable label="入学年份" show-overflow-tooltip width="150">
                            </el-table-column>
                            <el-table-column prop="levelName" label="层次" show-overflow-tooltip width="150">
                            </el-table-column>
                            <el-table-column prop="kechengScore" label="课程学分" show-overflow-tooltip width="120">
                            </el-table-column>
                            <el-table-column prop="videoScore" label="微视频学分" show-overflow-tooltip width="120">
                            </el-table-column>
                            <el-table-column prop="articleScore" label="心理文章学分" show-overflow-tooltip width="120">
                            </el-table-column>
                            <el-table-column prop="totalScore" label="总分" show-overflow-tooltip fixed="right" width="120">
                            </el-table-column>

                            <!-- <el-table-column fixed="right" label="操作" width="100" v-if="configInfo.accountInfo.level == 1">
                                <template slot-scope="scope">
                                    <el-button @click="handleDelete(scope.$index, scope.row)" type="text" size="small"
                                        style="color: #fc4646">删除</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!-- 分页 -->
                        <div class="page-box">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import request from "@/utils/request.js";
import { localStorageGet, localStorageSet } from "@/utils/localStorage";
export default {
    data () {
        return {
            formInline: {
                studentName: '',//姓名
                studentNo: '',//学号
                deptId: '',//院系
                majorId: '',//专业
                classRoomId: '',//班级
                levelId: '',//层次
                studyYear: '',//入学年份
                totalScore: '',//总分
                minScore: '',//最小分
                maxScore: '',//最高分

            },
            department: [],//院系数据集合
            speciality: [],//专业数据集合
            classList: [],//班级数据集合
            levels: [],//层次数据集合
            enrolYear: [],//年份数据集合
            fullscreenLoading: false,
            fileType: false,

            configInfo: {
                accountInfo: {}
            }, // 用户数据

            multipleSelection: [],
            total: 0, //列表数量
            currentPage: 1, //当前页码
            perPage: 10, //每页多少条数据
            studentList: [], //学生数据列表
            secretKey: "", //密钥
        };
    },
    //接口 一进入页面就执行的
    mounted () {
        window, scrollTo(0, 0);
        let Base64 = require("js-base64").Base64;
        let configInfo = JSON.parse(localStorageGet("userInfo"));
        this.configInfo = configInfo;
        this.accountName = configInfo.accountInfo.accountName;
        let secretKey = localStorageGet("secretKey");
        this.secretKey = secretKey?Base64.encode(secretKey):'';

        this.getStudentList(); // 学生列表
        this.getStudentDeparts(); //院系列表
        this.getStudentMisc(); //请求层次
    },
    //事件方法
    methods: {
        handleCurrentChange (val) {
            this.currentPage = val;
            // window.scrollTo(0, 0);
            this.getStudentList();
        },
        handleSizeChange (val) {
            this.perPage = val;
            window.scrollTo(0, 0);
            this.getStudentList();
        },
        getStudentList () { //学生列表
            this.formInline.totalScore = this.formInline.minScore + '-' + this.formInline.maxScore
            let data = {
                ...this.formInline,
                pageNum: this.currentPage,
                perPage: this.perPage, //每页多少条数据
                enkey: this.secretKey, //密钥
            };
            request({
                url: "/student/scores",
                method: "post",
                data,
            })
                .then((res) => {
                    this.total = res.data.count;
                    this.studentList = res.data.list;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getStudentDeparts () {//院系接口
            let data = {};
            request({
                url: "/student/departs",
                method: "post",
                data,
            })
                .then((res) => {
                    this.department = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deptChange (deptId) {//院系
            this.formInline.deptId = deptId;
            this.getStudentMajor();
        },
        speciaChange (majorId) {    //专业
            this.formInline.majorId = majorId;
            this.getStudentClass();
        },
        getStudentMajor () {      //请求专业   stype 1 请求专业 2 请求班级
            let data = { deptId: this.formInline.deptId, stype: 1, majorId: 0 };
            request({
                url: "/student/majorclass",
                method: "post",
                data,
            })
                .then((res) => {
                    // 置空title
                    this.speciality = [];//置空专业列表
                    this.classList = [];//置空班级列表
                    this.formInline.majorId = '';  //置空专业选中
                    this.formInline.classRoomId = '';//置空班级选中
                    this.speciality = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getStudentClass () {//班级接口
            this.classList = [];//置空班级列表
            this.formInline.classRoomId = '';//置空班级输入框
            let data = { deptId: this.formInline.deptId, stype: 2, majorId: this.formInline.majorId };
            request({
                url: "/student/majorclass",
                method: "post",
                data,
            })
                .then((res) => {
                    this.classList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getStudentMisc () {
            //请求层次
            let data = {};
            request({
                url: "/student/misc",
                method: "post",
                data,
            })
                .then((res) => {
                    this.levels = res.data.stuLevel;
                    this.enrolYear = res.data.studyYear;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        statistical () {
            this.fullscreenLoading = true;
            let data = {};
            request({
                url: "/student/calscore",
                method: "post",
                data,
            })
                .then((res) => {
                    this.$alert('成绩统计完成！', '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        callback: action => {
                            this.fullscreenLoading = false;
                            this.currentPage = 1;
                            this.getStudentList()
                        }
                    });
                })
                .catch((err) => {
                    this.$alert(err.msg, '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        callback: action => {
                            this.fullscreenLoading = false;
                        }
                    });
                });
        },

        importBtn () {//导出
            this.fileType = true
            let data = { enkey: this.secretKey,...this.formInline };
            request({
                url: "/student/exportscore",
                method: "post",
                data,
            }).then((res) => {
                    const a = document.createElement('a');
                    a.setAttribute('download', ''); //download属性
                    a.setAttribute('href', res.data.fileUrl);
                    a.click(); // 自执行点击事件
                    console.log('导出成功', res);
                    this.fileType = false
                })
                .catch((err) => {
                    console.log(err);
                    this.fileType = false
                });
        },

        searchMethod () {      //搜索
            if (this.formInline.minScore > this.formInline.maxScore) {
                this.$message.info('最高分不能小于最低分')
            } else {
                this.currentPage = 1;
                this.getStudentList();
            }
        },
        resetBtn () {      //重置
            Object.keys(this.formInline).forEach((key) => (this.formInline[key] = ''))
            this.getStudentList();
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
    },
    components: {},
    computed: {},
    filters: {
        filtime (val) { },
    },
};
</script>
<style lang='less' scoped>
/deep/.el-select .el-input__inner {
    // width: 220px;
    padding-right: 15px;
}

h3 {
    height: 45px;
    border-bottom: 1px solid #dcdfe6;
}

.studentManage-box {

    .student-title {
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 24px 0px;
        margin-top: 16px;
        height: 136px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
        border-radius: 8px;

        .btn-box {
            display: flex;
            padding-top: 12px;

            .search-btn {
                margin-right: 24px;
                width: 72px;
                height: 32px;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                color: #ffffff;
                background: linear-gradient(317deg,
                        rgba(70, 118, 248, 0.99) 0%,
                        #54c6f8 100%);
                box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
                border-radius: 4px;
            }

            .reset-btn {
                width: 72px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #c0c4cc;
                font-size: 14px;
                color: #606266;
            }
        }
    }

    .student-tab {
        .btn-box {
            margin-bottom: 16px;
        }

        padding: 24px;
        margin-top: 16px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
        border-radius: 8px;

        .btn-box {
            display: flex;
            justify-content: space-between;

            .add-btn,
            .import-btn {
                width: 96px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #4676f8;
                font-size: 14px;
                color: #4676f8;
            }

            .add-btn {
                margin-right: 24px;
            }

            .listText {
                font-size: 14px;
                color: #4676f8;
            }
        }

        .page-box {
            padding: 40px 0px;
        }
    }
}

.score-box {
    /deep/.el-form-item {
        display: inline-block;
        vertical-align: top;
        width: 100px;
        display: flex;
    }
}

.demo-form-inline {
    display: flex;
    flex-wrap: wrap;
}
</style>