<template>
  <div class="book-box backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="centre-content">
        <div class="course-nav">
          <div v-if="isSearch != true">
            <!-- <div :class="tabActive == 0
              ? 'nav-item-active hoverFinger'
              : 'nav-item hoverFinger'
              " @click="tabChange(0)">
              全部
            </div>
            <div v-for="item in navList" :class="tabActive == item.cateId
              ? 'nav-item-active hoverFinger'
              : 'nav-item hoverFinger'
              " @click="tabChange(item.cateId)">
              {{ item.cateName }}
            </div> -->
            <el-tabs v-model="cateId" @tab-click="tabChange">
              <el-tab-pane :label="item.cateName" :name="item.cateId.toString()" v-for="(item,i) in navList" :key="i"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-if="bookList.length != 0">
          <!-- 列表 -->
          <div class="book-list">
            <div class="book-item" v-for="(bookItem,i) in bookList" @click="toDetail(bookItem.cbid)" :key="i">
              <p class="book-cover"><img :src="bookItem.coverImg" alt="" /></p>
              <div class="book-info parentLocation">
                <p class="book-title">{{ bookItem.bookName }}</p>
                <p class="book-introduce">
                  {{ bookItem.bookDesc }}
                </p>
                <p class="book-auth sonLocation">
                  {{ bookItem.bookAuthor }} - {{ bookItem.cateName }}
                  <span v-show="bookItem.words != 0">- {{ bookItem.words }}字</span>
                </p>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-box">
            <p class="homePage hoverFinger" @click="startPage()">首页</p>
            <el-pagination :page-size="perPage" background layout="prev, pager, next" :total="total" pager-count:7
              @current-change="currentChange" :current-page="currentPage">
            </el-pagination>
            <p class="endPage hoverFinger" @click="currentChange(Math.ceil(total / perPage))">
              尾页
            </p>
          </div>
        </div>
        <!-- 暂无内容 -->
        <div class="notBox" v-else>
          <p class="no-content">
            <img src="../assets/home/no-content.png" alt="" />
          </p>
          <p class="no-conent-text">暂无资源，平台正在努力添加资源~</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import request from "@/utils/request";
export default {
  data () {
    return {
      navList: [],
      tabActive: 0,
      cateId: 0,
      bookList: [],
      currentPage: 1, // 当前页码
      total: 0, //列表总数量
      perPage: 10,
      isSearch: false,
      // searchType: '',
      // keyWord: '',
    };
  },
  //接口 一进入页面就执行的
  mounted () {
    // this.searchType = this.$route.query.searchType;
    // this.keyWord = this.$route.query.keyWord;
    this.getBookCate();
    this.getBookList();
    // this.isSearch = this.$route.query.search;
  },
  watch: {
    // keyWord() {
    //   // this.getBookList();
    //   if (this.searchType != undefined && this.keyWord != undefined && this.searchType == 'book') {
    //     // this.searchList();
    //     this.getBookList();
    //     return;
    //   }
    // },
    "$store.state.searchInfo" () {
      this.currentPage = 1;
      this.getBookList();
    },
  },
  //事件方法
  methods: {
    currentChange (val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getBookList();
    },
    startPage () {
      this.currentPage = 1;
      this.getBookList();
    },
    tabChange (cateId) {
      this.currentPage = 1;
      this.cateId = cateId.name;
      this.tabActive = cateId.name;
      this.getBookList();
    },
    toDetail (cbid) {
      // this.$router.push(`/bookDetail/${cbid}`)
      let pathInfo = this.$router.resolve({
        path: `/bookDetail/${cbid}`,
      });
      window.open(pathInfo.href, "_blank");
    },
    getBookCate () {
      //图书分类
      let data = {};
      request({
        url: "/book/cate",
        method: "post",
        data,
      }).then((res) => {
        this.navList = res.data;
        this.navList.unshift({cateId:0,cateName:'全部'})
      });
    },
    getBookList () {
      //图书列表
      if (
        this.searchType != undefined &&
        this.keyWord != undefined &&
        this.searchType == "book"
      ) {
        this.searchList();
        return;
      }

      let data = {
        cateId: this.cateId,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/book/lists",
        method: "post",
        data,
      }).then((res) => {
        this.bookList = res.data.list;
        this.total = res.data.count;
      });
      // if (this.searchType != undefined && this.keyWord != undefined) {
      //     this.searchList()
      // } else {
      //     return false;
      // }
    },
    searchList () {
      let data = {
        searchType: this.searchType,
        keyWord: this.keyWord,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/search",
        method: "post",
        data,
      }).then((res) => {
        this.isSearch = true;
        this.bookList = res.data.list;
        this.total = res.data.count;
      });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {
    searchType: function () {
      return this.$store.state.searchInfo.searchType;
    },
    keyWord: function () {
      return this.$store.state.searchInfo.keyWord;
    },
  },
  //过滤器
  filters: {
    filtime (val) { },
  },
};
</script>

<style lang='less' scoped>
.course-nav {
  padding: 30px 0px;

  .nav-list {
    display: flex;
    justify-content: center;
    height: 40px;
    justify-content: space-between;

    .nav-item {
      white-space: nowrap;
      // margin-right: 100px;
      font-size: 16px;
      color: #606266;
    }

    .nav-item-active {
      white-space: nowrap;
      // margin-right: 100px;
      font-size: 16px;
      color: #4676f8;
      height: 26px;
      border-bottom: 4px solid #4676f8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

// 列表
.book-list {
  .book-item {
    width: 1440px;
    height: 245px;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    margin-bottom: 40px;
    display: flex;

    .book-cover>img {
      width: 184px;
      height: 245px;
      background: #d8d8d8;
      border-radius: 8px;
    }

    .book-info {
      padding: 16px;

      .book-title {
        font-size: 22px;
        font-weight: bold;
        color: #3b3d41;
        margin-bottom: 30px;
      }

      .book-introduce {
        font-size: 16px;
        color: #606266;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .book-auth {
        font-size: 14px;
        color: #909399;
        bottom: 16px;
      }
    }
  }
}
</style>
