<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="userManage-box centre-content">
        <h3>用户管理</h3>
        <div class="message-title">
          <div class="ipt-box flex-box">
            <div class="flex-box">
              <div class="flex-box">
                <p class="nowrapText ipt-text">用户名：</p>
                <!-- @input="userInput($event)" -->
                <input class="input-box" type="text" v-model="accountName" placeholder="用户名" />
              </div>
              <p class="nowrapText ipt-text">显示名称：</p>
              <!-- @input="youNameInput($event)" -->
              <input class="input-box" type="text" v-model="trueName" placeholder="显示名称" />
            </div>
            <div class="flex-box">
              <p class="nowrapText ipt-text">手机号：</p>
              <!-- @input="phoneInput($event)" -->
              <input class="input-box" type="text" v-model="cellPhone" placeholder="手机号" />
            </div>

            <!-- 按钮 -->
            <div class="btn-box flex-box">
              <p class="search-btn" @click="searchMethod()">搜索</p>
              <p class="reset-btn" @click="resetMethod()">重置</p>
            </div>
          </div>
        </div>

        <div class="userMessage-tab">
          <div class="btn-box">
            <div class="flex-box">
              <p class="add-btn hoverFinger" @click="addUser()">添加用户</p>
              <p class="import-btn hoverFinger" @click="batchUploadVisible = true" style="display: none">
                批量导入
              </p>
            </div>
            <!-- <div class="listText">自定义列表</div> -->
          </div>
          <!-- table部分 -->
          <div>
            <el-table ref="multipleTable" :data="userMessageList" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
              <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
              <el-table-column type="index" label="序号" width="100">
              </el-table-column>
              <el-table-column prop="accountName" label="用户名" width="240" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="trueName" label="显示名称" width="240">
              </el-table-column>
              <el-table-column prop="levelText" label="账号" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="cellPhone" label="手机号" width="240" show-overflow-tooltip>
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="lookOverClick(scope.row)" type="text" size="small">查看</el-button>
                  <el-button type="text" size="small" @click="editClick(scope.row)">编辑</el-button>
                  <el-button @click="handleDelete(scope.row)" type="text" size="small"
                    style="color: #fc4646">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加用户 -->
    <!-- <div class="addDialog">
      <el-dialog
        title="添加用户"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <div class="dialog-box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="dialog-ipt-box">
              <el-form-item label="显示名称" prop="trueName" class="ipt-text">
                <el-input
                  class="dialog-input-box"
                  v-model="ruleForm.trueName"
                  placeholder="请输入姓名"
                  @input="nameInput($event)"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="用户名" prop="accountName" class="ipt-text">
                <el-input
                  class="dialog-input-box"
                  v-model="ruleForm.accountName"
                  placeholder="请输入用户名"
                  @input="accountNameInput($event)"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="手机号" prop="cellPhone" class="ipt-text">
                <el-input
                  class="dialog-input-box"
                  v-model="ruleForm.cellPhone"
                  placeholder="请输入手机号"
                  @input="cellPhoneInput($event)"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="密码" prop="password" class="ipt-text">
                <el-input
                  type="password"
                  class="dialog-input-box"
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dialog-ipt-box">
              <el-form-item label="确认密码" prop="checkPass" class="ipt-text">
                <el-input
                  type="password"
                  class="dialog-input-box"
                  v-model="ruleForm.checkPass"
                  placeholder="确认密码"
                ></el-input>
              </el-form-item>
            </div>

            <div class="line"></div>
            <el-form-item class="addStudent-btn-box">
              <el-button
                class="yesBtn"
                type="primary"
                @click="submitForm('ruleForm')"
                >确认</el-button
              >
              <el-button class="noBtn" @click="resetForm('ruleForm')"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div> -->

    <handleDialog :visible.sync="centerDialogVisible" :formData="ruleForm" :handleState="handleState"
      @submitSuccess="getUserMessageList" />
    <BatchUpload :visible.sync="batchUploadVisible"
      downloadTemplateUrl="https://xlingtong.oss-cn-beijing.aliyuncs.com/reslib/files/%E8%B5%84%E6%BA%90%E5%BA%93%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx"
      requestUrl="/student/import" @submitSuccess="getUserMessageList" />
  </div>
</template>

<script>
import request from "@/utils/request.js";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import handleDialog from "./components/handleDialog.vue";
import BatchUpload from "@/components/BatchUpload.vue";
function getRuleForm() {
  return JSON.parse(
    JSON.stringify({
      trueName: "", //显示名称
      accountName: "", //账户名称
      cellPhone: "", //电话号码
      password: "", // 密码
      checkPass: "",
    })
  );
}
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // if(value) {
      //     return callback(new Error("密码规则是：***"));
      // }

      if (this.ruleForm.checkPass !== "") {
        this.$refs.ruleForm.validateField("checkPass");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userMessageList: [],
      secretKey: "",
      centerDialogVisible: false,

      perPage: 10, //每页多少个
      currentPage: 1, //当前页码
      trueName: "", //显示名称
      accountName: "", //账户名称
      cellPhone: "", //电话号码
      password: "", // 密码

      total: 0,

      ruleForm: {
        trueName: "", //显示名称
        accountName: "", //账户名称
        cellPhone: "", //电话号码
        password: "", // 密码
        checkPass: "",
      },
      rules: {
        trueName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        accountName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        cellPhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "电话格式不正确", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      handleState: "",
      accountId: "", //删除id

      batchUploadVisible: false,
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    let Base64 = require("js-base64").Base64;
    let secretKey = localStorageGet("secretKey");
    this.secretKey = Base64.encode(secretKey);
    this.getUserMessageList();
  },
  //事件方法
  methods: {
    handleCurrentChange(val) {
      //切换页码
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getUserMessageList();
    },
    handleSizeChange(val) {
      //每页多少条数据
      this.perPageL = val;
      window.scrollTo(0, 0);
      this.getUserMessageList();
    },
    getUserMessageList() {
      let data = {
        perPage: this.perPage,
        pageNum: this.currentPage,
        trueName: this.trueName,
        accountName: this.accountName,
        cellPhone: this.cellPhone,
      };
      request({
        url: "/accounts/accounts",
        method: "post",
        data,
      })
        .then((res) => {
          this.total = res.data.count;
          this.userMessageList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addUser() {
      this.centerDialogVisible = true;
      this.handleState = "新增";
      this.ruleForm = getRuleForm();
      //   this.centerDialogVisible = true;
    },
    searchMethod() {
      this.getUserMessageList();
    },
    resetMethod() {
      this.accountName = "";
      this.trueName = "";
      this.cellPhone = "";
      this.getUserMessageList();
    },
    getAddUser() {
      let data = {
        accountName: this.accountName,
        trueName: this.trueName,
        cellPhone: this.cellPhone,
        password: this.password,
      };
      request({
        url: "/accounts/add",
        method: "post",
        data,
      })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    nameInput(trueName) {
      this.trueName = trueName;
    },
    accountNameInput(accountName) {
      this.accountName = accountName;
    },
    cellPhoneInput(cellPhone) {
      this.cellPhone = cellPhone;
    },
    passwordInput(password) {
      this.password = password;
    },

    submitForm(formName) {
      // console.log(this.trueName, "用户名");
      // console.log(this.accountName, "账户名称");
      // console.log(this.cellPhone, "电话号码");
      // console.log(this.password, "密码");

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getAddUser();
          //   this.textarea = "";
          //   this.messageTitle = "";
          this.getUserMessageList();
          this.centerDialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    //   this.centerDialogVisible = false;
    // },
    resetForm(formName) { },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //   trueName: this.trueName,
    //     accountName: this.accountName,
    //     cellPhone: this.cellPhone,
    youNameInput(e) {
      this.trueName = e.target.value;
    },
    userInput(e) {
      this.accountName = e.target.value;
    },
    phoneInput(e) {
      this.cellPhone = e.target.value;
    },
    lookOverClick(row) {
      //查看

      this.centerDialogVisible = true;
      this.handleState = "查看";
      this.ruleForm = JSON.parse(JSON.stringify(row));

      // this.ruleForm = row;
    },
    editClick(row) {
      //编辑
      //   let data = {
      //     trueName: row.trueName,
      //     cellPhone: row.cellPhone,
      //     password: row.password,
      //     // secretKey: this.secretKey,
      //   };
      //   request({
      //     url: "/accounts/edit",
      //     method: "post",
      //     data,
      //   })
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });

      //   this.centerDialogVisible = true;

      this.centerDialogVisible = true;
      this.handleState = "编辑";
      this.ruleForm = JSON.parse(JSON.stringify(row));
    },
    //删除
    getUserMessagetDel() {
      let data = { accountId: this.accountId };
      request({
        url: "/accounts/del",
        method: "post",
        data,
      })
        .then((res) => {
          this.getUserMessageList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(row) {
      //删除
      this.accountId = row.accountId;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getUserMessagetDel();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  //注册子组件
  components: {
    handleDialog,
    BatchUpload,
  },
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scope>
h3 {
  height: 45px;
  border-bottom: 1px solid #dcdfe6;
}

.userManage-box {
  padding: 40px 0px 60px;
}

.message-title {
  //   width: 1440px;
  //   height: 80px;
  margin-top: 16px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
  border-radius: 8px;
  margin-bottom: 16px;

  .ipt-box {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;

    .ipt-text {
      font-size: 14px;
      color: #303133;
    }

    .input-box {
      padding: 8px;
      width: 200px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #c0c4cc;
      // caret-color: #40B8FA;
      outline-color: #c0c4cc;
      margin-right: 24px;
    }

    // /deep/.el-input__inner {
    //   width: 200px;
    // }
  }

  .btn-box {
    .search-btn {
      margin-right: 16px;
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: linear-gradient(317deg,
          rgba(70, 118, 248, 0.99) 0%,
          #54c6f8 100%);
      box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
      border-radius: 4px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }

    .reset-btn {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #c0c4cc;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606266;
    }
  }
}

.userMessage-tab {
  .btn-box {
    margin-bottom: 16px;
  }

  padding: 24px;
  margin-top: 16px;
  // width: 1440px;
  // height: 624px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
  border-radius: 8px;

  .btn-box {
    display: flex;
    justify-content: space-between;

    .add-btn,
    .import-btn {
      width: 96px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #4676f8;
      font-size: 14px;
      color: #4676f8;
    }

    .add-btn {
      margin-right: 24px;
    }

    .el-button--primary {
      color: #4676f8;
      border: none;
    }

    .listText {
      font-size: 14px;
      color: #4676f8;
    }
  }

  .page-box {
    padding: 40px 0px;
  }
}
</style>
