export function getPreviewType(coverImg) {
    coverImg = coverImg - 0;
    if ([2, 4, 5].includes(coverImg)) {
        return "微软";
    }
    if ([3].includes(coverImg)) {
        return "pdf";
    }
    if ([1].includes(coverImg)) {
        return "image";
    }
    if ([9].includes(coverImg)) {
        return "video";
    }
    if ([11].includes(coverImg)) {
        return "audio";
    }
}