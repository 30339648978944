<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="centre-content">
        <div class="course-nav">
          <div v-if="isSearch != true">
            <!-- <div :class="tabActive == 0 ? 'nav-item-active' : 'nav-item'" @click="tabChange(0)">
              全部
            </div>
            <div v-for="item in navList" :class="tabActive == item.cateId ? 'nav-item-active' : 'nav-item'"
              @click="tabChange(item.cateId)" :key="item.id">
              {{ item.cateName }}
            </div> -->
            <el-tabs v-model="cateId" @tab-click="tabChange">
              <el-tab-pane :label="item.cateName" :name="item.cateId.toString()" v-for="(item,i) in navList" :key="i"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-if="courseList.length != 0">
          <div class="course-list">
            <div class="course-item" v-for="courseItem in courseList" @click="toDetail(courseItem.courseId)"
              :key="courseItem.id">
              <div class="course-cover">
                <div class="cover-img">
                  <img :src="courseItem.coverImg" alt="" />
                </div>
                <div class="cover-info flex-box">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/learn-num.png" alt="" />
                    </p>
                    <span>{{ courseItem.studyNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ courseItem.shareNum }}</span>
                  </div>
                </div>
              </div>
              <div class="course-info">
                <p class="course-title">{{ courseItem.courseTitle }}</p>
                <p class="course-introduce">{{ courseItem.courseLecturer }}</p>
                <p class="courseHover">
                  <img src="../assets/home/course-hover-icon.png" alt="" />
                </p>
              </div>
            </div>
          </div>

          <!-- 分页 -->
          <div class="page-box">
            <p class="homePage hoverFinger" @click="currentChange(1)">首页</p>
            <el-pagination :page-size="perPage" background layout="prev, pager, next" :total="total" pager-count:7
              @current-change="currentChange" :current-page="currentPage">
            </el-pagination>
            <p class="endPage hoverFinger" @click="currentChange(Math.ceil(total / perPage))">尾页</p>
          </div>
        </div>
        <!-- 暂无内容 -->
        <div class="notBox" v-else>
          <p class="no-content">
            <img src="../assets/home/no-content.png" alt="" />
          </p>
          <p class="no-conent-text">暂无资源，平台正在努力添加资源~</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
export default {
  data() {
    return {
      navList: [],
      tabActive: 0,
      cateId: 0,
      courseList: [], //课程列表
      currentPage: 1, // 当前页码
      total: 0,
      perPage: 8, //一页请求多少条数据
      isSearch: false,
      //   searchType: "",
      //   keyWord: "",
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    // this.searchType = this.$route.query.searchType;
    // this.keyWord = this.$route.query.keyWord;
    this.getCourseCate();
    this.getCourseList();
    // this.isSearch = this.$route.query.search;
  },
  watch: {
    // keyWord() {
    //   // this.getCourseList();
    //   if (this.searchType != undefined && this.keyWord != undefined && this.searchType == 'course') {
    //     // this.searchList();
    //     this.getCourseList();
    //     return;
    //   }
    // },
    "$store.state.searchInfo"() {
      this.currentPage = 1;
      this.getCourseList();
    },
  },
  //事件方法
  methods: {
    currentChange(val) {
      console.log(val)
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getCourseList();
    },
    tabChange(cateId) {
      //导航切换
      this.currentPage = 1;
      this.cateId = cateId.name;
      this.tabActive = cateId.name;
      this.getCourseList();
    },
    toDetail(courseid) {
      //   this.$router.push(`/courseDetail/${courseid}`);
      let pathInfo = this.$router.resolve({
        path: `/courseDetail/${courseid}`,
      });
      window.open(pathInfo.href, "_blank");
    },
    getCourseCate() {
      //课程分类
      let data = {};
      request({
        url: "/course/cate",
        method: "post",
        data,
      }).then((res) => {
        this.navList = res.data;
        this.navList.unshift({cateId:0,cateName:'全部'})
      });
    },
    getCourseList() {      //课程列表
      if (this.searchType != undefined && this.keyWord != undefined && this.searchType == 'course') {
        this.searchList();
        return;
      }
      let data = {
        cateId: this.cateId,
        pageNum: this.currentPage,
        perPage: this.perPage,
      };
      request({
        url: "/course/lists",
        method: "post",
        data,
      }).then((res) => {
        this.courseList = res.data.lists;
        this.total = res.data.count;
      }).catch((err) => {
        console.log(err)
      });
    },
    searchList() { // 搜索列表
      // this.currentPage = 1;
      let data = {
        searchType: this.searchType,
        keyWord: this.keyWord,
        perPage: this.perPage,
        pageNum: this.currentPage,
      };
      request({
        url: "/search",
        method: "post",
        data,
      }).then((res) => {
        this.isSearch = true;
        this.total = res.data.count;
        this.courseList = res.data.list;
      }).catch((err) => {
        console.log(err)
      })
    },

  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {
    searchType: function () {
      return this.$store.state.searchInfo.searchType;
    },
    keyWord: function () {
      return this.$store.state.searchInfo.keyWord;
    },
  },
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.course-nav {
  padding: 30px 0px;

  .nav-list {
    display: flex;
    justify-content: center;
    height: 40px;

    .nav-item {
      margin-right: 100px;
      font-size: 16px;
      color: #606266;
    }

    .nav-item-active {
      margin-right: 100px;
      font-size: 16px;
      color: #4676f8;
      height: 26px;
      border-bottom: 4px solid #4676f8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.course-list {
  display: flex;
  flex-wrap: wrap;

  .course-item {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    width: 336px;
    height: 310px;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
    border-radius: 8px;
    margin-right: 32px;
    margin-bottom: 40px;
    cursor: pointer;

    .course-cover {
      .cover-img>img {
        width: 336px;
        height: 224px;
        border-radius: 8px;
        background-color: #6F7174;
        display: block;
      }

      position: relative;
    }

    .cover-info {
      width: 303px;
      position: absolute;
      bottom: 0px;
      display: flex;
      padding: 17px 16px 7px;
      font-size: 12px;
      color: #ffffff;
      background: linear-gradient(180deg, rgba(54, 54, 56, 0) 0%, rgba(54, 54, 56, 1.5) 100%);
      border-radius: 0px 0px 8px 8px;
    }

    .course-info {
      padding: 16px;
      position: relative;

      .course-title {
        font-size: 16px;
        color: #3b3d41;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .course-title :hover {
        color: #4676f8;
      }

      .course-introduce {
        font-size: 12px;
        color: #909399;
      }

      .courseHover {
        position: absolute;
        right: 0;
        bottom: -8px;
        display: none;
      }

      .courseHover>img {
        width: 49px;
        height: 48px;
      }
    }
  }

  .course-item:hover .courseHover {
    display: inline-block;
    animation: mymove ease 2s;
    animation-iteration-count: 1;
    /*设置动画播放次数*/
    animation-fill-mode: forwards;
  }

  @keyframes mymove {
    from {
      // top: 80px;
      opacity: 0;
    }

    to {
      // top: 40px;
      opacity: 1;
    }
  }

  .course-item:nth-child(4n) {
    margin-right: 0px;
  }

  .course-item:hover {
    // box-shadow: 0px 6px 15px 0px rgba(0, 83, 249, 0.15);
    // transform: translateY(-.60rem); //向上浮动了8个像素
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
</style>