<template>
  <div class="backgrounAll">
    <div class="materialDetail-box animate__animated animate__bounce animate__fadeInUp">
      <div class="centre-content">
        <div class="detail parentLocation">
          <div class="detail-cover" v-if="detail.customImg != ''"><img :src="detail.customImg" alt=""></div>
          <div v-else>
            <div class="detail-cover">
              <!-- 1:png格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
              <img v-show="detail.coverImg == 1" src="../assets/home/img-cover.png" alt="" />
              <img v-show="detail.coverImg == 2" src="../assets/home/ppt-cover.png" alt="" />
              <img v-show="detail.coverImg == 3" src="../assets/home/pdf-cover.png" alt="" />
              <img v-show="detail.coverImg == 4" src="../assets/home/xls-cover.png" alt="" />
              <img v-show="detail.coverImg == 5" src="../assets/home/doc-cover.png" alt="" />
              <img v-show="detail.coverImg == 6" src="../assets/home/zip-cover.png" alt="" />
              <img v-show="detail.coverImg == 7" src="../assets/home/else-cover.png" alt="" />
              <img v-show="detail.coverImg == 8" src="../assets/home/txt-cover.png" alt="" />
              <img v-show="detail.coverImg == 9" src="../assets/home/video-cover.png" alt="" />
              <img v-show="detail.coverImg == 11" src="../assets/home/audio-cover.png" alt="" />
            </div>
            <div class="sonLocation">
              <p class="cover-title">{{ detail.resName }}</p>
              <p class="cover-line"></p>
            </div>
          </div>
          <div class="detail-info">
            <p class="detail-title">{{ detail.resName }}</p>
            <!-- <p class="detail-type">类型：自我认识</p> -->
            <p class="detail-layout">格式：{{ detail.format }}</p>
            <p class="detail-time">上传时间：{{ detail.createdAt }}</p>
            <div class="share-box">
              <div class="flex-box">
                <p class="download">
                  <img src="../assets/home/download.png" alt="" />
                </p>
                <p>{{ detail.viewNum }}</p>
              </div>
              <div class="flex-box">
                <p class="share-icon">
                  <img src="../assets/article/share-icon.png" alt="" />
                </p>
                <p>{{ detail.shareNum }}</p>
              </div>
              <!-- <span class="share hoverFinger" @click="share()">分享</span>
              <span style="color: red">*</span>
              <span>点击分享生成二维码和链接</span> -->
            </div>
            <div class="flex-box btn-box">
              <p class="preview-btn hoverFinger" @click="yulan()" v-if="coverImg == 2 ||
                coverImg == 4 ||
                coverImg == 5 ||
                coverImg == 1 ||
                coverImg == 9 ||
                coverImg == 11 ||
                coverImg == 2 ||
                coverImg == 3
                ">
                在线预览
              </p>
              <p class="download-btn hoverFinger" @click="downLoad()" v-if="configInfo != null">
                立即下载
              </p>
            </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="recommend-box" v-if="recommendList.length != 0">
          <div class="flexBox-space-between">
            <p class="recommend-text">相关推荐</p>
            <div class="block">
              <el-pagination :page-size="5" background layout="prev, pager, next" :total="total" pager-count:7
                @current-change="currentChange" :current-page="currentPage">
              </el-pagination>
            </div>
          </div>
          <div class="flex-box">
            <div class="article-item hoverFinger" v-for="kejianItem in recommendList" @click="toDetail(kejianItem.resId)"
              :key="kejianItem.id">
              <div class="article-cover">
                <div class="cover-img" v-if="kejianItem.customImg != ''"><img :src="kejianItem.customImg" alt=""></div>
                <div v-else>
                  <div class="cover-img">
                    <!-- 1:png格式  2:ppt格式  3:pdf格式  4:xls格式 5:doc格式 6:zip格式 7:其他格式 8:txt格式 9:video格式 11:audio格式  -->
                    <img v-show="kejianItem.coverImg == 1" src="../assets/home/png-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 2" src="../assets/home/ppt-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 3" src="../assets/home/pdf-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 4" src="../assets/home/xls-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 5" src="../assets/home/doc-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 6" src="../assets/home/zip-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 7" src="../assets/home/else-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 8" src="../assets/home/txt-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 9" src="../assets/home/video-cover.png" alt="" />
                    <img v-show="kejianItem.coverImg == 11" src="../assets/home/audio-cover.png" alt="" />
                  </div>
                  <div class="sonLocation">
                    <p class="cover-title">{{ kejianItem.resName }}</p>
                    <p class="cover-line"></p>
                  </div>
                </div>
                <div class="cover-info flex-box">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/download2.png" alt="" />
                    </p>
                    <span>{{ kejianItem.viewNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ kejianItem.shareNum }}</span>
                  </div>
                </div>
              </div>
              <div class="article-info">
                <p class="article-title">{{ kejianItem.resName }}</p>
                <!-- <p class="article-type">类型：自我认识</p> -->
                <p class="article-layput">格式：{{ kejianItem.format }}</p>
                <p class="article-time">上传时间：{{ kejianItem.createdAt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <p class="qrCode"><img src="" alt="" /></p>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
export default {
  data() {
    return {
      resId: "",
      detail: {},
      recommendList: [], //推荐列表
      currentPage: 1, // 当前页码
      total: 0,
      centerDialogVisible: false,
      type: "",
      previewUrl: "",
      downloadUrl: "", //文件下载地址
      coverImg: "",
      // format: '', //格式
      configInfo: {},
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.configInfo = configInfo;


    this.resId = this.$route.params.resid;
    this.getKejianDetail();
    this.getKejianRecommend();
    if (configInfo == null) {
      return false;
    } else {
      this.getKjainGeturl();
    }
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getKejianRecommend();
    },
    getKejianDetail() {
      let data = { resId: this.resId };
      request({
        url: "/kejian/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.detail = res.data;
          this.type = res.data.resType;
          this.coverImg = res.data.coverImg;
          // this.format = res.data.format;
          // this.getKejianRecommend()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getKejianRecommend() {
      let data = { type: this.type, pageNum: 5 };
      request({
        url: "/kejian/recommend",
        method: "post",
        data,
      })
        .then((res) => {
          this.recommendList = res.data;
          this.total = res.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    share() {
      this.centerDialogVisible = true;
    },
    toDetail(resIda) {
      this.resId = resIda;
      let resId = resIda
      // console.log(resId)
      // this.getKejianDetail();
      let pathInfo = this.$router.resolve({
        path: `/materialDetail/${resId}`,
      });
      // window.open(pathInfo.href, "_blank"); 
      window.location.href = pathInfo.href;
    },
    yulan() {
      //  预览
      this.$router.push(
        `/onlinePreview?resId=${this.resId}&coverImg=${this.detail.coverImg}&format=${this.detail.format}`
      );

    },
    downLoad() {
      //  下载
      window.location.href = this.downloadUrl;
      // window.open(this.fileOssUrl, '_blank'); //新窗下载
    },
    getKjainGeturl() {
      //获取课件素材的文件地址
      let data = { resId: this.resId };
      request({
        url: "/kejian/geturl",
        method: "post",
        data,
      })
        .then((res) => {
          this.downloadUrl = res.data.downloadUrl;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
.materialDetail-box {
  padding-top: 40px;
  padding-bottom: 60px;
}

.detail {
  display: flex;

  .detail-cover {
    img {
      display: block;
      width: 348px;
      height: 260px;
      background: linear-gradient(301deg, #5e9cd9 0%, #3861d9 100%);
      border-radius: 8px;
      backdrop-filter: blur(36px);
      
    }
    // .detail-cover
  }

  .sonLocation {
    top: 60px;
    left: 24px;
  }

  .cover-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出显示省略号
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .cover-line {
    margin-top: 16px;
    width: 43px;
    height: 3px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
  }

  .detail-info {
    margin-left: 40px;
    width: 100%;

    .detail-title {
      font-size: 16px;
      font-weight: bold;
      color: #3b3d41;
      margin-bottom: 16px;
    }

    .detail-type {
      font-size: 16px;
      color: #606266;
      margin-bottom: 10px;
    }

    .detail-layout {
      font-size: 16px;
      color: #606266;
      margin-bottom: 10px;
    }

    .detail-time {
      font-size: 16px;
      color: #606266;
      margin-bottom: 50px;
    }

    .share-box {
      display: flex;
      font-size: 14px;
      color: #6f7174;
      align-items: flex-end;

      .download>img {
        width: 19px;
        height: 19px;
        margin-right: 6px;
      }

      .share-icon>img {
        width: 19px;
        height: 19px;
        margin-left: 16px;
        margin-right: 6px;
      }

      .share {
        font-size: 14px;
        color: #4676f8;
        margin-left: 16px;
      }
    }

    .btn-box {
      margin-top: 20px;
      position: absolute;
      bottom: 0;

      .preview-btn {
        width: 160px;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(135deg,
            rgba(0, 140, 255, 0.55) 0%,
            #4676f8 100%);
        box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.13);
        border-radius: 6px;
        margin-right: 24px;
      }

      .download-btn {
        width: 160px;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(135deg,
            rgba(0, 211, 255, 0.52) 0%,
            #11b6ff 100%);
        box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.24);
        border-radius: 6px;
      }
    }
  }
}

// 推荐
.recommend-box {
  width: 1440px;
  // height: 382px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 50px;
  padding: 24px;
  overflow: hidden;

  .article-item {
    margin-top: 24px;
    width: 220px;
    margin-right: 73px;

    .article-cover {
      .cover-img>img {
        width: 220px;
        height: 164px;
        // background: linear-gradient(301deg, #67d4b4 0%, #1fcfdf 100%);
        border-radius: 8px;
        display: block;
      }

      position: relative;
    }

    .cover-info {
      position: absolute;
      bottom: 0px;
      display: flex;
      padding: 17px 16px 7px;
      font-size: 12px;
      color: #ffffff;
    }

    .article-info {
      margin: 10px 0 0px;

      .article-title {
        font-size: 16px;
        font-weight: bold;
        color: #3b3d41;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .article-type {
        font-size: 14px;
        color: #606266;
        margin-bottom: 10px;
      }

      .article-layput {
        font-size: 14px;
        color: #606266;
        margin-bottom: 10px;
      }

      .article-time {
        font-size: 14px;
        color: #606266;
      }
    }
  }

  .sonLocation {
    top: 40px;
    left: 16px;
  }

  .cover-title {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    // width: 130px;
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出显示省略号
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .cover-line {
    margin-top: 16px;
    width: 20px;
    height: 2px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
  }
}

// 弹框
.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode>img {
    width: 200px;
    height: 200px;
    background-color: #cacdd1;
  }
}
</style>
