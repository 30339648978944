/**
     * 目前对象值如果是函数 、RegExp等特殊对象存贮会被忽略
     * @param { String } key  属性
 * @param { string } value 值
    */
export const localStorageSet = (key, value) => {
    if (typeof (value) === 'object') value = JSON.stringify(value);
    localStorage.setItem(key, value)
};

/**
 * @param {String} key  属性
*/
export const localStorageGet = (key) => {
    return localStorage.getItem(key)
};

/**
* @param {String} key  属性
*/
export const localStorageRemove = (key) => {
    localStorage.removeItem(key)
};