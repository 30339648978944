<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="myinfo-box centre-content">
        <h3>基本资料</h3>

        <div class="basic-data">
          <div class="basic">
            <div class="flexbox marbottom-24">
              <p class="nameTitle">当前头像：</p>
              <p class="userIcon">
                <img v-if="ruleForm.avatar" :src="ruleForm.avatar" alt="" />
                <img v-else src="../../../assets/home/user.png" alt="" />
              </p>
              <p class="changeImg hoverFinger" @click="changeHeadImg()">
                更换头像
              </p>
            </div>
            <!-- :rules="rules" 绑定校验 -->
            <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
              <div class="flexbox marbottom-24">
                <p class="nameTitle">用 户 名 ：</p>
                <p class="nameTitle" style="margin-left: 0px;">{{ ruleForm.accountName }}</p>
              </div>
              <div class="flexbox marbottom-24">
                <p class="nameTitle">手 机 号 ：</p>
                <p class="nameTitle" style="margin-left: 0px;">{{ ruleForm.cellPhone }}</p>
              </div>
              <!-- <el-form-item label=" 手 机 号：" prop="cellPhone">
                <el-input v-model="ruleForm.cellPhone" placeholder="请输入手机号" readonly></el-input>
              </el-form-item> -->
              <el-form-item label="修改密码：" prop="pass">
                <el-input type="password" placeholder="可在此处修改密码" v-model="ruleForm.pass" autocomplete="off"></el-input>
              </el-form-item>
              <!-- required *显示 -->
              <el-form-item label="确认密码：" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off">
                  <i slot="prefix" class="el-input__icon el-icon-pass"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                <el-button @click="reset()" style="margin-left: 24px;">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 上传头像弹框 -->
    <div>
      <el-dialog title="上传头像" :visible.sync="centerDialogVisible" width="30%" center @open="picUploadOpen">
        <div class="head-img">
          <!--  -->
          <img :src="imageUrl || defaultImageUrl" alt="" />
        </div>
        <div class="line"></div>
        <el-upload v-show="!picUploadShow" class="choseImg hoverFinger" action="#" :http-request="handlePreview"
          :show-file-list="false">选择头像</el-upload>
        <div v-show="picUploadShow" class="submitImg hoverFinger" @click="submitPic">
          提交头像
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import request2 from "@/utils/request2.js";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import { setTransitionHooks } from "@vue/runtime-core";
import { baseUrl } from "@/settings.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      defaultImageUrl: require("../../../assets/home/head-img.png"),
      phone: "",
      avatar: "",
      cellPhone: "",
      // password: '',
      accountName: "",

      ruleForm: {
        avatar: "",
        cellPhone: "",
        accountName: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        cellPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },

      imageUrl: "",

      centerDialogVisible: false,
      baseUrl,
      picUploadShow: false,
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.ruleForm.avatar = configInfo.accountInfo.avatar;
    this.ruleForm.cellPhone = configInfo.accountInfo.cellPhone;
    this.ruleForm.accountName = configInfo.accountInfo.accountName;
  },
  //事件方法
  methods: {
    changeHeadImg() {
      this.centerDialogVisible = true;
    },
    getmyInfo() {
      let data = {
        avatar: this.ruleForm.avatar, //头像
        cellPhone: this.ruleForm.cellPhone, //手机号
        accountName: this.ruleForm.accountName, //显示名称
        password: this.ruleForm.pass, //修改密码
      };
      request({
        url: "/auth/modify",
        method: "post",
        data,
      })
        .then((res) => {
          // 使用保存后的用户信息替换缓存内的用户数据
          let configInfo = JSON.parse(localStorageGet("userInfo"));
          configInfo.accountInfo.avatar = data.avatar;
          configInfo.accountInfo.cellPhone = data.cellPhone;
          configInfo.accountInfo.accountName = data.accountName;
          configInfo = JSON.stringify(configInfo);
          localStorageSet("userInfo", configInfo);
          this.$message({
            message: "修改成功",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUploadFile() {
      let data = { file: this.imgurl };
      request2({
        url: "/upload/file",
        method: "post",
        data,
      })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getmyInfo();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      //重置
      // this.ruleForm.cellPhone = "";
      this.ruleForm.pass = '';
      this.ruleForm.checkPass = '';
    },
    handlePreview(requestObj) {
      var formData = new FormData();
      formData.append("file", requestObj.file);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      request2({
        url: "/upload/file",
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.imageUrl = res.data.ossUrl;
          this.picUploadShow = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    submitPic() {
      this.centerDialogVisible = false;
      this.ruleForm.avatar = this.imageUrl;
      // this.getUploadFile();
      this.getmyInfo();

    },
    picUploadOpen() {
      this.imageUrl = this.ruleForm.avatar;
      this.picUploadShow = false;
    },
  },

  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
};
</script>

<style lang='less' scoped>
h3 {
  height: 45px;
  border-bottom: 1px solid #dcdfe6;
}

.flexbox {
  display: flex;
}

.myinfo-box {
  padding: 40px 0px 60px;

  .basic-data {
    padding: 40px;
    margin-top: 24px;
    box-shadow: 0px 2px 8px 0px rgba(45, 49, 59, 0.2);
    border-radius: 8px;
    background: url("https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/myInfo-bj.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: contain;

    .basic {
      margin: 0 auto;
      width: 500px;

      .nameTitle {
        font-size: 14px;
        color: #606266;
        width: 75px;
      }

      .changeImg {
        font-size: 14px;
        color: #3c6dfb;
        padding-top: 77px;
      }

      .userIcon {
        width: 94px;
        height: 94px;
        background-color: white;
        margin-right: 14px;
        // margin-bottom: 26px;
      }

      .userIcon>img {
        width: 94px;
        height: 94px;
      }

      .ipt-box {
        width: 300px;
        // height: 36px;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid #c0c4cc;
        outline-color: #c0c4cc;
        margin-right: 24px;
      }

      .btn-box {
        margin-top: 40px;

        .sub-btn {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(317deg,
              rgba(70, 118, 248, 0.99) 0%,
              #54c6f8 100%);
          box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
          border-radius: 4px;
          font-size: 14px;
          color: #ffffff;
          margin-right: 24px;
        }

        .reset-btn {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          font-size: 14px;
          color: #606266;
        }
      }
    }
  }
}

.head-img>img {
  width: 220px;
  height: 220px;
  display: block;
  margin: 0 auto;
}

.line {
  width: 526px;
  height: 1px;
  background: #d5d6d9;
  margin-top: 60px;
}

.choseImg {
  width: 88px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #4676f8;
  font-size: 14px;
  color: #4676f8;
  margin: 16px auto;
}

.submitImg {
  margin: 16px auto;
  width: 88px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: linear-gradient(317deg,
      rgba(70, 118, 248, 0.99) 0%,
      #54c6f8 100%);
  box-shadow: 0px 2px 8px 0px rgba(70, 117, 246, 0.45);
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
}

/deep/.el-form-item__label {
  width: 56px !important;
  white-space: nowrap;
  width: 0px !important;
}

/deep/.el-form-item__content {
  margin-left: 80px !important;
}

/deep/.el-input__inner {
  padding-left: 12px !important;
  width: 320px;
}

/deep/.el-button,
/deep/.el-button--primary {
  padding: 0px;
  width: 72px;
  height: 32px;
  line-height: 32px;
}
</style>
